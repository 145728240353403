import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";


export const ImageResource = ({ url, onLoad, onError, width, height, ...rest }) => {
    const preventDefault = e => e.preventDefault()

    const imgObj = <img alt="Resource" className="resource-container img-resource"
        src={url}
        onClick={preventDefault}
        onMouseDown={preventDefault}
        onClickCapture={preventDefault}
        onLoad={(e) => {
            preventDefault(e);
            if (onLoad) onLoad(url);
        }}
        onError={(e) => {
            if (onError) onError(e)
        }}
        onContextMenu={(e) => {
            e.preventDefault();
            return false;
        }}
        {...rest}
    />
    if (width && height) {
        return <div style={{ width: `${width}px`, height: `${height}px` }}>
            {imgObj}
        </div>
    }
    return imgObj;
}

export const VideoResource = ({ url, ...rest }) => {
    // split here just in case of different logic inside
    return <ImageResource url={url} {...rest} />
}


export const PDFResource = ({ url, onLoad, onError, width, height, ...rest }) => {
    const preventDefault = e => e.preventDefault()
    // const file = useMemo(
    //     () => ({ url: url }), //withCredentials: true 
    //     []
    // );
    return <Document
        // file={file}
        file={url}
        onContextMenu={preventDefault}
        className="resource-container"
        onLoadSuccess={() => {
            if (onLoad) onLoad(url);
        }}
        onLoadError={onError}
        {...rest}
    >
        <Page pageNumber={1} width={width} height={height}
            renderAnnotationLayer={false} />
    </Document>
}


export const Resource = ({ url, onLoad, onError, ...rest }) => {
    const [ext, setExt] = useState("");
    useEffect(() => {
        if (!url || url.length < 1) return;
        let _ext = "";
        try {
            const matchObj = url.match(/\/([^/]+)\.([^?.]+)\?/);
            _ext = matchObj[2];
            if (!_ext || _ext.length < 1) return;
            setExt(_ext)
        } catch (err) {
            console.log(err);
            return;
        }
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    const handleLoaded = (_url) => {
        if (url !== _url) {
            console.error("Resource has been discarded earlier.");
            return;
        }
        if (onLoad) {
            onLoad()
        }
    }
    let resource = null;
    if (ext === 'pdf') {
        resource = <PDFResource url={url} onLoad={handleLoaded} onError={onError} {...rest} />
    } else if (ext === 'mp4') {
        resource = <VideoResource url={url} onLoad={handleLoaded} onError={onError} {...rest} />
    } else if (['png', 'jpg', 'jpeg'].includes(ext)) {
        resource = <ImageResource url={url} onLoad={handleLoaded} onError={onError} {...rest} />
    }
    return resource;
}
