import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { instructionCL3Actions } from "_actions";
import { normalizeString, getLeviDistance } from "_api/voice";
import { VCL, VOICE_CONFIG_NAMES } from "_api/voiceCommandLibrary";
import { extractLeaves } from "_api/data";
import { InstructionsList } from "./InstructionsList";
import { InstructionsShow } from "./InstructionsShow";

const Instructions = ({ command, height, width, mode, changeMode, ...props }) => {
    const [instructions, setInstructions] = useState([]); // data related to instructions
    const [data, setData] = useState({ leaves: [], blocks: [] }); // data related to blocks in specific instruction
    const [selectedInstruction, setSelectedInstruction] = useState([]);
    const [controlCommand, setControlCommand] = useState({ command: "", time: 0 });
    /**
     * Fetch all instruction for the current user
     */
    async function fetchListInstrConfigsData() {
        try {
            const _instructions = await props.listUserInstructions();
            console.log(_instructions);
            // just for an easy voice based string comparison later on
            setInstructions(_instructions.map(o => {
                return { ...o, normalizedName: normalizeString(o.name) }
            }));
        } catch (err) {
            console.log(err);
        }
    }

    const cbs = {
        [VCL.onShowAllInstructions]: fetchListInstrConfigsData,
        [VCL.onShowInstruction]: (candidateName) => {
            let minDist = -1,
                candidateInstruction = null;
            for (let instruction of instructions) {
                const distance = getLeviDistance(candidateName, instruction.normalizedName);
                if (minDist < 0 || minDist > distance) {
                    minDist = distance;
                    candidateInstruction = instruction;
                }
            }
            if (minDist < 0) {
                window.alert(`Show instruction command with instruction ${candidateName}`);
                return;
            }
            handleSelectInstruction(candidateInstruction)();
        },
        [VCL.onNext]: () => setControlCommand({ command: VCL.onNext }),
        [VCL.onBack]: () => setControlCommand({ command: VCL.onBack }),
        [VCL.onRestart]: () => setControlCommand({ command: VCL.onRestart }),
        [VCL.onPlayAudio]: () => setControlCommand({ command: VCL.onPlayAudio }),
        [VCL.onStopAudio]: () => setControlCommand({ command: VCL.onStopAudio }),
        default: (cmd) => console.log(`Instructions panel: unrecognized command: ${cmd}`)
    }

    useEffect(() => {
        if (!command || !command.command) return;
        const func = cbs[command.command];

        if (!func) {
            console.log(`Instructions panel: unrecognized command: ${command.command}`)
            return;
        }
        func(...(command.args || []))
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [command])

    const handleSelectInstruction = (instruction) =>
        async (e) => {
            if (e) e.preventDefault();
            if (selectedInstruction.id === instruction.id) return;
            setSelectedInstruction(instruction);
            try {
                const res = await props.getBlocksPerInstruction(instruction.id);
                const _blocks = res.hierarchy;
                console.log(res)
                if (!_blocks || _blocks.length < 1) {
                    setData({ ...data, leaves: [], blocks: [] });
                    console.log(res)
                    window.alert("This instruction has no resources in it. Please, let administrator know.")
                    return;
                }
                // hierarchy always starts with array of 1 element
                const _leaves = extractLeaves(_blocks[0]);
                console.log("_leaves", _leaves);
                console.log("_blocks", _blocks);
                setData({ ...data, leaves: _leaves, blocks: _blocks });
                changeMode(VOICE_CONFIG_NAMES.INSTRUCTIONS_INSTRUCTION);
            } catch (err) {
                console.log(err);
            }
        }

    let content = null;
    if (mode === VOICE_CONFIG_NAMES.INSTRUCTIONS_HOME) {
        content = <InstructionsList
            // @TODO change to instructions
            instructions={instructions} selectedInstruction={selectedInstruction}
            handleSelectInstruction={handleSelectInstruction}
            height={height}
            width={width}
        />
    } else if (mode === VOICE_CONFIG_NAMES.INSTRUCTIONS_INSTRUCTION) {
        console.log("LOG LOG LOG")
        content = <InstructionsShow
            leaves={data.leaves}
            instruction={selectedInstruction}
            controlCommand={controlCommand}
            height={height}
            width={width}
        />

    }

    return <div className="d-flex">
        {content}
    </div>
}

function mapState(state) {
    return {};
}

const actionCreators = {
    listUserInstructions: instructionCL3Actions.listUserInstructions,
    getBlocksPerInstruction: instructionCL3Actions.getBlocksPerInstruction,
};

const connectedInstructions = connect(mapState, actionCreators)(Instructions);
export { connectedInstructions as Instructions };
