import { scheduleService } from "_services";
import { alertActions, loadingAPIActions } from "_actions";


export const scheduleActions = {
    listResourceSchedule,
    createSchedule,
    deleteSchedule,
    updateSchedule
};

function listResourceSchedule(schedule_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return scheduleService.listResourceSchedule(schedule_id).then(
            ({ data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                return data.schedules;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function createSchedule(data) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        console.log(data);
        return scheduleService.createSchedule(data).then(
            ({ data, message }) => {
                dispatch(alertActions.success(message));
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function deleteSchedule(id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return scheduleService.deleteSchedule(id).then(
            ({ message, data }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data={"module_id": module_id}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}


function updateSchedule(res_id, data) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return scheduleService.updateSchedule(res_id, data).then(
            ({ data, message }) => {
                dispatch(alertActions.success(message));
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}