import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { moduleActions } from "_actions";
import { Modal } from "_components/Base";
import { TreeView } from "_components";
import { TreeNodeAI } from "./TreeNode";

import lodashClonedeep from "lodash.clonedeep";
import { getObjectValueByMultilevelKey } from "_api";

// custom function for destructure item.level and getting object from items
// ATTENTION: in get_block_hierarchy everything starts from 1 (due to the ordering)
const index_correction = (indx) => indx - 1; // server,db (1) -> array (0)
const data_reducer_no_correction = (acc, key) => {
    return acc.children ? acc.children[key] : acc[key]
};
const data_reducer = (acc, key) => {
    return data_reducer_no_correction(acc, index_correction(key));
};
const isSameSet = (s1, s2) => {
    if (s1.size !== s2.size) return false;
    for (let o of s1) {
        if (!s2.has(o)) return false;
    }
    for (let o of s2) {
        if (!s1.has(o)) return false;
    }
    return true;
}

const searchActiveItems = (obj) => {
    const result = [];
    if (obj.active) result.push(obj);
    if (!obj.children || obj.children.length < 1)
        return result;
    obj.children.forEach((child, indx) => {
        result.push(...searchActiveItems(child));
    })
    return result;
}

// works in place
const openActives = (obj) => {
    if (obj.active) {
        obj.open = obj.active;
        return true;
    }
    if (!obj.children || obj.children.length < 1) {
        return false;
    }
    let toOpen = false;
    obj.children.forEach(child => {
        toOpen = toOpen || openActives(child);
    });
    if (toOpen) {
        obj.open = toOpen;
    }
    return toOpen;
}

const UpdateConnectedBlocksModal = ({ open, handleClose, handleAction, currentModule, misc = {}, ...props }) => {
    const [items, setItems] = useState([]);
    const [initActiveElementIds, setInitActiveElementIds] = useState(new Set());
    const [activeElements, setActiveElements] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                // const { blocks } = await props.getConnectedBlocks(currentModule.id);
                const { hierarchy } = await props.getPossibleConnectedBlocks(currentModule.id);
                hierarchy.forEach(module => {
                    openActives(module);
                })

                setItems(hierarchy);
                const newActiveElements = []
                for (const module of hierarchy) {
                    newActiveElements.push(...searchActiveItems(module))
                }
                if (newActiveElements.length > 0) {
                    setActiveElements(newActiveElements);
                    setInitActiveElementIds(new Set(newActiveElements.map(o => o.id)));
                }
            } catch (err) {
                console.log(err);
            }
        }
        fetchData();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const ids = activeElements.map(o => o.id);
            const same = isSameSet(initActiveElementIds, new Set(ids));
            if (same) {
                window.alert("There is no change in the connected blocks.");
                return;
            }
            await props.updateConnectedBlocks(currentModule.id, ids);
            handleAction();
        } catch (err) {
            console.log(err)
        }
        handleClose(e);
    }

    const onItemClick = (item) => {
        let newActiveElements = [];
        const indx = activeElements.findIndex(o => o.id === item.id);
        if (indx === -1) {
            const lvls = activeElements.map(o => o.level)
            const higherLvlExist = lvls.some(lvl => item.level.startsWith(lvl));
            if (higherLvlExist) {
                window.alert("You already selected element of a higher level.")
                return;
            }
            for (const lvl of lvls) {
                if (lvl.startsWith(item.level)) {
                    window.alert("You already selected one or more lower level elements.")
                    return;
                }
            }
            newActiveElements = [...activeElements, item];
        } else {
            newActiveElements = activeElements.filter(o => o.id !== item.id);
        }
        setActiveElements(newActiveElements);
    }

    const onExpandClick = (item) => {
        let _items = lodashClonedeep(items);
        let _obj = getObjectValueByMultilevelKey(_items, item.level, ",", data_reducer);
        _obj.open = !_obj.open;
        setItems(_items)
    }

    const modalBody = <>
        <p className="lead mb-0">Select blocks accessible to a user when more details will be requested.</p>
        <p className="small mt-1">
            Note: When an element is selected - whole hierarchy under it will be included automatically.</p>
        <TreeView
            items={items} node={TreeNodeAI}
            onExpandClick={onExpandClick}
            onItemClick={onItemClick}
            top={true}
            activeElements={new Set(activeElements.map(o => o.id))}
        />
    </>

    return <Modal isopen={open}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleOk={handleSubmit}
        modalTitle="Update Connected Blocks"
        modalBody={modalBody}
    />
}

function mapState(state) {
    return {};
}
const actionCreators = {
    getConnectedBlocks: moduleActions.getConnectedBlocks,
    updateConnectedBlocks: moduleActions.updateConnectedBlocks,
    getPossibleConnectedBlocks: moduleActions.getPossibleConnectedBlocks,
};

const connectedUpdateConnectedBlocksModal = connect(mapState, actionCreators)(UpdateConnectedBlocksModal);
export { connectedUpdateConnectedBlocksModal as UpdateConnectedBlocksModal };
