import { userConstants } from "_constants";

function stateFlagManager(stateImpl, type) {
	const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
	if (!matches) return stateImpl;
	const [, requestName, requestState] = matches;
	let flagData = {
		["loading_" + requestName]: requestState === "REQUEST",
		["loaded_" + requestName]: requestState === "SUCCESS",
	};
	return Object.assign({}, stateImpl, {
		states: {
			...stateImpl.states,
			...flagData,
		},
	});
}

export function users(state = {}, action) {
	const stateImpl = usersImpl(state, action);
	return stateFlagManager(stateImpl, action.type);
}

function usersImpl(state = {}, action) {
	switch (action.type) {
		case userConstants.CLEAR_ALL:
			return {};

		default:
			return state;
	}
}
