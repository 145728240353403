import React, { useState } from "react";
import { connect } from "react-redux";
import { moduleActions } from "_actions";
import { Modal, Form } from "_components/Base";

import "_css/file_uploader.css";

const NewBlockModal = ({ open, handleClose, handleAction, currentModule, misc = {}, ...props }) => {
    const [data, setData] = useState({ name: "", goal: "", isLeaf: false, selectedFile: null, selectedAudioFile: null, is_important: false });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    const handleVerifiedSubmit = async () => {
        let block = await props.addNewBlock(currentModule.id, misc.parentBlockId, data.name, data.isLeaf, data.goal, data.is_important);
        if (data.isLeaf) {
            const { aspect_ratio } = await props.updateBlockResource(currentModule.id, block.id, data.selectedFile);
            block.aspect_ratio = aspect_ratio;
            if (data.selectedAudioFile) {
                await props.updateBlockAudioResource(currentModule.id, block.id, data.selectedAudioFile);
                block.audio_url = true;
            } else {
                block.audio_url = false;
            }
        }
        block.children = [];
        block.module_id = currentModule.id;
        handleAction(block, misc.parentBlockLevel);
        handleClose();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (data.isLeaf && !data.selectedFile) {
                window.alert("Please select the file first");
                return;
            }
            const { conflicts } = await props.verifyAddNewBlock(currentModule.id, misc.parentBlockId)
            if (conflicts && conflicts.length > 0) {
                const conflictModuleNamesStr = conflicts.map(o => o.name).join(', ');
                if (!window.confirm(
                    `Parental block appears in ${conflicts.length} module${conflicts.length > 1 ? 's' : ''} (${conflictModuleNamesStr}).\n` +
                    `New structure will be added to all of them automatically.\n` +
                    'Do you want to continue?')
                ) {
                    return;
                }
            }

            await handleVerifiedSubmit()
        } catch (err) {
            console.log(err)
        }
    }
    const modalBody = <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control name="name" type="text" placeholder="Block name" value={data.name} onChange={handleChange} />
        </Form.Group>

        <Form.Group controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="With resource"
                name="isLeaf" checked={data.isLeaf}
                onChange={(e) => { setData({ ...data, isLeaf: e.target.checked }) }} />
        </Form.Group>
        <Form.Group controlId="formImportantCheckbox">
            <Form.Check type="checkbox" label="Flag for instructor?"
                name="is_important" checked={data.is_important}
                onChange={(e) => { setData({ ...data, is_important: e.target.checked }) }} />
        </Form.Group>
        {data.isLeaf ?
            <>
                <div className="form-group files color">
                    <label>Upload resource</label>
                    <input type="file" className="form-control" multiple=""
                        onChange={(e) => {
                            e.preventDefault();
                            setData({ ...data, selectedFile: e.target.files[0] });
                        }} />
                </div>
                <div className="form-group files color">
                    <label>Upload Audio resource</label>
                    <input type="file" className="form-control" multiple=""
                        onChange={(e) => {
                            e.preventDefault();
                            setData({ ...data, selectedAudioFile: e.target.files[0] });
                        }} />
                </div>
            </>
            :
            <Form.Group controlId="formBasicGoal">
                <Form.Label>Goal</Form.Label>
                <Form.Control name="goal" type="text" placeholder="Block goal" value={data.goal} onChange={handleChange} />
            </Form.Group>
        }
    </Form>
    return <Modal isopen={open}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleOk={handleSubmit}
        modalTitle="Add New Block"
        modalBody={modalBody}
    />
}

function mapState(state) {
    return {};
}
const actionCreators = {
    addNewBlock: moduleActions.addNewBlock,
    verifyAddNewBlock: moduleActions.verifyAddNewBlock,
    updateBlockResource: moduleActions.updateBlockResource,
    updateBlockAudioResource: moduleActions.updateBlockAudioResource,
};

const connectedNewBlockModal = connect(mapState, actionCreators)(NewBlockModal);
export { connectedNewBlockModal as NewBlockModal };
