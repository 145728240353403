export const userCL3Constants = {
    CL3_CLEAR_ALL: "CL3_CLEAR_ALL",

    ORGANIZATION_LOGIN: "ORGANIZATION_LOGIN",

    ORGANIZATION_LOGIN_REQUEST: "ORGANIZATION_LOGIN_REQUEST",
    ORGANIZATION_LOGIN_SUCCESS: "ORGANIZATION_LOGIN_SUCCESS",
    ORGANIZATION_LOGIN_FAILURE: "ORGANIZATION_LOGIN_FAILURE",

    ORGANIZATION_LOGOUT: "ORGANIZATION_LOGOUT",

};
