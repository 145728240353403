import React from "react";
import { Button } from "_components/Base";

export const InstructionsList = ({ instructions, handleSelectInstruction, selectedInstruction, height, width, ...props }) => {
    const buttonProps = (instruction) => {
        const classNames = [instruction.id === selectedInstruction.id ? 'active' : ''];
        classNames.push("px-auto", "mx-auto")
        return {
            className: classNames.join(" "),
            variant: 'outline-primary',
            style: {
                width: "45%", maxHeight: "70px",
                height: `${parseInt((100 - 1) / 10 / Math.ceil(instructions.length / 2)) * 10}%`,
                fontSize: "xx-large"
            },
            onClick: handleSelectInstruction(instruction)
        };
    }

    return <div className="d-flex flex-row flex-wrap justify-content-start align-content-around"
        style={{ height: `${height}px`, width: `${width}px` }}
    >
        {
            instructions.map((instruction, indx) => (
                <Button key={`instruction-list-${indx}`}
                    {...buttonProps(instruction)}
                >
                    {instruction.name}
                </Button>
            ))
        }
    </div >
}