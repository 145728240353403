import React, { Component, useEffect, useState } from "react";
import Moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { connect } from "react-redux";
import { resourceActions, alertActions, scheduleActions, organizationActions } from "_actions";
import { Form, Col, Row, Table, Button } from "react-bootstrap";

import Modal from "./Modal";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";
import { isPartiallyEmittedExpression, isThisTypeNode, setConstantValue } from "typescript";

import { extendMoment } from 'moment-range';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Next } from "react-bootstrap/esm/PageItem";



const moment = extendMoment(Moment);


const Agenda = (props) => {
  const [resourceList, setResources] = useState([]);
  const [allEvents, setAllEvents] = useState([]);

  const [checkedResource, setCheckedResource] = useState(false);
  const [checkedUser, setCheckedUser] = useState(false);

  useEffect(() => {
    async function fetchData() {
        const _resources = await props.listResources(props.user.organization.id + "")
        var _list = []
        async function getListOfEvents(i,max){
          if(i < max){
            const _list_events = await props.listResourceSchedule(_resources[i].id).then(result => {
        
              _list = _list.concat(result)
              getListOfEvents(i+1,max)
              }
              )

          } else {
            setAllEvents(_list)
            setFilteredEvents(_list)
          }
        }
        var i = 0
        var max = _resources.length
        getListOfEvents(i,max)

        setResources(_resources);
        
    }
    fetchData();
    }, []) //only get once

    const [currentResource, setResourceNumber] = useState(0);
    const [currentUser, setCurrentUser] = useState("")
    const [filteredEvents, setFilteredEvents] = useState([])

    const updateUser = (e) => {
      if(checkedUser){
        setCurrentUser(e.target.value)
        var filtered = allEvents.filter(event => event.user == e.target.value 
          && (!checkedResource || checkedResource && event.resource_id == resourceList[currentResource].id))
        setFilteredEvents(filtered)
      }
    }

    const resourceChange = (e) => {
      if(checkedResource){
        setResourceNumber(e.target.value)
        var filtered = allEvents.filter(event => event.resource_id == resourceList[e.target.value].id
          && (!checkedUser || checkedUser && event.user == currentUser))
        setFilteredEvents(filtered)
      }
    }

    const getResourceName = (e) => {
      var a = resourceList.filter(resource => resource.id == e)
      return a[0].name
    }

    const getUniqueUsers = (e) => {
      var userValues = allEvents.map( (value) => value.user).filter( (value, index, _arr) => _arr.indexOf(value) == index);
      return userValues
    }

    const updateCheckedResource = () => {
      if(!checkedResource == false){
        var filtered = allEvents.filter(event => (!checkedUser || checkedUser && event.user == currentUser))
        setFilteredEvents(filtered)
      } else {
        var filtered = allEvents.filter(event => event.resource_id == resourceList[currentResource].id
          && (!checkedUser || checkedUser && event.user == currentUser))
        setFilteredEvents(filtered)
      }
      setCheckedResource(!checkedResource)

    }



    const updateCheckedUser = () => {
      if(currentUser == ""){
        setCurrentUser(getUniqueUsers()[0])
      }
      if(!checkedUser == false){
        var filtered= allEvents.filter(event => (!checkedResource || checkedResource && event.resource_id == resourceList[currentResource].id))
        setFilteredEvents(filtered)
      } else {
        var filtered = []
        if(currentUser == ""){
          filtered = allEvents.filter(event => (!checkedResource || checkedResource && event.resource_id == resourceList[currentResource].id) &&
          event.user == getUniqueUsers()[0])
        } else {
          filtered = allEvents.filter(event => (!checkedResource || checkedResource && event.resource_id == resourceList[currentResource].id) &&
          event.user == currentUser)
        }        
        setFilteredEvents(filtered)
      }

      setCheckedUser(!checkedUser)
    }





  return (<div style={{marginLeft: '0.8rem'}}>
    <Row>
      <Col sm ={2}>
      <input
        name="a"
        type="checkbox"
        checked={checkedResource}
        onChange={updateCheckedResource}
      />
      <label style={{marginLeft: '0.8rem'}}>Filter by resource</label>
      
      </Col>
      <Col>
    <Form.Control as="select" aria-label="Default select example"
  value = {currentResource}
  onChange={resourceChange}>
    {

    Object.keys(resourceList).map((key, element) => {
      return (<option key={element} value={element}>
        {element}: {resourceList[element].name}</option> );
      })
    }
  </Form.Control>
  </Col>
  </Row>

  <Row>
      <Col sm ={2}>
      <input
        name="a"
        type="checkbox"
        checked={checkedUser}
        onChange={updateCheckedUser}
      />
      <label style={{marginLeft: '0.8rem'}}>Filter by user</label>
      
      </Col>
      <Col>
    <Form.Control as="select" aria-label="Default select example"
  value = {currentUser}
  onChange={updateUser}>
    {

    getUniqueUsers().map(element => {
      return (<option key={element} value={element}>
        {element}</option> );
      })
    }
  </Form.Control>
  </Col>
  </Row>

  <Table striped bordered hover size="sm">
  <thead>
    <tr>
      <th>User</th>
      <th>Resource</th>
      <th>Location</th>
      <th>Start</th>
      <th>End</th>
      <th>Participants</th>
      <th>Instance</th>
    </tr>
  </thead>
  <tbody>

  {
    Object.keys(filteredEvents).map(function (key, element){

      return (<tr key={element}>
      <td>{filteredEvents[element].user}</td>
      <td>{getResourceName(filteredEvents[element].resource_id)}</td>
      <td>{filteredEvents[element].location}</td>
      <td>{moment(filteredEvents[element].start).format('MMMM Do YYYY, h:mm a')}</td>
      <td>{moment(filteredEvents[element].end).format('MMMM Do YYYY, h:mm a')}</td>
      <td>{filteredEvents[element].participants}</td>
      <td>{filteredEvents[element].resource_number}</td>
    </tr>);
    })
  }
  </tbody>
  </Table>
</div>);
}

Agenda.title = (date, { localizer }) => "Agenda";


function mapState(state) {
  // const resourceList = state.resourceList;
  const { loggedIn, user } = state.authentication;
  return { loggedIn, user };
  // return {resourceList};
}
const actionCreators = {
  listResources: resourceActions.listResources,
  listResourceSchedule: scheduleActions.listResourceSchedule,
  createSchedule: scheduleActions.createSchedule,
  listOrgUsers: organizationActions.listOrgUsers,
  deleteSchedule: scheduleActions.deleteSchedule,
  updateSchedule: scheduleActions.updateSchedule,
  errorAlert: alertActions.error()
};

const connectedAgenda = connect(mapState, actionCreators)(Agenda);
export { connectedAgenda as Agenda };
