import React from "react";
const Table = ({
    headers = [],
    rows = [],
    className = "",
    widths = [],
    ...rest
}) => {
    return (
        <table className={`table ${className}`}>
            {headers && (
                <thead>
                    <tr>
                        {headers &&
                            headers.map((header, indx) => {
                                let widthObj =
                                    widths && widths[indx] ? { width: widths[indx] } : {};
                                return (
                                    <th key={`table_th_${indx}`} {...widthObj}>
                                        {header}
                                    </th>
                                );
                            })}
                    </tr>
                </thead>
            )}
            <tbody>
                {rows.map((row, indx) => (
                    <tr key={"table_tr_" + indx}>
                        {row.map((item, inxy) => (
                            <td
                                key={`table_tr_${indx}_td_${inxy}`}
                                style={{ verticalAlign: "middle" }}
                            >
                                {item}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
export { Table };
