import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Button, Row, Col } from "_components/Base";
import { Modal } from "_components/Base";


import { genericActions } from "_actions";

const CreateVoiceCommandModal = ({ open, handleClose, handleAction, ...props }) => {
    const [localVoiceCommandStack, setLocalVoiceCommandStack] = useState({
        onCommandName: "",
        search: [{}],
        after: {},
        example: "",
        priority: -1
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = { ...localVoiceCommandStack };
        if (!result.onCommandName || result.onCommandName.length < 1) {
            alert("onCommandName must not be empty");
            return;
        }
        result.search = localVoiceCommandStack.search
            .filter(o => o.keys && o.keys.trim().length > 0)
            .map(o => {
                const kwargs = { keys: o.keys.split("|").map(v => v.trim()).filter(v => v && v.length > 0) };
                const fuz = parseFloat(o.fuzzy)
                if (!isNaN(fuz) || fuz > 0) {
                    kwargs.options = { fuzzy: fuz };
                }
                return kwargs;
            });

        if (localVoiceCommandStack.after.keys && localVoiceCommandStack.after.keys.length > 0) {
            const fuz = parseFloat(localVoiceCommandStack.after.fuzzy);
            const kwargs = { keys: localVoiceCommandStack.after.keys.split('|').map(v => v.trim()).filter(v => v && v.length > 0) }
            if (!isNaN(fuz) || fuz > 0) {
                kwargs.options = { fuzzy: localVoiceCommandStack.after.fuzzy };
            }
            result.after = kwargs;
        } else {
            result.after = {};
        }

        try {
            const { id } = await props.createVoiceCommandStack(result);
            result["id"] = id
        } catch (err) {
            console.log(err);
            return;
        }
        handleAction(result);
        handleClose();
    }

    const modalBody = <>
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicOnCommandName">
                <Form.Label>onCommandName</Form.Label>
                <Form.Control name="onCommandName" type="text"
                    value={localVoiceCommandStack.onCommandName}
                    onChange={(e) => {
                        setLocalVoiceCommandStack({
                            ...localVoiceCommandStack,
                            onCommandName: e.target.value
                        })
                    }} />
            </Form.Group>
            <h5>Search.Keys</h5>
            <p className="small">
                Use | for logical OR. If no "|" were found - system searches exact match in sentence.
                To add logical AND - add AND condition. To remove - leave it empty.
            </p>
            <Button onClick={(e) => {
                e.preventDefault();
                setLocalVoiceCommandStack({
                    ...localVoiceCommandStack,
                    search: [...localVoiceCommandStack.search, { keys: "", fuzzy: 0 }]
                })
            }}>Add AND condition</Button>

            {localVoiceCommandStack.search.map((o, i) => {
                return <div key={`div-${i}`} ><Row>
                    <Col md={10}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>Search.Keys</Form.Label>
                            <Form.Control name="keys" type="text"
                                value={localVoiceCommandStack.search[i].keys}
                                onChange={(e) => {
                                    setLocalVoiceCommandStack({
                                        ...localVoiceCommandStack,
                                        search: localVoiceCommandStack
                                            .search.map((v, j) => j === i ? { ...v, keys: e.target.value } : v)
                                    })
                                }} />
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>Fuzzy</Form.Label>
                            <Form.Control name="fuzzy" type="number"
                                value={localVoiceCommandStack.search[i].fuzzy}
                                onChange={(e) => {
                                    setLocalVoiceCommandStack({
                                        ...localVoiceCommandStack,
                                        search: localVoiceCommandStack
                                            .search.map((v, j) => j === i ? { ...v, fuzzy: parseFloat(e.target.value) } : v)
                                    })
                                }} />
                        </Form.Group>
                    </Col>
                </Row>
                    <hr />
                </div>
            })}

            <hr />

            <Row>
                <Col md={10}>
                    <Form.Group controlId="formBasicName">
                        <Form.Label>After.Keys (Logical OR | is possible)</Form.Label>
                        <Form.Control name="key" type="text"
                            value={localVoiceCommandStack.after.keys || ""}
                            onChange={(e) => {
                                setLocalVoiceCommandStack({
                                    ...localVoiceCommandStack,
                                    after: {
                                        ...localVoiceCommandStack.after,
                                        keys: e.target.value
                                    }
                                })
                            }} />
                    </Form.Group>
                </Col>
                <Col md={2}>
                    <Form.Group controlId="formBasicName">
                        <Form.Label>After.Fuzzy</Form.Label>
                        <Form.Control name="fuzzy" type="number"
                            value={localVoiceCommandStack.after.fuzzy}
                            onChange={(e) => {
                                setLocalVoiceCommandStack({
                                    ...localVoiceCommandStack,
                                    after: {
                                        ...localVoiceCommandStack.after,
                                        fuzzy: parseFloat(e.target.value)
                                    }
                                })
                            }} />
                    </Form.Group>
                </Col>
            </Row>


            <hr />

            <Form.Group controlId="formBasicName">
                <Form.Label>Example string</Form.Label>
                <Form.Control name="example" type="text"
                    value={localVoiceCommandStack.example}
                    onChange={(e) => {
                        setLocalVoiceCommandStack({
                            ...localVoiceCommandStack,
                            example: e.target.value
                        })
                    }} />
            </Form.Group>
        </Form>
    </>;
    return <Modal isopen={open}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleOk={handleSubmit}
        modalTitle='Create Voice Command stack'
        modalBody={modalBody}
    />
}

function mapState(state) {
    return {};
}

const actionCreators = {
    getVoiceCommandStack: genericActions.getVoiceCommandStack,
    updateVoiceCommandStack: genericActions.updateVoiceCommandStack,
    createVoiceCommandStack: genericActions.createVoiceCommandStack,
    swapVoiceCommandStacks: genericActions.swapVoiceCommandStacks,
};

const connectedCreateVoiceCommandModal = connect(mapState, actionCreators)(CreateVoiceCommandModal);
export { connectedCreateVoiceCommandModal as CreateVoiceCommandModal };