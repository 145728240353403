import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { blockActions, moduleActions } from "_actions";
import { ButtonGroup, Button } from "_components/Base";
import { Resource } from "_components";

const PreviewResourcePanel = ({ item, width, height, ...props }) => {
    const { id, audio_url, aspect_ratio } = item;
    const [resource, setResource] = useState({ url: "", expiresAt: "" });
    const [audioPlaying, setAudioPlaying] = useState(false);
    const audioSource = useRef();
    const audioBuffer = useRef();
    const context = useRef();
    const resetAudio = () => {
        context.current = new window.AudioContext();
        if (audioSource.current) {
            audioSource.current.stop();
        }
        setAudioPlaying(false);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await props.previewResource(id);
                setResource({ ...resource, url: res.url, expiresAt: res.expires_at });
                if (audio_url) {

                }
            } catch (err) {
                console.log(err);
            }
        }
        fetchData();
        resetAudio();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    const createAudioSourceAndStart = () => {
        audioSource.current = context.current.createBufferSource();
        audioSource.current.buffer = audioBuffer.current;
        audioSource.current.connect(context.current.destination);
        audioSource.current.start();
        audioSource.current.current_block_id = id;
        audioSource.current.addEventListener('ended', () => {
            setAudioPlaying(false);
        }, false);
        setAudioPlaying(true);
    }

    const playAudioResource = async (e) => {
        if (!audio_url) {
            return;
        }
        try {
            if (audioSource.current) {
                createAudioSourceAndStart();
                return;
            }
            context.current = new window.AudioContext();
            const response = await props.previewAudioResource(id);
            const audioResponse = await fetch(response.url);
            const arrayBuffer = await audioResponse.arrayBuffer();
            audioBuffer.current = await context.current.decodeAudioData(arrayBuffer);
            createAudioSourceAndStart()
        } catch (err) {
            console.log(err);
        }
    }
    const stopAudioResource = (e) => {
        if (!audio_url || !audioPlaying) {
            return;
        }
        try {
            audioSource.current.stop();
            setAudioPlaying(false);
        } catch (err) {
            console.log(err);
        }
    }

    const preventDefault = (e) => { e.preventDefault() }

    let calcWidth = width, calcHeight = height;
    if (aspect_ratio > 1) {
        calcHeight = calcWidth / aspect_ratio;
    } else {
        calcWidth = aspect_ratio * calcHeight;
    }
    const core = <div className="d-flex flex-row p-2 justify-content-center">
        {/* <Resource url={resource.url} width={400} height={400} /> */}
        <Resource url={resource.url} width={calcWidth} height={calcHeight} />
    </div>
    return <div className="full-width" onContextMenu={preventDefault}>
        {core}
        {audio_url &&
            <ButtonGroup>
                <Button variant="outline-primary" onClick={playAudioResource}>Play audio</Button>
                <Button variant="outline-primary" onClick={stopAudioResource}
                    disabled={!audioPlaying}
                >
                    Stop audio
                </Button>
            </ButtonGroup>
        }
    </div>;
}

function mapState(state) {
    return {};
}
const actionCreators = {
    previewResource: blockActions.previewResource,
    previewAudioResource: blockActions.previewAudioResource,
    deleteBlockFeedback: moduleActions.deleteBlockFeedback,
};

const connectedPreviewResourcePanel = connect(mapState, actionCreators)(PreviewResourcePanel);
export { connectedPreviewResourcePanel as PreviewResourcePanel };
