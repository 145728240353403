import { useEffect, useRef, useReducer } from "react";

const ROLES = { user: "user", instructor: "instructor" };

/*
* onSocketIncomingCallPlug(user, usertype, callback) is a function to show custom confirmation window with callback following to resume operation in socket space
*/
const useMicroTalkWrapper = ({ socketRef, usertype, onSocketIncomingCallPlug, onPreConfirmCallAction, onPostConfirmCallAction, onAcceptIncomingCall, onHangUp, ...props }) => {
    const [, forceRerender] = useReducer(x => x + 1, 0);
    const socket = useRef();
    const callRequest = useRef({});
    const instructors = useRef([]);
    const users = useRef([]);
    const onCall = useRef(false);
    const isOutgoingCall = useRef(false);

    /* =====Notify functions===== */
    function onSocketError({ error }) {
        props.alertActions(error);
    }
    function onSocketListInstructors(_instructors) {
        instructors.current = _instructors;
        forceRerender();
    }
    function onSocketListUsers(_users) {
        users.current = _users;
        forceRerender();
    }
    function onSocketNewInstructor(userObj) {
        if (!instructors.current) instructors.current = [];
        const existingIndx = instructors.current.findIndex(o => o.sid === userObj.sid);
        if (existingIndx > -1) {
            console.log("Existing 'new' instructor");
            return;
        }
        console.log("new instructor", userObj);
        instructors.current.push(userObj);
        forceRerender();
    }
    function onSocketNewUser(userObj) {
        if (!users.current) users.current = [];
        const existingIndx = users.current.findIndex(o => o.sid === userObj.sid);
        if (existingIndx > -1) {
            console.log("Existing 'new' user");
            return;
        }
        console.log("new user", userObj);
        users.current.push(userObj);
        forceRerender();
    }
    function onSocketRemoveInstructor({ sid }) {
        if (!instructors.current) return;
        instructors.current = instructors.current.filter(o => o.sid !== sid);
        forceRerender();
    }
    function onSocketRemoveUser({ sid }) {
        if (!users.current) return;
        users.current = users.current.filter(o => o.sid !== sid);
        forceRerender();
    }
    function onSocketChangeUserAvailability({ sid, available }) {
        if (!users.current) return;
        users.current = users.current.map(o => {
            if (o.sid !== sid) return o;
            return { ...o, available }
        });
        forceRerender();
    }
    function onSocketChangeInstructorAvailability({ sid, available }) {
        if (!instructors.current) return;
        instructors.current = instructors.current.map(o => {
            if (o.sid !== sid) return o;
            return { ...o, available }
        });
        forceRerender();
    }

    function __getUserBySid(sid, callertype) {
        function findUser(sid, arr, usertype) {
            const user = arr.find(o => o.sid === sid);
            return [user, usertype];
        }
        let user, usertype = callertype;
        if (usertype === ROLES.user) {
            [user, usertype] = findUser(sid, users.current, usertype);
        } else if (usertype === ROLES.instructor) {
            [user, usertype] = findUser(sid, instructors.current, usertype);
        } else {
            [user, usertype] = findUser(sid, users.current, ROLES.user);
            if (!user) {
                [user, usertype] = findUser(sid, instructors.current, ROLES.instructor);
            }
        }
        return [user, usertype];
    }

    function onSocketIncomingCall({ from, callertype }) {
        const [user, usertype] = __getUserBySid(from, callertype);
        const notification = new Notification(
            "Incoming call",
            {
                requireInteraction: true,
                icon: "https://icons.iconarchive.com/icons/icons8/windows-8/64/Mobile-Phone-icon.png"
            });
        if (onPreConfirmCallAction) onPreConfirmCallAction();
        const callback = (accept) => {
            notification.close();
            if (onPostConfirmCallAction) onPostConfirmCallAction();
            if (accept) {
                // do something here
                callRequest.current = { callRequestSid: from, callRequestEmail: user.email }
                isOutgoingCall.current = false;
                if (onAcceptIncomingCall) {
                    onAcceptIncomingCall();
                }
            }
            socketReactToCall(from, accept);
            forceRerender();
        }
        if (onSocketIncomingCallPlug)
            onSocketIncomingCallPlug(user, usertype, callback);
        else {
            const accept = window.confirm(`Do you want to accept call from ${user.email} (${usertype})?`);
            callback(accept);
        }
    }
    function onSocketReactIncomingCall({ from, accept }) {
        const [user,] = __getUserBySid(from);
        if (!user) {
            window.alert("Error: got invite from `we don't know who` :D")
            handleHangup()
            return;
        }
        if (!accept) {
            window.alert(`Call connection to ${user.email} has been rejected`)
            handleHangup()
            return;
        }
        callRequest.current = { callRequestSid: from, callRequestEmail: user.email }
        console.log(callRequest.current)
        isOutgoingCall.current = true;
        forceRerender();
    }

    function handleHangup() {
        callRequest.current = {}
        isOutgoingCall.current = false;
        console.log("Handle HANGUP");
        if (onHangUp) onHangUp();
        forceRerender();
    }

    function socketInitiateCall(user) {
        if (onCall.current) {
            window.alert("You can't make more than one call at a time")
            return;
        }
        if (!user.available) {
            window.alert("User is not available right now")
            return;
        }
        socket.current.emit("microtalk-initiate-call", { sid: user.sid })
    }
    function socketReactToCall(sid, accept) {
        socket.current.emit("microtalk-react-incoming-call", { sid, accept })
    }

    useEffect(() => {
        if (!socketRef) return;
        socket.current = socketRef;
        async function fetchData() {
            try {
                const token = await props.getMAClientToken();
                if (usertype === ROLES.user)
                    socket.current.emit("microtalk-user-auth", { token });
                else if (usertype === ROLES.instructor) {
                    socket.current.emit("microtalk-instructor-auth", { token });
                } else {
                    return;
                }

                socket.current.on("microtalk-notify-error", onSocketError)
                socket.current.on("microtalk-notify-list-instructors", onSocketListInstructors)
                socket.current.on("microtalk-notify-list-users", onSocketListUsers)
                socket.current.on("microtalk-notify-new-instructor", onSocketNewInstructor)
                socket.current.on("microtalk-notify-new-user", onSocketNewUser)
                socket.current.on("microtalk-notify-remove-instructor", onSocketRemoveInstructor)
                socket.current.on("microtalk-notify-remove-user", onSocketRemoveUser)
                socket.current.on("microtalk-notify-change-user-availability", onSocketChangeUserAvailability)
                socket.current.on("microtalk-notify-change-instructor-availability", onSocketChangeInstructorAvailability)
                socket.current.on("microtalk-notify-incoming-call", onSocketIncomingCall)
                socket.current.on("microtalk-notify-react-incoming-call", onSocketReactIncomingCall)

            } catch (err) {
                console.log(err)
            }
        }
        fetchData();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketRef])

    return [instructors, users, socket, callRequest, isOutgoingCall, forceRerender, socketInitiateCall];
}

export default useMicroTalkWrapper;