import React, { useState } from 'react';
import { connect } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { organizationActions } from '_actions';

const CreateOrgPanel = (props) => {
    const [email, setEmail] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    async function handleSubmit(e) {
        e.preventDefault();
        try {
            await props.createOrganization(organizationName, email);
        } catch (err) {
            console.log(err);
        }
    }
    return <>
        <h3>Create Organization</h3>
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicTitle">
                <Form.Label>Organization Name</Form.Label>
                <Form.Control type="text" value={organizationName} onChange={(e) => { setOrganizationName(e.value); }} placeholder="Organization" />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
                <Form.Label>Manager email address</Form.Label>
                <Form.Control type="email" value={email} onChange={(e) => { setEmail(e.value); }} placeholder="Enter email" />
            </Form.Group>

            <Button variant="outline-dark" type="submit" onClick={handleSubmit}>
                Create
            </Button>
        </Form>
    </>
}


function mapState(state) {
    return {};
}
const actionCreators = {
    createOrganization: organizationActions.createOrganization
};

const connectedCreateOrgPanel = connect(mapState, actionCreators)(CreateOrgPanel);
export { connectedCreateOrgPanel as CreateOrgPanel };
