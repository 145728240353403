import moment from "moment";
export const normalizeTimer = (seconds = 0) => {
    const hour = Math.floor(seconds / (60 * 60));
    const minute = Math.floor(seconds / 60 - hour * 60);
    const second = seconds % 60;
    return { second, minute, hour }
}

export const toSeconds = (hour = 0, minute = 0, second = 0) => {
    return (hour * 60 + minute) * 60 + second;
}

/**
 * 
 * @param {Integer} timestamp 
 * @returns duration from time stamp to now as string with all the units separated with ":"
 */
export const fromNowFormal = (timestamp) => {
    const dur = moment.duration({ from: new Date(timestamp), to: new Date() });
    const _map = [dur.years(), dur.months(), dur.days(), dur.hours(), dur.minutes(), dur.seconds()];
    let indx = Math.min(_map.findIndex(o => o > 0), _map.length - 2);
    function lpad(num) {
        return `${num}`.padStart(2, "0")
    }
    return _map.slice(indx).map(o => lpad(o)).join(":");
}