import React, { useEffect, useState, useRef } from 'react';
import { alertActions, moduleActions } from "_actions";
import { connect } from "react-redux";
import { TreeView } from "_components";
import { history, joinObjects, sortModulesInstructions } from "_helpers";
import { Button } from "_components/Base";

import { getObjectValueByMultilevelKey } from "_api";
import { CreateModuleModal } from "./CreateModuleModal";
import { NewBlockModal } from "./NewBlockModal";
import { UpdateBlockModal } from "./UpdateBlockModal";
import { TreeNodeAI } from "./TreeNode";
import { DeleteBlockModal } from './DeleteBlockModal';
import { DeleteModuleModal } from './DeleteModuleModal';
import { AddExistingBlockModal } from './AddExistingBlockModal';
import { AddDuplicateBlockModal } from './AddDuplicateBlockModal';
import { PreviewResourcePanel } from './PreviewResourcePanel';
import { UpdateConnectedBlocksModal } from './UpdateConnectedBlocksModal';
import lodashClonedeep from "lodash.clonedeep";

import { GearIcon, HomeIcon } from '@primer/octicons-react';

import 'react-contexify/dist/ReactContexify.css';
import config from '_configs';

import { useContainerDimensions } from "_hooks/useResize";

// ATTENTION: item.is_instruction - means boolean value if it the item (instruction/module/top-block) is an instruction (true) or a module (false) for visual representation
// ATTENTION: item.isInstructionItem - is temp variable to show that the item is an instruction/module and has to be updated with getHierarchy to convert to blocks hierarchy

// custom function for destructure item.level and getting object from items
// ATTENTION: in get_block_hierarchy everything starts from 1 (due to the ordering)
const index_correction = (indx) => indx - 1; // server,db (1) -> array (0)
const inverted_index_correction = (indx) => indx + 1; // array (0) -> server,db (1) 
const data_reducer_no_correction = (acc, key) => {
    return acc.children ? acc.children[key] : acc[key]
};
const data_reducer = (acc, key) => {
    return data_reducer_no_correction(acc, index_correction(key));
};

const getParentLevel = (level, sep = ",") => {
    let lastSep = level.lastIndexOf(sep);
    return [lastSep, level.substr(0, lastSep)];
}

// works in place
const update_children_level = (parentObj, sep = ",") => {
    if (!parentObj.children || parentObj.children.length < 1)
        return;
    const parentLevel = parentObj.level;
    parentObj.children.forEach((child, indx) => {
        child.level = [parentLevel, inverted_index_correction(indx)].join(sep)
        update_children_level(child);
    })
}

// works in place
const update_disabled_menu_options = (parentObj, disabled = false, disableSourceModuleArray = []) => {
    let _disabled = disabled;
    if (!disabled) {
        const arr = disableSourceModuleArray.slice(-2)
        if (arr.length === 2 && arr[0] !== arr[1]) {
            _disabled = true;
        }
    }
    parentObj.disableMenuParent = _disabled;
    parentObj.children.forEach((child, indx) => {
        update_disabled_menu_options(child, _disabled, [...disableSourceModuleArray, parentObj.source_module_id]);
    })
}

const styles = {
    container: {
    },
    leftPanel: {
        minWidth: "200px",
        width: "40%",
    },
    titleText: {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        paddingLeft: "1rem"
    },
    bordered: {
        border: "2px solid",
    },
    rightPanel: {
        minWidth: "200px",
        width: "50%",
    }
}

const ModulesPage = (props) => {
    const [items, setItems] = useState([])
    const [modules, setModules] = useState([]);
    const [currentModule, setCurrentModule] = useState({ id: -1, name: "", cloned_from_module_ids: [], block_feedbacks_init: [], block_feedbacks: {} });
    const [modal, setModal] = useState({ open: false, type: "", misc: {}, currentModule: {} })
    const [modulesMeta, setModulesMeta] = useState({});
    const [activeBlockItem, setActiveBlockItem] = useState({});
    const rightPanelRef = useRef();

    const { width, height } = useContainerDimensions(rightPanelRef);

    useEffect(() => {
        const fetchData = async () => {
            // setItems(data);
            try {
                const res = await props.listModules()
                setModules(res.modules);
                setItems(res.modules.sort(sortModulesInstructions).map((o, i) => {
                    return {
                        id: o.id, children: [],
                        name: o.name,
                        isInstructionItem: true, // is instruction/module OR block item
                        level: `${inverted_index_correction(i)}`,
                        is_instruction: o.is_instruction // is instruction or module 
                    }
                }
                ))
            } catch (err) {
                console.log(err)
            }
        }
        fetchData();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onExpandClick = (item) => {
        let _items = lodashClonedeep(items);
        let _obj = getObjectValueByMultilevelKey(_items, item.level, ",", data_reducer);
        _obj.open = !_obj.open;
        setItems(_items)
    }

    const onModuleExpandClick = async (item) => {
        const res = await props.getBlockHierarchy(item.id);
        const newItems = items.map(o => {
            if (o.id !== item.id) return o;
            const _obj = res.hierarchy[0];
            _obj.open = !_obj.open;
            _obj.level = o.level;
            _obj.is_instruction = item.is_instruction; // check if it is module or instruction
            update_children_level(_obj)
            update_disabled_menu_options(_obj)
            return _obj;
        })
        const meta = ({
            id: item.id,
            name: "module.name", //just in case
            cloned_from_module_ids: res.cloned_from_module_ids,
            block_feedbacks_init: res.block_feedbacks,
            block_feedbacks: res.block_feedbacks.reduce((acc, cur) => {
                const { block_id, ...curRest } = cur;
                if (!acc[block_id]) acc[block_id] = []
                acc[block_id].push(curRest)
                return acc;
            }, {})
        })
        setItems(newItems);
        setModulesMeta({ ...modulesMeta, [item.id]: meta });
    }

    const onLeafItemClick = (item) => setActiveBlockItem(item)

    const modalBehaviorTrigger = (type, module_id, misc = {}) => {
        return (e) => {
            const open = !(modal.open && modal.type === type);
            setModal({ ...modal, type, open, misc, currentModule: modulesMeta[module_id] });
        }
    }

    const renderModalContent = () => {
        let _modal = <></>
        let _rest = {
            open: modal.open,
            currentModule: modal.currentModule,
            handleClose: () => setModal({ ...modal, open: false }),
            misc: modal.misc
        }
        switch (modal.type) {
            case "create-module":
                _modal = <CreateModuleModal
                    handleAction={(_module) => {
                        setModules([...modules, _module]);
                        setItems([...items, {
                            ..._module,
                            children: [], isInstructionItem: true,
                            level: `${inverted_index_correction(items.length)}`
                        }])
                    }}

                    {..._rest} />
                break;
            case "add-block":
                _modal = <NewBlockModal
                    handleAction={(item, parentBlockLevel) => {
                        let _items = lodashClonedeep(items);
                        let parentObj = getObjectValueByMultilevelKey(_items, parentBlockLevel, ",", data_reducer);
                        const order = inverted_index_correction((parentObj.children || []).length);
                        parentObj.children.push({ ...item, children: [], level: [parentBlockLevel, order].join(",") })
                        // check if smth selected(instr) previously to correct this selection
                        setItems(_items)
                    }}
                    {..._rest} />
                break;
            case "update-block":
                _modal = <UpdateBlockModal
                    handleAction={(modifiedItem, level, moduleUpdated) => {
                        let _items = lodashClonedeep(items);
                        let blockObj = getObjectValueByMultilevelKey(_items, level, ",", data_reducer);
                        Object.entries(modifiedItem).forEach(([k, v]) => blockObj[k] = v);
                        setItems(_items)
                        if (moduleUpdated) {
                            setCurrentModule({ ...currentModule, name: modifiedItem["name"] });
                            setModules(
                                modules.map(o => o.id !== currentModule.id ? o : { ...o, name: modifiedItem["name"] })
                            )
                        }
                        // if there is something in preview (usually there is the same block) - hide it
                        setActiveBlockItem({});
                    }}
                    {..._rest} />
                break;
            case "update-connected-blocks":
                _modal = <UpdateConnectedBlocksModal
                    handleAction={() => { }} // everything is done through modal - nothing to react on
                    {..._rest} />
                break;
            case "add-existing-block":
                _modal = <AddExistingBlockModal
                    handleAction={(hierarchy, sourceModuleIds, selectedModuleId, parentBlockLevel) => {
                        let _items = lodashClonedeep(items);
                        let parentObj = getObjectValueByMultilevelKey(_items, parentBlockLevel, ",", data_reducer);
                        const order = inverted_index_correction((parentObj.children || []).length);
                        // hierarchy always is assmbled as an array.
                        // In this situation it makes sense to destructure right away
                        let newBlock = hierarchy[0];
                        newBlock.level = [parentBlockLevel, order].join(",");
                        update_children_level(newBlock)
                        parentObj.children.push(newBlock)
                        update_disabled_menu_options(parentObj);
                        setItems(_items)
                        // process cloned_from_module_ids
                        setCurrentModule({
                            ...currentModule,
                            cloned_from_module_ids: Array.from(
                                new Set([...currentModule.cloned_from_module_ids, ...sourceModuleIds, selectedModuleId])
                            )
                        })
                    }}
                    {..._rest} />
                break;
            case "add-duplicate-block":
                _modal = <AddDuplicateBlockModal
                    handleAction={(hierarchy, sourceModuleIds, selectedModuleId, parentBlockLevel) => {
                        let _items = lodashClonedeep(items);
                        let parentObj = getObjectValueByMultilevelKey(_items, parentBlockLevel, ",", data_reducer);
                        const order = inverted_index_correction((parentObj.children || []).length);
                        // hierarchy always is assmbled as an array.
                        // In this situation it makes sense to destructure right away
                        let newBlock = hierarchy[0];
                        newBlock.level = [parentBlockLevel, order].join(",");
                        update_children_level(newBlock)
                        parentObj.children.push(newBlock)
                        update_disabled_menu_options(parentObj);
                        setItems(_items)
                        // process cloned_from_module_ids
                        setCurrentModule({
                            ...currentModule,
                            cloned_from_module_ids: Array.from(
                                new Set([...currentModule.cloned_from_module_ids, ...sourceModuleIds, selectedModuleId])
                            )
                        })
                    }}
                    {..._rest} />
                break;
            case "delete-block":
                _modal = <DeleteBlockModal
                    handleAction={(id, level) => {
                        let _items = lodashClonedeep(items);
                        let [lastSep, parentLevel] = getParentLevel(level, ",")
                        let parentObj = getObjectValueByMultilevelKey(_items, parentLevel, ",", data_reducer);
                        if (!parentObj.children || parentObj.children.length < 1) {
                            window.alert("Something is wrong: No children to delete")
                            return <></>
                        }
                        // delete child
                        parentObj.children.splice(index_correction(level.substr(parseInt(lastSep + 1))), 1);
                        // update levels
                        update_children_level(parentObj);
                        setItems(_items)
                    }}
                    {..._rest}
                />
                break;
            case "delete-module":
                _modal = <DeleteModuleModal
                    handleAction={(moduleId) => {
                        setItems(
                            items.filter(o => o.module_id !== moduleId)
                        )
                        const newModulesMeta = { ...modulesMeta };
                        delete newModulesMeta[moduleId];
                        setModulesMeta(newModulesMeta)
                        // setCurrentModule({ id: -1, name: "", cloned_from_module_ids: [] })
                        setModules(
                            modules.filter(_module => _module.id !== moduleId)
                        )
                    }}
                    {..._rest}
                />
                break;
            default:
                break;
        }
        return _modal;
    }

    const moveBlockSameParent = (item, isUp = true) => {
        return async () => {
            let _items = lodashClonedeep(items)
            const level = item.level;
            let [lastSep, parentLevel] = getParentLevel(level, ",")
            let parentObj = getObjectValueByMultilevelKey(_items, parentLevel, ",", data_reducer);
            if (!parentObj) {
                window.alert("No PARENT!");
                return;
            }
            const child_index = index_correction(level.substr(parseInt(lastSep + 1)));
            if (isUp && child_index - 1 < 0) {
                window.alert("It is a top element");
                return;
            } else if (!isUp && child_index + 1 > parentObj.children.length - 1) {
                window.alert("It is a bottom element");
                return;
            }
            const swappedElementsIndx = child_index - (isUp ? 1 : 0);
            let swappedElements = parentObj.children.splice(swappedElementsIndx, 2).reverse();
            const [blockId1, blockId2] = swappedElements.map(el => el.id);
            await props.swapBlocks(item.module_id, parentObj.id, blockId1, blockId2);
            // swap levels
            swappedElements.map(el => el.level).reverse()
                .forEach((lvl, indx) => {
                    swappedElements[indx].level = lvl;
                    update_children_level(swappedElements[indx])
                })
            parentObj.children.splice(swappedElementsIndx, 0, ...swappedElements);
            setItems(_items)
        }
    }


    const menuCommands = {
        newBlock: (item) => modalBehaviorTrigger("add-block", item.module_id, { parentBlockId: item.id, parentBlockLevel: item.level }),
        updateBlock: (item) => modalBehaviorTrigger("update-block", item.module_id, item),
        addExistingBlock: (item) => modalBehaviorTrigger("add-existing-block", item.module_id, { parentBlockId: item.id, parentBlockLevel: item.level }),
        addDuplicateBlock: (item) => modalBehaviorTrigger("add-duplicate-block", item.module_id, { parentBlockId: item.id, parentBlockLevel: item.level, siblingNames: item.children.map(o => o.name) }),
        deleteBlock: (item) => modalBehaviorTrigger("delete-block", item.module_id, { id: item.id, level: item.level, }),
        deleteModule: (item) => modalBehaviorTrigger("delete-module", item.module_id, {}),
        updateConnectedBlocks: (item) => modalBehaviorTrigger("update-connected-blocks", item.module_id, { id: item.id, level: item.level, }),
        moveBlockDown: (item) => moveBlockSameParent(item, false),
        moveBlockUp: (item) => moveBlockSameParent(item, true),
    }

    return <>
        {modal.open && renderModalContent()}
        <div className="d-flex justify-content-start px-4 pt-4" style={{ flex: "0 1 auto" }}>
            <div style={styles.leftPanel} className="mr-5" >
                <div className="d-flex justify-content-start" >
                    <Button variant="outline-secondary" className="mr-2 btn-lg" onClick={modalBehaviorTrigger("create-module")}>New</Button>
                    <Button variant="outline-secondary" className="mr-2 btn-lg" onClick={() => { }}><GearIcon /> </Button>
                    <Button variant="outline-secondary" className="btn-lg" onClick={() => { history.push(config.clientUrls.HOME) }}><HomeIcon /></Button>
                </div>
            </div>
        </div>
        <div className="d-flex justify-content-start px-4 pt-2" style={{ height: "calc(100vh - 150px)" }}>
            <div style={joinObjects(styles.bordered, styles.leftPanel)} className="mr-5 overflow-auto">
                <p style={styles.titleText}>All Modules</p>
                <TreeView
                    items={items} node={TreeNodeAI}
                    onExpandClick={onExpandClick}
                    onModuleExpandClick={onModuleExpandClick}
                    onLeafItemClick={onLeafItemClick}
                    commands={menuCommands} top={true}
                    activeElements={new Set([activeBlockItem.id])}
                />
            </div>

            <div style={joinObjects(styles.bordered, styles.rightPanel)} ref={rightPanelRef}>
                {activeBlockItem.id &&
                    (
                        <>
                            <p style={styles.titleText}>Preview window</p>
                            <PreviewResourcePanel item={activeBlockItem}
                                width={width - 20} height={height - 100} />
                        </>
                    )
                }
            </div>
        </div>
    </ >
}


function mapState(state) {
    return {};
}
const actionCreators = {
    getBlockHierarchy: moduleActions.getBlockHierarchy,
    listModules: moduleActions.listModules,
    swapBlocks: moduleActions.swapBlocks,
    errorAlert: alertActions.error,
};

const connectedModulesPage = connect(mapState, actionCreators)(ModulesPage);
export { connectedModulesPage as ModulesPage };
