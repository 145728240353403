import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { alertActions, organizationActions, userActions } from "_actions";
import { TableDropdown, Table, Modal } from "_components/Base";
import { ButtonGroup, Button, Form } from "react-bootstrap";

const UserList = ({ users, ...props }) => {
    const widths = [null, null, "18"];
    const headers = ["Email", "Roles", ""];
    const rows = users.map(user => {
        const dropdownItems = [];
        if (!user.roles.includes("manager")) {
            dropdownItems.push(
                {
                    name: "Delete user",
                    onClick: () => props.deleteUser(user.id),
                }
            )
        }

        if (user.roles.includes("user")) {
            dropdownItems.push(
                {
                    name: "Swap password",
                    onClick: () => props.swapPassword(user.id),
                }
            )
        } else {
            dropdownItems.push(...["instructor", "admin"]
                .filter(o => !user.roles.includes(o))
                .map(o => {
                    return {
                        name: `Add ${o} role`,
                        onClick: () => props.addRoles(user.id, [o])
                    }
                }))

        }
        return [user.email, user.roles.join(", "), <TableDropdown items={dropdownItems} />]
    })
    return <>
        <Table headers={headers} rows={rows} widths={widths} />
    </>
}

const CreateAdministratoveUserModalBody = ({ email, roles, handleChange }) => {
    const options = ["admin", "instructor"];

    return <>
        <Form>
            <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address prefix (before @)</Form.Label>
                <Form.Control type="text" placeholder="Email prefix" name="email" value={email} onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlSelect2">
                <Form.Label>Roles</Form.Label>
                <Form.Control as="select" rows={2} name="roles" onChange={handleChange} multiple>
                    {options.map((name, indx) => <option key={`options_${indx}`} name={name} value={name} >{name}</option>)}
                </Form.Control>
            </Form.Group>
        </Form>
    </>
}

const CreateIAMUserModalBody = ({ email, password, handleChange }) => {
    return <>
        <Form>
            <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address prefix (before @)</Form.Label>
                <Form.Control type="text" placeholder="Email prefix" name="email" value={email} onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" name="password" value={password} onChange={handleChange} />
            </Form.Group>

        </Form>
    </>
}


const UserManagerPanel = (props) => {
    const SORT_DICT = { manager: 0, instructor: 1, admin: 2, user: 3 };
    const [users, setUsers] = useState([]);
    const initNewUser = { modalOpen: false, isIAMUser: false, roles: [], email: "", password: "" };
    const [newUser, setNewUser] = useState(initNewUser);
    const sortFunc = (u1, u2) => Math.min(...u1.roles.map(r => SORT_DICT[r])) - Math.min(...u2.roles.map(r => SORT_DICT[r]));
    useEffect(() => {
        async function fetchData() {
            const _users = await props.listOrgUsers();
            setUsers(_users.sort(sortFunc));
        }
        fetchData();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function handleChange(e) {
        e.preventDefault();
        const { name, value, selectedOptions } = e.target;
        if (selectedOptions && selectedOptions.length > 0) {
            setNewUser({ ...newUser, roles: Object.values(selectedOptions).map(o => o.value) });
        } else {
            setNewUser({ ...newUser, [name]: value });
        }
    }
    async function handleCreateUser() {
        try {
            console.log(newUser);
            if (newUser.roles.length < 1) {
                return props.errorAlert("You must choose at least one role");
            }
            const user = await props.createUserWithRoles(newUser.email, newUser.password, newUser.roles, newUser.isIAMUser);
            setUsers([...users, user].sort(u => Math.max(u.roles.map(r => SORT_DICT[r]))));

            setNewUser(initNewUser);
        } catch (err) {
            console.log(err);
        }
    }
    async function handleCancel() {
        setNewUser(initNewUser);
    }
    async function addRoles(userId, roles) {
        try {
            const roles_added = await props.addRoles(userId, roles);
            if (roles_added && roles_added.length > 0)
                setUsers(users.map(user => user.id === userId ? { ...user, roles: [...user.roles, ...roles] } : user));
            else props.errorAlert("You must choose at least one role");
        } catch (err) {
            console.log(err)
        }
    }

    return <>
        {newUser.modalOpen &&
            (
                <Modal
                    handleOk={handleCreateUser}
                    handleCancel={handleCancel}
                    handleClose={handleCancel}
                    isopen={newUser.modalOpen}
                    modalTitle={"New User Form"}
                    modalBody={newUser.isIAMUser ?
                        <CreateIAMUserModalBody email={newUser.email} password={newUser.password} handleChange={handleChange} />
                        :
                        <CreateAdministratoveUserModalBody email={newUser.email} roles={newUser.roles} handleChange={handleChange} />}
                />
            )
        }
        <h3>Manage your team</h3>
        <>
            <ButtonGroup aria-label="Basic example">
                <Button variant="outline-dark" onClick={() => { setNewUser({ ...newUser, isIAMUser: true, roles: ['user'], modalOpen: true }) }}>New IAM User</Button>
                <Button variant="outline-dark" onClick={() => { setNewUser({ ...newUser, isIAMUser: false, modalOpen: true }) }}>New User</Button>

            </ButtonGroup>
        </>
        <UserList users={users} addRoles={addRoles} deleteUser={() => { }} swapPassword={() => { }} />
    </>
}


function mapState(state) {
    return {};
} //
const actionCreators = {
    listOrgUsers: organizationActions.listOrgUsers,
    createUserWithRoles: userActions.createUserWithRoles,
    addRoles: userActions.addRoles,
    errorAlert: alertActions.error()
};

const connectedUserManagerPanel = connect(mapState, actionCreators)(UserManagerPanel);
export { connectedUserManagerPanel as UserManagerPanel };
