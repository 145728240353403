import React, { useEffect, useState, useRef } from "react";
import { Timer } from "_components";
import { processCommand, processCommandAPI } from "_api/voice";
import { VCL, VOICE_CONFIG_NAMES } from "_api/voiceCommandLibrary";
import { Home, Instructions, Notes, Logs, Help } from "./panels";
import { useContainerDimensions } from "_hooks/useResize";

/**
 * 
 * Hub to process the voice and distribute over Home, Instructions, Notes, and Logs panels
 * 
 */
export const PageUserCabinet = ({ voiceCommand, voiceCommandStack, changeMute, ...props }) => {
    const containerRef = useRef();
    const { width, height } = useContainerDimensions(containerRef);
    const [mode, setMode] = useState(VOICE_CONFIG_NAMES.NONE);
    const [doMute, setDoMute] = useState(false);
    const [prevMode, setPrevMode] = useState(VOICE_CONFIG_NAMES.NONE);
    const [instructionsCommand, setInstructionsCommand] = useState({});
    const [logsCommand, setLogsCommand] = useState({});
    const [notesCommand, setNotesCommand] = useState({});

    const [timerTime, setTimerTime] = useState({});
    function __setCommandImpl(func, _mode, _command, _args) {
        setMode(_mode);
        func({ command: _command, args: _args });
    }
    function setInstructionsCommandImpl(_mode, ...args) {
        __setCommandImpl(setInstructionsCommand, _mode, ...args);
    }
    function setLogsCommandImpl(_mode, ...args) {
        __setCommandImpl(setLogsCommand, _mode, ...args);
    }
    function setNotesCommandImpl(_mode, ...args) {
        __setCommandImpl(setNotesCommand, _mode, ...args);
    }

    const cbs = {
        default: (cmd) => console.log('not recognized ', cmd),
        [VCL.onShowInstruction]: (cmd, candidateName) => {
            setInstructionsCommandImpl(
                VOICE_CONFIG_NAMES.INSTRUCTIONS_INSTRUCTION, VCL.onShowInstruction, [candidateName]);
        },
        [VCL.onShowAllInstructions]: (cmd) => {
            setInstructionsCommandImpl(VOICE_CONFIG_NAMES.INSTRUCTIONS_HOME, VCL.onShowAllInstructions);
        },
        [VCL.onShowAllLogs]: (cmd) => {
            setLogsCommandImpl(VOICE_CONFIG_NAMES.LOGS, VCL.onShowAllLogs);
        },
        [VCL.onShowAllNotes]: (cmd) => {
            setNotesCommandImpl(VOICE_CONFIG_NAMES.NOTES, VCL.onShowAllNotes);
        },
        [VCL.onHelp]: (cmd) => {
            const _mode = mode.slice(); //create a clone
            setPrevMode(_mode);
            let newMode = VOICE_CONFIG_NAMES.HELP_HOME;
            if (_mode === VOICE_CONFIG_NAMES.INSTRUCTIONS_HOME) {
                newMode = VOICE_CONFIG_NAMES.HELP_INSTRUCTION_HOME;
            } else if (_mode === VOICE_CONFIG_NAMES.INSTRUCTIONS_INSTRUCTION) {
                newMode = VOICE_CONFIG_NAMES.HELP_INSTRUCTION_INSTRUCTION;
            } else if (_mode === VOICE_CONFIG_NAMES.NOTES) {
                newMode = VOICE_CONFIG_NAMES.HELP_NOTES;
            } else if (_mode === VOICE_CONFIG_NAMES.LOGS) {
                newMode = VOICE_CONFIG_NAMES.HELP_LOGS;
            }
            setMode(newMode);
        },
        [VCL.onClose]: (cmd) => {
            if (prevMode === mode) return;
            setMode(prevMode);
        },
        [VCL.onNext]: (cmd) => {
            setInstructionsCommandImpl(
                VOICE_CONFIG_NAMES.INSTRUCTIONS_INSTRUCTION, VCL.onNext);
        },
        [VCL.onRestart]: (cmd) => {
            setInstructionsCommandImpl(
                VOICE_CONFIG_NAMES.INSTRUCTIONS_INSTRUCTION, VCL.onRestart);
        },
        [VCL.onBack]: (cmd) => {
            setInstructionsCommandImpl(
                VOICE_CONFIG_NAMES.INSTRUCTIONS_INSTRUCTION, VCL.onBack);
        },
        [VCL.onPlayAudio]: (cmd) => {
            setInstructionsCommandImpl(
                VOICE_CONFIG_NAMES.INSTRUCTIONS_INSTRUCTION, VCL.onPlayAudio);
        },
        [VCL.onStopAudio]: (cmd) => {
            setInstructionsCommandImpl(
                VOICE_CONFIG_NAMES.INSTRUCTIONS_INSTRUCTION, VCL.onStopAudio);
        },
        [VCL.onStartTimer]: (cmd, timesDict) => setTimerTime(timesDict),
        [VCL.onStopTimer]: (cmd) => setTimerTime({}),
        [VCL.onFarawell]: (cmd) => window.alert("This is the moment we will log out the user"),
        [VCL.onMute]: (cmd) => { changeMute(true); setDoMute(true); },
        [VCL.onUnmute]: (cmd) => { changeMute(false); setDoMute(false); },
        [VCL.onHome]: (cmd) => { setMode(VOICE_CONFIG_NAMES.NONE) },
        [VCL.default]: (cmd) => console.log(`Default command ${cmd}`),

        [VCL.onOpenInstructions]: (cmd) => {
            setInstructionsCommandImpl(VOICE_CONFIG_NAMES.INSTRUCTIONS_HOME, VCL.onShowAllInstructions);
        },
        [VCL.onOpenLogs]: (cmd) => { setLogsCommandImpl(VOICE_CONFIG_NAMES.LOGS, VCL.onShowAllLogs); },
        [VCL.onOpenNotes]: (cmd) => { setLogsCommandImpl(VOICE_CONFIG_NAMES.NOTES, VCL.onShowAllNotes); },
    };

    useEffect(() => {
        /* effect for the voice command processing */
        console.log(voiceCommand)
        if (!voiceCommand) return;
        const _command = voiceCommand.command;
        if (!_command || _command.trim().length < 1) return;
        if (voiceCommandStack && voiceCommandStack.length > 0) {
            processCommandAPI(voiceCommandStack, _command.trim(), cbs, mode, doMute);
        } else {
            window.alert("Can't process commands before receiving the commoandStack. Working with old scheme.")
            processCommand(_command.trim(), cbs, mode, doMute);
        }

        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voiceCommand])

    return <div className="full-width-vw full-height-vh" ref={containerRef}>
        <Home mode={mode}
            onOpenInstructions={cbs.onOpenInstructions}
            onOpenLogs={cbs.onOpenLogs}
            onOpenNotes={cbs.onOpenNotes}
            height={height}
            width={width}
        />
        <Instructions mode={mode}
            command={instructionsCommand}
            changeMode={(_mode) => { setMode(_mode); }}
            height={height}
            width={width}
        />
        <Logs mode={mode}
            command={logsCommand}
        />
        <Notes mode={mode}
            command={notesCommand}
        />
        <Help mode={mode}
            infoMode={prevMode} />
        <Timer onTimerEnd={() => setTimerTime({})} {...timerTime}
            height={height}
            width={width} />
    </div>
}