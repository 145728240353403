import React, { useState } from "react";
import { connect } from "react-redux";
import { moduleActions } from "_actions";
import { Modal, Form } from "_components/Base";

const CreateModuleModal = ({ open, handleClose, handleAction, ...props }) => {
    const [data, setData] = useState({ name: "", description: "", is_instruction: false });
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const newModule = await props.createModule(data.name, data.description, data.is_instruction);
            handleAction({ ...newModule, ...data });
        } catch (err) {
            console.log(err)
        }
        handleClose(e);
    }
    const _type = data.is_instruction ? "Instruction" : "Module";
    const modalBody = <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control name="name" type="text" placeholder={`${_type} name`}
                value={data.name}
                onChange={handleChange} />
            <Form.Text className="text-muted">
                This name will be used for both {_type.toLowerCase()} and its top block
            </Form.Text>
        </Form.Group>

        <Form.Group controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Create as Instruction"
                name="is_instruction" checked={data.is_instruction}
                onChange={(e) => { setData({ ...data, is_instruction: e.target.checked }) }} />
        </Form.Group>

        {data.is_instruction &&
            <Form.Group controlId="formBasicDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control name="description" type="text"
                    placeholder="Description" value={data.description} onChange={handleChange} />
            </Form.Group>
        }

    </Form>;
    return <Modal isopen={open}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleOk={handleSubmit}
        modalTitle={`Create ${_type}`}
        modalBody={modalBody}
    />
}

function mapState(state) {
    return {};
}
const actionCreators = {
    createModule: moduleActions.createModule,
};

const connectedCreateModuleModal = connect(mapState, actionCreators)(CreateModuleModal);
export { connectedCreateModuleModal as CreateModuleModal };
