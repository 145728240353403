import React from "react";
import { TreeNode } from "_components";

export const TreeNodeAI = ({ item, open, children, block_feedbacks = {}, onExpandClick, onModuleExpandClick, commands, top = false, ...rest }) => {
    const menuItems = [];
    if (commands && !item.isInstructionItem) {
        const disabled = item.source_module_id !== item.module_id;
        if (!item.is_leaf) {
            menuItems.push(
                { name: "Add new block", onClick: commands.newBlock(item), disabled },
            );
            if (item.is_instruction) {
                menuItems.push(
                    { name: "Add module blocks", onClick: commands.addExistingBlock(item), disabled },
                );
            } else {
                menuItems.push(
                    { name: "Add duplicate blocks", onClick: commands.addDuplicateBlock(item), disabled },
                );
            }
        }
        menuItems.push(
            { name: "Modify", onClick: commands.updateBlock(item), disabled },
        )

        if (top) {
            if (item.is_instruction) {
                menuItems.push(
                    { name: "Update connected blocks", onClick: commands.updateConnectedBlocks(item) },
                )
            }
            menuItems.push(
                { name: "Delete module", onClick: commands.deleteModule(item) },
            );
        } else {
            menuItems.push(
                { name: "Delete", onClick: commands.deleteBlock(item), disabled: item.disableMenuParent },
                { name: "Move up", onClick: commands.moveBlockUp(item), disabled: item.disableMenuParent },
                { name: "Move down", onClick: commands.moveBlockDown(item), disabled: item.disableMenuParent }
            );
        }
    }

    let selectedFullyClass = "";
    if (item.selectedFully) {
        selectedFullyClass = "tree-node-selected-fully";
    } else if (item.selectedFully === false) {
        selectedFullyClass = "tree-node-selected-partially";
    }
    return <TreeNode item={item} open={open}
        onExpandClick={item.isInstructionItem ? onModuleExpandClick : onExpandClick}
        menuItems={menuItems}
        className={selectedFullyClass}
        {...rest}
    >{children}</TreeNode>
}