import React from "react";
import { VOICE_CONFIG_NAMES } from "_api/voiceCommandLibrary";
import { Button } from "_components/Base";

export const Home = ({ mode, onOpenInstructions, onOpenLogs, onOpenNotes, width, height, ...props }) => {
    const hidden = mode !== VOICE_CONFIG_NAMES.NONE;
    if (hidden) return null;
    const buttonProps = {
        style: { height: "25%", width: "80%", fontSize: "xxx-large" },
        className: "px-auto mx-auto",
        variant: "outline-primary"
    }
    return (
        <div className="d-flex flex-column justify-content-around" style={{ height: `${height}px`, width: `${width}px` }}>
            <Button onClick={onOpenInstructions} {...buttonProps}>Instructions</Button>
            <Button onClick={onOpenLogs} {...buttonProps}>Logs</Button>
            <Button onClick={onOpenNotes} {...buttonProps}>Notes</Button>
        </div>
    );
}