import { userConstants } from "_constants";

function flagConstantGenerator(flagConstant) {
	return {
		loading: `loading_${flagConstant}`,
		loaded: `loaded_${flagConstant}`
	};
}

const loginFlags = flagConstantGenerator(userConstants.LOGIN);

const getProfileFlags = flagConstantGenerator(userConstants.USERS_GET_PROFILE);


export const userFlagConstants = {
	loginFlags,
	getProfileFlags,
};
