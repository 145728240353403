import config from "_configs";
import { authHeader, handleResponse } from "_helpers";

export const chatService = {
    createInstructorChatSession,
    createUserChatSession
};

function createInstructorChatSession() {
    const requestOptions = {
        method: "POST",
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/chat/instructor/session`, requestOptions).then(
        handleResponse
    );
}

function createUserChatSession() {
    const requestOptions = {
        method: "POST",
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/chat/user/session`, requestOptions).then(
        handleResponse
    );
}
