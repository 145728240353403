const extractLeaves = (obj) => {
    if (obj.is_leaf) return [obj];
    return obj.children
        .map(child => extractLeaves(child))
        .reduce((acc, cur, indx) => {
            // each element is array
            acc.push(...cur);
            return acc;
        }, [])
}

export { extractLeaves }