import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { alertActions, userActions } from "_actions";
import { history } from "_helpers";
import { Button, Collapse, Modal } from "_components/Base";
import { StatusIcon } from "_components";
import { MicroTalkConnect } from "./MicroTalkConnect";
import useMicroTalkWrapper from "_hooks/sockets/useMicroTalkWrapper";
import { Howl } from 'howler';
import ringtone from '_sounds/ringtone.mp3'

const ROLES = { user: "user", instructor: "instructor" };
const styles = {
    container: {
    },
    leftPanel: {
        minWidth: "150px",
        width: "30%",
    },
    titleText: {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        paddingLeft: "1rem"
    },
    bordered: {
        border: "2px solid",
    },
    rightPanel: {
        minWidth: "200px",
        width: "70%",
    },
    contactWindow: {
        position: "fixed",
        bottom: "10px",
        right: "10px",
        backgroundColor: "rgba(0,0,255,0.1)"
    },
    chatarea: {
        position: "fixed",
        bottom: "10px",
        left: "10px",
        backgroundColor: "rgba(0,0,255,0.1)",
        maxWidth: "50vw"
    }
}

const AvailableUsersCollapsible = ({ children, title = "Contacts", ...rest }) => {
    const [open, setOpen] = useState(false);
    return (
        <div {...rest} className="d-flex flex-column" style={styles.contactWindow}>
            <Collapse in={open}>
                <div id="collapse-contact-list" className="mb-3 px-2 pt-2">
                    {children}
                </div>
            </Collapse>
            <Button
                onClick={() => setOpen(!open)}
                aria-controls="collapse-contact-list"
                aria-expanded={open}
            >
                {title}
            </Button>
        </div>
    );
}

const MicroTalk = ({ socketRef, usertype, ...props }) => {
    const [modal, setModal] = useState({ open: false });
    const [instructors, users, socket,
        callRequest, isOutgoingCall,
        forceRerender, socketInitiateCall
    ] = useMicroTalkWrapper({
        socketRef, usertype,
        onPreConfirmCallAction: incomingCall,
        onPostConfirmCallAction: stopRingtone,
        onSocketIncomingCallPlug: onSocketIncomingCallPlug,
        ...props
    });

    const ringtoneSound = useRef();
    useEffect(() => {
        if (history.action === "PUSH") {
            ringtoneSound.current = new Howl({
                src: [ringtone],
                loop: true,
                preload: true
            });
        }
    }, [])
    function incomingCall() {
        if (ringtoneSound.current) {
            ringtoneSound.current.play()
        }
    }

    function stopRingtone() {
        if (ringtoneSound.current) {
            ringtoneSound.current.stop();
        }
    }

    function handleHangup() {
        callRequest.current = {}
        isOutgoingCall.current = false;
        forceRerender();
    }

    function availabilityToColor(available) {
        return available ? "green" : "red";
    }

    function closeModal() {
        setModal({ ...modal, open: false });
    }
    function onSocketIncomingCallPlug(user, usertype, callback) {
        setModal({
            open: true,
            handleClose: () => { closeModal(); callback(false); },
            handleOk: () => { closeModal(); callback(true); },
            content: `Do you want to accept call from ${user.email}(${usertype}) ? `
        })
    }

    return <>
        {modal.open && <Modal isopen={modal.open}
            handleClose={modal.handleClose}
            handleCancel={modal.handleClose}
            handleOk={modal.handleOk}
            modalTitle="Incoming call"
            modalBody={modal.content}
        />}
        <AvailableUsersCollapsible title={"MicroTalk"}>
            <div className="d-flex flex-column">
                {usertype === ROLES.instructor && users.current.length > 0 &&
                    <>
                        <p>Users</p>
                        {users.current.map(
                            (user, i) => <Button key={`user - list - item - ${i}`} isLink onClick={(e) => socketInitiateCall(user)}>
                                <StatusIcon statusColor={availabilityToColor(user.available)} /> {user.email}
                            </Button>)
                        }
                    </>
                }
                {instructors.current.length > 0 &&
                    <>
                        <p>Instructors</p>
                        {instructors.current.map(
                            (user, i) => <Button key={`instructor - list - item - ${i}`} isLink onClick={(e) => socketInitiateCall(user)}>
                                <StatusIcon statusColor={availabilityToColor(user.available)} /> {user.email}
                            </Button>)
                        }
                    </>
                }
            </div>
        </AvailableUsersCollapsible>

        <div style={styles.chatarea}>
            {!ringtoneSound.current &&
                <Button onClick={(e) => {
                    e.preventDefault();
                    ringtoneSound.current = new Howl({
                        src: [ringtone],
                        loop: true,
                        preload: true
                    });
                    forceRerender();
                }} >Allow MicroTalk to notify me about a call</Button>
            }
            <MicroTalkConnect
                socketRef={socket.current}
                usertype={"usertype"}
                callRequest={callRequest.current}
                isOutgoingCall={isOutgoingCall.current}
                onHangUp={handleHangup} />
        </div>

    </>
}

function mapState(state) {
    return {};
}

const actionCreators = {
    getMAClientToken: userActions.getMAClientToken,
    successAlert: alertActions.success,
    errorAlert: alertActions.error,

};

const connectedMicroTalk = connect(mapState, actionCreators)(MicroTalk);
export { connectedMicroTalk as MicroTalk };
