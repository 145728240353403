import React from 'react';
import { Redirect } from 'react-router-dom';
import config from '_configs';
import { CustomRoute } from "_components";

export const PrivateRoute = ({ component: Component, allowedRoles = [], ...rest }) => (
    <CustomRoute {...rest} render={props => {
        const user = localStorage.getItem('microassistant_user');

        if (user && allowedRoles.length === 0) {
            // any loggedin user is allowed
            return <Component {...props} />;
        } else if (user && allowedRoles.length > 0 && JSON.parse(user).roles.some(userRole => allowedRoles.includes(userRole))) {
            // user with any of the rolles matching is allowed
            return <Component {...props} />;
        }

        return <Redirect to={{ pathname: config.clientUrls.LOGIN, state: { from: props.location } }} />;
    }} />
)