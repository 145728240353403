import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { moduleActions } from "_actions";
import { Modal } from "_components/Base";

const DeleteBlockModal = ({ open, handleClose, handleAction, currentModule, misc = {}, ...props }) => {
    const [conflicts, setConflicts] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const data = await props.verifyBlockDelete(currentModule.id, misc.id)
                setConflicts(data.conflicts)
            } catch (err) {
                console.log(err)
                handleClose();
            }
        }
        fetchData();
        return () => { }
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await props.deleteBlock(currentModule.id, misc.id);
            handleAction(misc.id, misc.level);
        } catch (err) {
            console.log(err)
        }
        handleClose(e);
    }
    let modalBody = <></>;
    if (conflicts && conflicts.length > 0) {
        modalBody = <>
            This action will delete this block with all its children and resources.
            It will also affect {conflicts.length} configuration{conflicts.length > 1 ? "s" : ""} ({conflicts.map(o => o.config_name).join(", ")}).
            Are you sure you want to continue?
        </>;
    } else {
        modalBody = <>Are you sure you want to delete this block with all its children and resources (if it originally belongs to current module)?</>;
    }
    return <Modal isopen={open}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleOk={handleSubmit}
        modalTitle="Delete Block"
        modalBody={modalBody}
    />
}

function mapState(state) {
    return {};
}
const actionCreators = {
    deleteBlock: moduleActions.deleteBlock,
    verifyBlockDelete: moduleActions.verifyBlockDelete,

};

const connectedDeleteBlockModal = connect(mapState, actionCreators)(DeleteBlockModal);
export { connectedDeleteBlockModal as DeleteBlockModal };
