import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { Route } from 'react-router-dom';
import { userActions } from "_actions";
import { NavBar } from "_components";

const CustomRoute = ({ variant = { navbar: true, sidebar: false }, component: Component, render, ...rest }) => {
    useEffect(() => {
        async function fetchData() {
            try {
                // await props.getProfile();
            } catch (err) { }
        }
        if (rest.loggedIn) {
            // TODO Discuss
            fetchData();
        }
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    let renderFunc = props => <>
        {variant.navbar && <NavBar />}
        {render ? render(props) : <Component {...props} />}
    </>
    return <Route {...rest} render={renderFunc} />
}

function mapState(state) {
    const { loggedIn, user } = state.authentication;
    return { loggedIn, user };
}

const actionCreators = {
    getProfile: userActions.getProfile
};

const connectedCustomRoute = connect(
    mapState,
    actionCreators
)(CustomRoute);
export { connectedCustomRoute as CustomRoute };
