import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { userCL3Actions, userActions, alertActions } from "_actions";
import { Form, Button } from "react-bootstrap";


/**
 * Login device with organization credentials to get access to CL3 functionality (client/session manager)
 */

const OrganizationLogin = (props) => {
    const versions = ["client", "manager"];
    const [credentials, setCredentials] = useState({ email: "", password: "", room: "", version: versions[0] });
    useEffect(() => {
        const fetchData = async () => {
            console.log("Organization LOGOUT!!!!");
            props.clearAlert();
            await props.organizationLogout();
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        props.clearAlert();
        setCredentials({ ...credentials, [name]: value })
    }

    async function handleChangeOption(e) {
        e.preventDefault();
        const { selectedOptions } = e.target;
        setCredentials({ ...credentials, version: Object.values(selectedOptions).map(o => o.value)[0] });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await props.organizationLogin(
                credentials.email,
                credentials.password,
                credentials.room,
                credentials.version
            );
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <h3>Organization Login</h3>
            <p className="small">
                Use provided organization user credentials to login particular device to perform Session Manager or Client role.
                Generally, there will be a single session manager that will control (remote auth) access to Client devices.
            </p>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="form.Email">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control name="email" type="email" placeholder="Enter email" value={credentials.email} onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="form.Password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control name="password" type="password" placeholder="Password" value={credentials.password} onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="form.Room">
                    <Form.Label>Room</Form.Label>
                    <Form.Control name="room" type="text" placeholder="Room name" value={credentials.room} onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="form.Version">
                    <Form.Label>Version</Form.Label>
                    <Form.Control as="select" rows={versions.length} name="version" onChange={handleChangeOption} defaultValue={versions[0]}>
                        {versions.map((version, indx) => <option key={`options_${indx}`} name={version} value={version} >{version}</option>)}
                    </Form.Control>
                </Form.Group>

                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Login
            </Button>
            </Form>
        </>
    )
}


function mapState(state) {
    return {};
}

const actionCreators = {
    organizationLogin: userCL3Actions.organizationLogin,
    organizationLogout: userCL3Actions.organizationLogout,
    logout: userActions.logout,
    errorAlert: alertActions.error,
    clearAlert: alertActions.clearError,
    successAlert: alertActions.success,
};

const connectedOrganizationLogin = connect(mapState, actionCreators)(OrganizationLogin);
export { connectedOrganizationLogin as OrganizationLogin };
