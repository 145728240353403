import React from "react";
import { connect } from "react-redux";
import { moduleActions } from "_actions";
import { Modal } from "_components/Base";

const DeleteModuleModal = ({ open, handleClose, handleAction, currentModule, misc = {}, ...props }) => {

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await props.deleteModule(currentModule.id);
            handleAction(currentModule.id);
        } catch (err) {
            console.log(err)
        }
        handleClose(e);
    }
    let modalBody = <>
        Are you sure you want to do delete this instruction with all the blocks and resources that originally belong to this instruction?
    </>;

    return <Modal isopen={open}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleOk={handleSubmit}
        modalTitle="Delete Module"
        modalBody={modalBody}
    />
}

function mapState(state) {
    return {};
}
const actionCreators = {
    deleteModule: moduleActions.deleteModule,
};

const connectedDeleteModuleModal = connect(mapState, actionCreators)(DeleteModuleModal);
export { connectedDeleteModuleModal as DeleteModuleModal };
