import React from "react";
import { Modal as ModalBS, Button, ButtonGroup } from "react-bootstrap";

export const Modal = ({
	isopen,
	modalTitle,
	modalBody,
	handleOk,
	handleCancel,
	handleClose,
	...rest
}) => {
	return (
		<ModalBS show={isopen} onHide={handleClose} size="lg" {...rest}>
			<ModalBS.Header closeButton>
				<ModalBS.Title>{modalTitle}</ModalBS.Title>
			</ModalBS.Header>
			<ModalBS.Body>{modalBody}</ModalBS.Body>
			{(handleOk || handleCancel) && (
				<ModalBS.Footer>
					<ButtonGroup>
						{handleOk && (
							<Button variant="outline-dark" onClick={handleOk}>
								Ok
							</Button>
						)}
						{handleCancel && (
							<Button variant="outline-secondary" onClick={handleCancel}>
								Cancel
							</Button>
						)}
					</ButtonGroup>
				</ModalBS.Footer>
			)}
		</ModalBS>
	);
};
