import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import lodashClonedeep from "lodash.clonedeep";
import { moduleActions } from "_actions";
import { getObjectValueByMultilevelKey } from "_api";
import { Modal } from "_components/Base";
import { TreeView } from "_components";
import { TreeNodeAI } from "./TreeNode";
import { sortByStringAttrFactory } from "_helpers";

const index_correction = (indx) => indx - 1; // server,db (1) -> array (0)
const inverted_index_correction = (indx) => indx + 1; // array (0) -> server,db (1) 
const data_reducer_no_correction = (acc, key) => {
    return acc.children ? acc.children[key] : acc[key]
};
const data_reducer = (acc, key) => {
    return data_reducer_no_correction(acc, index_correction(key));
};

// works in place
const update_children_level = (parentObj, sep = ",") => {
    if (!parentObj.children || parentObj.children.length < 1)
        return;
    const parentLevel = parentObj.level;
    parentObj.children.forEach((child, indx) => {
        child.level = [parentLevel, inverted_index_correction(indx)].join(sep)
        update_children_level(child);
    })
}
// works in place
const drop_leaves = (obj) => {
    if (obj.is_leaf) return; // just in case
    obj.children = obj.children.filter(child => !child.is_leaf)
    obj.children.forEach(child => drop_leaves(child))
}

// works in place
const drop_selected_fully = (obj) => {
    if (obj.selectedFully) delete obj["selectedFully"];
    obj.children.forEach(child => drop_selected_fully(child));
}


const AddExistingBlockModal = ({ open, handleClose, handleAction, currentModule, misc = {}, ...props }) => {
    // const [modules, setModules] = useState([]);
    const [items, setItems] = useState([])
    const [modulesMeta, setModulesMeta] = useState({});
    const [activeBlockItem, setActiveBlockItem] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await props.listModules();
                const _modules = res.modules
                    .filter(module => module.id !== currentModule.id)
                    .sort(sortByStringAttrFactory("name"));
                setItems(_modules.map((o, i) => {
                    return {
                        id: o.id, children: [],
                        name: o.name,
                        block_id: o.block_id,
                        isInstructionItem: true, // is instruction/module OR block item
                        level: `${inverted_index_correction(i)}`,
                        is_instruction: o.is_instruction // is instruction or module 
                    }
                }
                ))
            } catch (err) {
                console.log(err);
                handleClose()
            }
        }

        fetchData();
        return () => { }
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const modifyChildrenSelected = (obj, selectedFully) => {
        obj.selectedFully = selectedFully;
        obj.children.forEach(o => { modifyChildrenSelected(o, selectedFully) });
    }

    const onNonLeafItemClick = (item) => {
        let _items = lodashClonedeep(items);
        if (activeBlockItem && activeBlockItem.id) {
            // cleaning after previous active block
            let prevActiveObj = getObjectValueByMultilevelKey(_items, activeBlockItem.level, ",", data_reducer);
            drop_selected_fully(prevActiveObj);
        }
        setActiveBlockItem(item);
        let obj = getObjectValueByMultilevelKey(_items, item.level, ",", data_reducer);
        modifyChildrenSelected(obj, true)
        setItems(_items);
    };

    const onExpandClick = (item) => {
        let _items = lodashClonedeep(items);
        let _obj = getObjectValueByMultilevelKey(_items, item.level, ",", data_reducer);
        _obj.open = !_obj.open;
        setItems(_items)
    }

    const onModuleExpandClick = async (item) => {
        const res = await props.getBlockHierarchy(item.id);
        const newItems = items.map(o => {
            if (o.id !== item.id) return o;
            const _obj = res.hierarchy[0];
            _obj.open = !_obj.open;
            _obj.level = o.level;
            _obj.is_instruction = item.is_instruction; // check if it is module or instruction
            update_children_level(_obj)
            drop_leaves(_obj);
            if (o.selectedFully) {
                setActiveBlockItem(_obj);
                modifyChildrenSelected(_obj, true);
            }
            return _obj;
        })
        const meta = ({
            id: item.id,
            name: "module.name", //just in case
            cloned_from_module_ids: res.cloned_from_module_ids,
            block_feedbacks_init: res.block_feedbacks,
            block_feedbacks: res.block_feedbacks.reduce((acc, cur) => {
                const { block_id, ...curRest } = cur;
                if (!acc[block_id]) acc[block_id] = []
                acc[block_id].push(curRest)
                return acc;
            }, {})
        })
        setItems(newItems);
        setModulesMeta({ ...modulesMeta, [item.id]: meta });
    }

    const getAddedModuleBlock = () => {
        let added_module_id, added_block_id;
        if (activeBlockItem.isInstructionItem) {
            added_module_id = activeBlockItem.id;
            added_block_id = activeBlockItem.block_id;
        } else {
            added_block_id = activeBlockItem.id;
            added_module_id = activeBlockItem.module_id;
        }
        return [added_module_id, added_block_id]
    }

    const handleVerifiedSubmit = async () => {
        const [added_module_id, added_block_id] = getAddedModuleBlock();
        let res = await props.addExistingBlock(currentModule.id, misc.parentBlockId, added_module_id, added_block_id);
        handleAction(res.hierarchy, res.cloned_from_module_ids, added_module_id, misc.parentBlockLevel);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!activeBlockItem || !activeBlockItem.id) {
                window.alert("Please select a block to add");
                return;
            }

            const added_block_id = getAddedModuleBlock()[1]; // returns [added_module_id, added_block_id]
            // check if there are modules/instructions that use selected block
            const { conflicts } = await props.verifyAddExistingBlock(currentModule.id, misc.parentBlockId, added_block_id)
            if (conflicts && conflicts.length > 0) {
                const conflictModuleNamesStr = conflicts.map(o => o.name).join(', ');
                if (!window.confirm(
                    `Parental block appears in ${conflicts.length} module${conflicts.length > 1 ? 's' : ''} (${conflictModuleNamesStr}).\n` +
                    `New structure will be added to all of them automatically.\n` +
                    'Do you want to continue?')
                ) {
                    return;
                }
            }
            await handleVerifiedSubmit()

        } catch (err) {
            console.log(err)
        }
        handleClose(e);
    }
    const modalBody = <TreeView
        items={items} node={TreeNodeAI}
        onExpandClick={onExpandClick}
        onModuleExpandClick={onModuleExpandClick}
        onNonLeafItemClick={onNonLeafItemClick}
        commands={null} top={true}
    />
    return <Modal isopen={open}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleOk={handleSubmit}
        modalTitle="Add Existing Block with its children"
        modalBody={modalBody}
    />
}

function mapState(state) {
    return {};
}
const actionCreators = {
    getBlockHierarchy: moduleActions.getBlockHierarchy,
    addExistingBlock: moduleActions.addExistingBlock,
    verifyAddExistingBlock: moduleActions.verifyAddExistingBlock,
    listModules: moduleActions.listModules,
};

const connectedAddExistingBlockModal = connect(mapState, actionCreators)(AddExistingBlockModal);
export { connectedAddExistingBlockModal as AddExistingBlockModal };
