import React, { useState } from "react";
import {
    PlusIcon, DashIcon, KebabHorizontalIcon,
    ShareAndroidIcon, PersonIcon
} from "@primer/octicons-react";
import { Button, Dropdown } from "_components/Base";

import { Menu, Item, Separator, useContextMenu } from 'react-contexify';
import moment from "moment";
import { sortByStringAttrFactory } from "_helpers";

const dummyFunc = () => { }

export const InstructionTreeView = ({ instructions, ...rest }) => {
    return <>
        {instructions.map((item) => (<InstructionTreeBranch item={item} key={`instr-branch-${item.id}`} {...rest} />))}
    </>
}

const InstructionTreeBranch = ({ item, onExpandClick, ...rest }) => {
    let classNames = ["treeview-offset", "mt-3"]
    if (rest.className) classNames.push(rest.className);
    return <div className={classNames.join(" ")}>
        <InstructionNode
            key={`instr-node-${item.id}`}
            item={item} open={item.open}
            onExpandClick={() => onExpandClick(item)}
            {...rest}
        />
        {item.open &&
            <UserTreeView
                key={`user-view-${item.id}`}
                users={item.users}
                instruction_id={item.id}
                {...rest}
            />
        }
    </div>
}

const InstructionNode = ({ item, open, className = "", onExpandClick, onItemClick, activeElements, commands = {}, ...rest }) => {
    const contextMenuId = `context-menu-instr-${item.id}`;
    const { show } = useContextMenu({
        id: contextMenuId,
    });
    const [active, setActive] = useState(false);

    const menuItems = [];
    if (commands) {
        menuItems.push(
            { name: "Assign new user", onClick: commands.assignNewUser(item) },
        );
    }
    const enableMenu = menuItems && menuItems.length > 0;

    const preffix_icon = <Button
        btn={false} variant="icon"
        onClick={onExpandClick}
        className="no-borders p-0 px-1"
    >
        {open ? <DashIcon /> : <PlusIcon />}
    </Button>

    const dropdownTitle = <KebabHorizontalIcon className="kebab-vertical" size={16} />

    const handleContextMenu = (e) => {
        e.preventDefault();
        show(e, { props: { key: 'value' } })
    }

    const fieldClassName = [];
    const containerClassNames = [];
    if (className && className.length > 0) containerClassNames.push(className);
    if (activeElements && activeElements.has(item.id)) {
        containerClassNames.push("active")
    }
    if (active) containerClassNames.push("active");
    fieldClassName.push("clickable");

    return <>
        <div className={`full-width tree-node d-flex ${containerClassNames.join(" ")}`}
            onContextMenu={enableMenu ? handleContextMenu : dummyFunc}
        >
            {preffix_icon}
            <div
                className={`d-flex full-width ${fieldClassName.join(" ")}`}
                onClick={() => { onItemClick(item) }}
            >
                <span className="align-middle">
                    <ShareAndroidIcon className="mb-1 mr-1" size="small" />
                    {item.name}
                </span>
            </div>
            <span className="d-flex ml-auto">
                <span>
                    <small>
                        {moment(item.created_at).format('YYYY/MM/DD')}
                        &nbsp;
                        {moment(item.created_at).format('hh:mm')}
                    </small>
                </span>
                {enableMenu &&
                    <Dropdown items={menuItems} title={dropdownTitle} className="noDropdownCaret" style={{ marginLeft: "auto" }} variant="none" />
                }
            </span>
        </div >
        {
            enableMenu &&
            <Menu id={contextMenuId} onShown={(e) => { setActive(true) }} onHidden={(e) => { setActive(false) }}>
                {menuItems.map(({ name, onClick, header, divider, disabled }, indx) => {
                    let _content = null,
                        key = `${contextMenuId}-item-${indx}`;

                    if (header) {
                        _content = <Item key={key} disabled>{header}</Item>
                    } else if (divider) {
                        _content = <Separator key={key} />
                    } else {
                        _content = <Item key={key} onClick={onClick} disabled={disabled}>{name}</Item>
                    }
                    return _content;

                })}
            </Menu>
        }
    </>

}

const UserTreeView = ({ users, ...rest }) => {
    return <>
        {users.sort(sortByStringAttrFactory("email")).map((item) => (<UserTreeBranch item={item} key={`user-branch-${item.id}`} {...rest} />))}
    </>
}

const UserTreeBranch = ({ item, onExpandClick, ...rest }) => {
    let classNames = ["treeview-offset", "mt-1"]
    if (rest.userClassName) classNames.push(rest.userClassName);
    return <div className={classNames.join(" ")}>
        <UserNode
            key={`user-node-${item.id}`}
            item={item} open={item.open}
            onExpandClick={() => onExpandClick(item)}
            {...rest}
        />
    </div>
}

const UserNode = ({ item, className = "", instruction_id, onExpandClick, commands = {}, ...rest }) => {

    const contextMenuId = `context-menu-instr-${instruction_id}-user-${item.id}`;
    const { show } = useContextMenu({
        id: contextMenuId,
    });
    const [active, setActive] = useState(false);

    const menuItems = [];
    if (commands) {
        menuItems.push(
            { name: "Withhold user", onClick: commands.withholdUser(instruction_id, item) },
        );
    }
    const enableMenu = menuItems && menuItems.length > 0;


    const dropdownTitle = <KebabHorizontalIcon className="kebab-vertical" size={16} />

    const handleContextMenu = (e) => {
        e.preventDefault();
        show(e, { props: { key: 'value' } })
    }

    const fieldClassName = [];
    const containerClassNames = [];
    if (className && className.length > 0) containerClassNames.push(className);
    if (active) containerClassNames.push("active");

    return <>
        <div className={`full-width tree-node d-flex ${containerClassNames.join(" ")}`}
            onContextMenu={enableMenu ? handleContextMenu : dummyFunc}
        >
            <div
                className={`d-flex full-width ${fieldClassName.join(" ")}`}
                onClick={() => { }}
            >

                <span className="align-middle">
                    <PersonIcon className="mb-1 mr-1" size="small" />
                    {item.email}
                </span>
            </div>
            <span className="d-flex ml-auto">
                {enableMenu &&
                    <Dropdown items={menuItems} title={dropdownTitle} className="noDropdownCaret" style={{ marginLeft: "auto" }} variant="none" />
                }
            </span>
        </div >
        {
            enableMenu &&
            <Menu id={contextMenuId} onShown={(e) => { setActive(true) }} onHidden={(e) => { setActive(false) }}>
                {menuItems.map(({ name, onClick, header, divider, disabled }, indx) => {
                    let _content = null,
                        key = `${contextMenuId}-item-${indx}`;

                    if (header) {
                        _content = <Item key={key} disabled>{header}</Item>
                    } else if (divider) {
                        _content = <Separator key={key} />
                    } else {
                        _content = <Item key={key} onClick={onClick} disabled={disabled}>{name}</Item>
                    }
                    return _content;

                })}
            </Menu>
        }
    </>
}