import React, { Component, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Agenda } from "./components/AgendaView" 
import Moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "./schedule.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { connect } from "react-redux";
import { resourceActions, alertActions, scheduleActions, organizationActions } from "_actions";
import { Form, Col, Row } from "react-bootstrap";

import Modal from "./components/Modal";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";
import { isPartiallyEmittedExpression, isThisTypeNode } from "typescript";

import { extendMoment } from 'moment-range';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";



const moment = extendMoment(Moment);

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);


class schedule extends Component {
  
  
  constructor(props) {
    super(props);
    this.state = {
      loaded : false,
      startTemp : moment().add(1, "days").toDate(),
      endTemp : moment().add(1, "days").toDate(),
      tempTitle : 'bozo',
      desc: '',
      participants:'',
      user:'',
      location:'here',
      errDel: false,
      errDelMessage: "",
      errSubmit:false, 
      errSubmitMessage: "",
      schedule:{id:-1},
      resourceList:[],
      orgList:[],
      eventsNotReady:[],
      recurring_id:'',
      endDate: new Date(),
      currentResource: 0,
      currentResourceNumber:1,
      currentNumber:0,
      currentMax:1,
      recurring:"false",
      currentOrgUser: 0,
      events: [
      ]
    }
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitDelete = this.handleSubmitDelete.bind(this);
    this.handleRepeatDelete = this.handleRepeatDelete.bind(this);
    this.render = this.render.bind(this);
    this.resourceChange = this.resourceChange.bind(this);
    this.resourceNumberChange = this.resourceNumberChange.bind(this);
    this.userChange = this.userChange.bind(this);
    this.editOpen = this.editOpen.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  //TODO - DONE open modal on this event for when selecting a time


  handleChangeDate(date) {
    console.log(date)
    this.setState({
      endDate: date
    })
  }

  onFormSubmit(e) {
    e.preventDefault();
    console.log(this.state.endDate)
  }
  
  async componentDidMount() {
    console.log(this.props.user);
    const _resources = await this.props.listResources();
    if(this.props.user.roles.includes("manager")){
      try{
        const _users = await this.props.listOrgUsers()
        this.setState({orgList: _users})
      } catch (err) {
        console.log(err)
      }
    }
    if(this.props.user.roles.includes("user")){
      var a = _resources.filter(resource => 
        resource.availability == 'available' || resource.availability.includes(this.props.user.email)
      )
      this.setState({resourceList: a});
    } else {
      this.setState({resourceList: _resources});
    }
    
    
    await this.updateEvents();
    console.log(this.state.events);
  }

  async updateEvents(){

    console.log("in update")
    console.log(this.state.currentResource)
    const _schedule = await this.props.listResourceSchedule(this.state.resourceList[this.state.currentResource].id);
    // @ATTENTION: DISABLIN-G ESLING WARNINGS!
    // eslint-disable-next-line react-hooks/exhaustive-deps
    
    var a = _schedule.filter(item => item.resource_number == this.state.currentResourceNumber)
      .map(item => {
    
        return {start:new Date(item.start), end:new Date(item.end), title:item.user, id:item.id,
          desc:item.desc, participants: item.participants, location: item.location, recurring_id:item.recurring_id}
      
    })

    this.setState({events: a});
    

  }


  handleSelect = ({ start, end }) => {

    this.setState({
      startTemp : start,
      endTemp : end,
    })

  

    this.modalOpen();
    //const title = window.prompt('New Event name')
    //this.modalOpen();
    // if (true)
    //   this.setState({
    //     events: [
    //       ...this.state.events,
    //       {
    //         start,
    //         end,
    //         title,
    //       },
    //     ],
    //   })
  }

  


  onEventResize = (data) => {
    const { start, end } = data;

    this.setState((state) => {
      state.events[0].start = start;
      state.events[0].end = end;
      return { events: [...state.events] };
    });
  };

  onEventDrop = (data) => {
    console.log(data);
  };

  //Modal Section

  //TODO - CHANGE INFO TO ADD A NEW EVENT RATHER THAN THIS NONSENSE
  //Use the commented part of handleSelect to do it, should be pretty easy

  handleChange(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;


    this.setState({
      [name]: value
    });
  }

  async handleEdit(e) {
    e.preventDefault();


    let participants = this.state.participants;
    let desc = this.state.desc;
    let location = this.state.location;
    let recurring_id = this.state.recurring_id;
    let user = "";


    if(this.props.user.roles.includes("manager")){
      user = this.state.orgList[this.state.currentOrgUser].email
    } else {
      user = this.props.user.email
    }

    let title = user;

    if(this.state.participants.split(',').length > this.state.resourceList[this.state.currentResource].max_capacity-1){
      this.setState({errSubmit: true, 
        errSubmitMessage: "Partipants exceed max capacity of " + 
        this.state.resourceList[this.state.currentResource].max_capacity})
        return;
    }

    var data = {
      desc: desc,
      participants: participants,
      location: location
    }
     try {
         console.log(data)
         await this.props.updateSchedule(this.state.schedule.id, data);
         for(var i = 0; i < this.state.events.length; i++){
           if(this.state.events[i].id == this.state.schedule.id){
             console.log("WEE WOO IN HERE")
             //https://stackoverflow.com/questions/29537299/react-how-to-update-state-item1-in-state-using-setstate
             // 1. Make a shallow copy of the items
            let cool = [...this.state.events];
            // 2. Make a shallow copy of the item you want to mutate
            let item = {...cool[i]};
            // 3. Replace the property you're intested in
            item.desc = desc;
            item.location = location;
            item.participants = participants;
            item.recurring_id = recurring_id
            // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
            cool[i] = item;
            // 5. Set the state to our new copy
            this.setState({events: cool});


             
           }
         }
     } catch (err) {
         console.log(err);
     } 



    this.editClose();
  }

  modalOpen() {
    this.setState({errSubmit: false, 
      errSubmitMessage: " "})
    this.setState({ modal: true });
  }

  modalClose() {

    this.setState({
      modalInputName: "",
      modal: false,

    })

  }

  editOpen(e) { 
    this.setState({errDel: false, errDelMessage: ""})
    this.setState({errSubmit: false, errSubmitMessage: ""})



    console.log(this.props.user.roles.includes("user"))
    console.log(this.props.user.email == e.title)
    if(this.props.user.roles.includes("user") && this.props.user.email !== e.title){
    } else {
      this.setState({edit: true, schedule: e, participants: e.participants, desc: e.desc, location: e.location})
    }
  }

  editClose() { 
    this.setState({edit: false})
  }


  async handleSubmit(e) {
    e.preventDefault();


    let start = this.state.startTemp;
    let end = this.state.endTemp;
    let participants = this.state.participants;
    let desc = this.state.desc;
    let location = this.state.location;
    let user = "";
    if(this.props.user.roles.includes("manager")){
      user = this.state.orgList[this.state.currentOrgUser].email
    } else {
      user = this.props.user.email
    }

    let title = user;



      var start1 = moment(start)

      if(start1.isBefore(new Date())){
        this.setState({errSubmit: true, 
          errSubmitMessage: "Cannot schedule past events!"})
          return;
      }
      var end1 = moment(end);
      var starta; 
      var enda;
      // CHECK BUFFER TIME
      if(this.props.user.roles.includes("user")){
        var buffer = moment.duration(this.state.resourceList[this.state.currentResource].buffer_time, 'hh:mm')
        starta = start1.subtract(buffer)
        enda = end1.add(buffer)
      } else {
        starta = start1;
        enda = end1;
      }

      var range1 = moment.range(starta,enda)

      var fail = false;
      Object.keys(this.state.events).map((key,el) => {
        var start2 = moment(this.state.events[el].start)
        var end2 = moment(this.state.events[el].end)
        var range2 = moment.range(start2,end2)
        if(range1.overlaps(range2)){
          
          console.log('in here')
          this.setState({errSubmit: true, 
            errSubmitMessage: "No two events can occur simultaneously! Please leave a buffer time of " + this.state.resourceList[this.state.currentResource].buffer_time})
            fail = true;
            return;
        }

      })
      
      if(fail){
        return;
      }


 

    if(this.props.user.roles.includes("user")){
      var date1 = moment(start)
      var date2 = moment(end);
      var diff = date2.diff(date1);
      var max_booking_time = moment.duration(this.state.resourceList[this.state.currentResource].max_booking_time, 'HH:mm')
      console.log(max_booking_time)
      console.log(diff)
      if (diff > max_booking_time._milliseconds){
        this.setState({errSubmit: true, 
          errSubmitMessage: "Scheduling exceeds max booking time of " + 
          this.state.resourceList[this.state.currentResource].max_booking_time})
          return;
      } 
      if(this.state.participants.split(',').length > this.state.resourceList[this.state.currentResource].max_capacity){
        this.setState({errSubmit: true, 
          errSubmitMessage: "Partipants exceed max capacity of " + 
          this.state.resourceList[this.state.currentResource].max_capacity})
          return;
      }
    }

    if(this.state.recurring !== 'false'){

      
        var start1 = moment(start) 
        var lastDay = moment(this.state.endDate) 
        var end1 = moment(end)
        var tmpArr = this.state.events
        var promises = []

        while(start1.isBefore(lastDay)){

          if(start1.day() <= 5){

            var data = {
              start: start1.toDate(),
              end: end1.toDate(),
              location: this.state.location,
              desc: desc,
              participants: participants,
              user: user,
              resource_id: this.state.resourceList[this.state.currentResource].id,
              resource_number:parseInt(this.state.currentResourceNumber),
              recurring_id:this.state.recurring +'_'+ user +'_'+start
            }
        
             try {
                 var a = await this.props.createSchedule(data);
                 promises.push(
                  {
                    start:start1.toDate(),
                    end:end1.toDate(),
                    title,
                    id: a.id,
                    desc,
                    participants,
                    location
                  }
                 )
             } catch (err) {
                 console.log(err);
             }   
            
          }

          var inc;
          if(this.state.recurring == 'daily'){

          var inc = moment.duration(1, "day")
          } else { //weekly
            var inc = moment.duration(7, "day")
          }
            
          start1 = start1.add(inc)

          end1 = end1.add(inc)


        }
        this.setState({
          events:tmpArr.concat(promises)
        })
      } else {

        var data = {
          start: this.state.startTemp,
          end: this.state.endTemp,
          location: this.state.location,
          desc: desc,
          participants: participants,
          user: user,
          resource_id: this.state.resourceList[this.state.currentResource].id,
          resource_number:parseInt(this.state.currentResourceNumber),
          recurring_id:"false"
        }
    
         try {
             var a = await this.props.createSchedule(data);
    
             this.setState({
              events: [
                ...this.state.events,
                {
                  start,
                  end,
                  title,
                  id: a.id,
                  desc,
                  participants,
                  location
                },
              ],
             });
         } catch (err) {
             console.log(err);
         } 
  
      }
    


   


    this.modalClose();
  }



async resourceChange(e) {
  this.setState({currentResource: e.target.value, currentResourceNumber:1}, 
    async () =>{
      await this.updateEvents();
    })
}

async resourceNumberChange(e) {
  this.setState({currentResourceNumber: e.target.value}, 
  async () =>{
    await this.updateEvents();
  })

}

async handleSubmitDelete(e) { 
  e.preventDefault();
  if(this.props.user.roles.includes("user")){
    var date1 = moment(new Date())
    var date2 = moment(this.state.schedule.start);
    var diff = date2.diff(date1);
    console.log(diff);
    console.log(this.state.resourceList[this.state.currentResource].latest_cancel)
    var cancel = moment.duration(this.state.resourceList[this.state.currentResource].latest_cancel, 'hh:mm')
    if (diff < cancel._milliseconds){
      this.setState({errDel: true, errDelMessage: "Cancelling too soon to deadline. Please contact admin."})
    } else {
      try {
        this.setState({errDel: false, errDelMessage: ""})
        await this.props.deleteSchedule(this.state.schedule.id);
        var tempevents = this.state.events.filter(el => el && el.id !== this.state.schedule.id)
        console.log(tempevents)
        this.setState({events: tempevents})
    } catch (err) {
        console.log(err);
    }
    this.editClose();
    }
  } else {
    try {
      console.log("schedule id " + this.state.schedule.id)
      await this.props.deleteSchedule(this.state.schedule.id);
      var tempevents = this.state.events.filter(el => el.id !== this.state.schedule.id)
      this.setState({events: tempevents})
  } catch (err) {
      console.log(err);
  }
  this.editClose();
  }
}


async handleRepeatDelete(e) { 
  e.preventDefault();
  if(this.props.user.roles.includes("user")){
    var date1 = moment(new Date())
    var date2 = moment(this.state.schedule.start);
    var diff = date2.diff(date1);
    console.log(diff);
    console.log(this.state.resourceList[this.state.currentResource].latest_cancel)
    var cancel = moment.duration(this.state.resourceList[this.state.currentResource].latest_cancel, 'hh:mm')
    if (diff < cancel._milliseconds){
      this.setState({errDel: true, errDelMessage: "Cancelling too soon to deadline. Please contact admin."})
    } else {
      try {
        this.setState({errDel: false, errDelMessage: ""})
        await this.props.deleteSchedule(this.state.schedule.id);
        tempevents = tempevents.filter(el => el && el.id !== this.state.schedule.id)
        console.log(tempevents)
        this.setState({events: tempevents})
    } catch (err) {
        console.log(err);
    }
    this.editClose();
    }
  } else {
    this.state.events.filter(e => e.recurring_id === this.state.schedule.recurring_id).map(async (slot) => {
      try {
        await this.props.deleteSchedule(slot.id);
      } catch (err) {
          console.log(err);
      } 
    })

  var tempevents = this.state.events.filter(el => el.recurring_id !== this.state.schedule.recurring_id)
  this.setState({events: tempevents})
  this.editClose();
  }
}


// async handleSubmitDelete(e) { 
//   e.preventDefault();
//   try {

//       await props.deleteSchedule(showDelete.schedule_id);
//   } catch (err) {
//       console.log(err);
//   }
//   //handleCloseDelete(); 
// }

async userChange(e) {
  this.setState({currentOrgUser: e.target.value})
  
}


  //Modal Section End


  render() {
    return (
      
      <div className="schedule">
        <div>

          <Row>
            <Col sm={11}>
            <Form.Control as="select" aria-label="Default select example"
              value = {this.state.currentResource}
            onChange={this.resourceChange}>
            {

             Object.keys(this.state.resourceList).map((key, element) => {
              return (<option key={element} value={element}>
                {element}: {this.state.resourceList[element].name}</option> );
              })
            }
            </Form.Control>
            </Col>
            <Col>
            <Form.Control as="select" aria-label="Default select example"
              value = {this.state.currentResourceNumber}
            onChange={this.resourceNumberChange}>
            {this.state.resourceList.length ? 
            [...Array(this.state.resourceList[this.state.currentResource].max_capacity)].map((x, i) =>
            <option key={i+1} value={parseInt(i+1)}>
              {i+1}
            </option>
            ) : null}
            </Form.Control>
            </Col>
          </Row>
            
        </div>
        <DnDCalendar
          selectable
          defaultDate={moment().toDate()}
          defaultView="month"
          events={this.state.events}
          localizer={localizer}
          onEventDrop={this.onEventDrop}
          onEventResize={this.onEventResize}
          resizable
          views={{
            day: true,
            week: true,
            month: true,
            agenda: Agenda
          }}
          messages={{agenda: "Agenda"}}
          style={{ height: "100vh" }}
          onSelectEvent={event => this.editOpen(event)}
          onSelectSlot={this.handleSelect}
        />




        {/* THIS IS FOR MODAL TO CREATE */}
        <Modal show={this.state.modal} handleClose={e => this.modalClose(e)}>
          <h2>{this.state.resourceList.length ? this.state.resourceList[this.state.currentResource].name : this.state.resourceList.name}</h2>
          <div className="form-group">
            {this.props.user.roles.includes("manager") ? 
            
            (

              <div>
            <Form.Control as="select" aria-label="Default select example"
            value = {this.state.currentOrgUser}
          onChange={this.userChange}>
          {

           Object.keys(this.state.orgList).map((key, element) => {
            return (<option key={element} value={element}>
              {element}: {this.state.orgList[element].email}</option> );
            })
          }
          </Form.Control>
          
          <label>Select user</label>
          </div>
          ) : null}
            <input
              type="text"
              style={{marginTop:'.5rem'}}
              //value={this.state.modalInputName}
              name="participants"
              onChange={e => this.handleChange(e)}
              className="form-control"
            />
            <label>Add participant emails (comma-separated)</label>
            <input
               type="textarea" 
              //value={this.state.modalInputName}
              name="desc"
              onChange={e => this.handleChange(e)}

              className="form-control"
            />
            <label>Description</label>

            <input
               type="textarea" 
              //value={this.state.modalInputName}
              name="location"
              onChange={e => this.handleChange(e)}

              className="form-control"
            />
            <label>Lab Name/Location</label>


            <Form.Group className="mb-3">
          <Form.Label>Does this event repeat? Currently: {this.state.recurring}</Form.Label>
          <div key={`inline-radio`} className="mb-3">
        
      <Form.Check
        inline
        label="False"
        name="group1"
        onChange={(e) => this.setState({recurring: 'false'})}
        type={'radio'}
        id={`inline-radio-1`}
      />
      <Form.Check
        inline
        label="Daily"
        name="group1"
        onChange={(e) => this.setState({recurring: 'daily'})}
        type={'radio'}
        id={`inline-radio-2`}
      />
      <Form.Check
        inline
        label="Weekly"
        name="group1"
        onChange={(e) => this.setState({recurring: 'weekly'})}
        type={'radio'}
        id={`inline-radio-3`}
      />
    </div>



        </Form.Group>

          {(this.state.recurring !== 'false') ?
          <form onSubmit={ this.onFormSubmit }>
          <div className="form-group">
            <DatePicker
                selected={ this.state.endDate }
                onChange={ this.handleChangeDate }
                name="endDate"
                dateFormat="MM/dd/yyyy"
            />
          </div>
        </form>
        : null}
          
            {this.state.errSubmit ? (<div style={{color:'red'}}>{this.state.errSubmitMessage}</div>) : null}

          </div>
          <div className="form-group">
            <button onClick={e => this.handleSubmit(e)} type="button">
              Save
            </button>
          </div>
        </Modal>


        {/* THIS IS MODAL TO UPDATE */}
        <Modal show={this.state.edit} handleClose={e => this.editClose()}>
          <h2>Edit {this.state.schedule.title}'s event</h2>
          <div className="form-group">
            <input
              type="text"
              style={{marginTop:'.5rem'}}
              //value={this.state.modalInputName}
              name="participants"
              onChange={e => this.handleChange(e)}
              value={this.state.participants}
              className="form-control"
            />
            <label>Add participant emails (comma-separated)</label>
            <input
               type="textarea" 
              //value={this.state.modalInputName}
              name="desc"
              onChange={e => this.handleChange(e)}
              value={this.state.desc}
              className="form-control"
            />
            <label>Description</label>

            <input
               type="textarea" 
              //value={this.state.modalInputName}
              name="location"
              onChange={e => this.handleChange(e)}
              value={this.state.location}
              className="form-control"
            />
            <label>Lab Name/Location</label>

            {this.state.errDel ? (<div style={{color:'red'}}>{this.state.errDelMessage}</div>) : null}
            {this.state.errSubmit ? (<div style={{color:'red'}}>{this.state.errSubmitMessage}</div>) : null}

          </div>
          <div className="form-group">
            <button onClick={e => this.handleEdit(e)} type="button">
              Edit
            </button>
          </div>

          <div className="form-group">
            <Row>
              <Col>
            <button onClick={e => this.handleSubmitDelete(e)} type="button">
              Delete Reservation
            </button>
            </Col>
            <Col>
            <button onClick={e => this.handleRepeatDelete(e)} type="button">
              Delete Recurring Reservation
            </button>
            </Col>
            </Row>
          </div>
        </Modal>

      </div>

    );
  }
}

function mapState(state) {
  //const resourceList = state.resourceList;
  const { loggedIn, user } = state.authentication;
  return { loggedIn, user };
}
const actionCreators = {
  listResources: resourceActions.listResources,
  listResourceSchedule: scheduleActions.listResourceSchedule,
  createSchedule: scheduleActions.createSchedule,
  listOrgUsers: organizationActions.listOrgUsers,
  deleteSchedule: scheduleActions.deleteSchedule,
  updateSchedule: scheduleActions.updateSchedule,
  errorAlert: alertActions.error()
};

const connectedSchedule = connect(mapState, actionCreators)(schedule);
export { connectedSchedule as schedule };
