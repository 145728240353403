import React from "react";
import { OverlayTrigger, Tooltip } from "_components/Base";
import { AlertIcon } from "@primer/octicons-react";

export const OverlayedIcon = ({ tooltip, ...rest }) => {
    return <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-3">{tooltip}</Tooltip>}
    >
        <span>
            <AlertIcon className="red-font d-inline-flex" size="small"  {...rest} />
        </span>
    </OverlayTrigger >
}