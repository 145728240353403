import { organizationService } from "_services";
import { alertActions, loadingAPIActions } from "_actions";


export const organizationActions = {
    createOrganization,
    listOrganizations,
    setIsActiveOrganization,
    listOrgUsers
};

function createOrganization(organizationName, email) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return organizationService.createOrganization(organizationName, email).then(
            ({ message }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                return true;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function listOrganizations() {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return organizationService.listOrganizations().then(
            ({ data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                return data.organizations;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function setIsActiveOrganization(id, isActive) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return organizationService.setIsActiveOrganization(id, isActive).then(
            ({ message }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                return true;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function listOrgUsers(org_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return organizationService.listOrgUsers(org_id).then(
            ({ data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                return data.users;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}