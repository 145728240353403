import React, { useState } from 'react';
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";

import { CreateOrgPanel } from "./CreateOrgPanel"

const PageSystemCabinet = (props) => {
    const initPanels = {
        createOrganization: false
    }
    const [activePanels, setActivePanels] = useState(initPanels);

    function handleChangeActivePanel(target) {
        setActivePanels(
            { ...initPanels, [target]: true }
        );
    }
    return <>
        <h3>System Cabinet</h3>

        <Dropdown>
            <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
                Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item onSelect={() => handleChangeActivePanel("createOrganization")}>Create organization</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>

        {activePanels.createOrganization && <CreateOrgPanel />}
    </>
}


function mapState(state) {
    return {};
}
const actionCreators = {};

const connectedPageSystemCabinet = connect(mapState, actionCreators)(PageSystemCabinet);
export { connectedPageSystemCabinet as PageSystemCabinet };
