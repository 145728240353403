import { userCL3Constants } from "_constants";
import { userCL3Service } from "_services";
import { alertActions, loadingAPIActions } from "_actions";
import { history } from "_helpers";

import config from "_configs";

export const userCL3Actions = {
	organizationLogin,
	organizationLogout,
	loginToRoom,
	changeUserRoom,
	remoteLogoutOfRoom
};

function organizationLogin(email, password, room, version) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request({ email }));

		return userCL3Service.organizationLogin(email, password, room, version).then(
			({ data }) => {
				localStorage.setItem("microassistant_user", JSON.stringify(data));
				console.log(localStorage.getItem("microassistant_user"));
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success(data));
				if (version === 'manager') {
					history.push(config.clientUrls.CL3__SESSION_MANAGER_CABINET);
				} else {
					history.push(config.clientUrls.CL3__CLIENT_CABINET);
				}
				return true;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.error(error.error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request(user) {
		return { type: userCL3Constants.ORGANIZATION_LOGIN_REQUEST, user };
	}
	function success(user) {
		return { type: userCL3Constants.ORGANIZATION_LOGIN_SUCCESS, user };
	}
	function failure(error) {
		return { type: userCL3Constants.ORGANIZATION_LOGIN_FAILURE, error };
	}
}

function organizationLogout() {
	return (dispatch) => {
		dispatch({ type: userCL3Constants.ORGANIZATION_LOGOUT });
		console.log("ORGANIZATION LOGOUT")
		userCL3Service.organizationLogout();
		dispatch({ type: userCL3Constants.CL3_CLEAR_ALL });
		return true;
	};
}

function loginToRoom(email, password, room_id) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return userCL3Service.loginToRoom(email, password, room_id).then(
			({ data }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				return data;
			},
			(error) => {
				dispatch(alertActions.error(error.error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function changeUserRoom(source_room_id, target_room_id) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return userCL3Service.changeUserRoom(source_room_id, target_room_id).then(
			({ data }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				return data;
			},
			(error) => {
				dispatch(alertActions.error(error.error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function remoteLogoutOfRoom(user_id, room_id) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return userCL3Service.remoteLogoutOfRoom(user_id, room_id).then(
			({ data }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				return data;
			},
			(error) => {
				dispatch(alertActions.error(error.error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}