import { resourceService } from "_services";
import { alertActions, loadingAPIActions } from "_actions";


export const resourceActions = {
    listResources,
    createResource,
    updateResource,
    deleteResource
};

function listResources() {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return resourceService.listResources().then(
            ({ data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                return data.resources;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function createResource(data) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return resourceService.createResource(data).then(
            ({ data, message }) => {
                dispatch(alertActions.success(message));
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}


function updateResource(res_id, data) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return resourceService.updateResource(res_id, data).then(
            ({ data, message }) => {
                dispatch(alertActions.success(message));
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function deleteResource(res_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return resourceService.deleteResource(res_id).then(
            ({ message, data }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data={"module_id": module_id}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}