import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { userActions, alertActions } from "_actions";
import { Form, Button } from "react-bootstrap";

const ForgotPassowrd = (props) => {
    const [email, setEmail] = useState("");
    useEffect(() => {
        props.clearAlert();
        props.logout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (e) => {
        e.preventDefault();
        const { value } = e.target;
        props.clearAlert();
        setEmail(value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            props.forgot(email);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <h3>Please enter your email. If account exists, we will send email with instructions.</h3>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicPassword1">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control name="email" type="email" placeholder="Email" value={email} onChange={handleChange} />
                </Form.Group>

                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Forgot
            </Button>
            </Form>
        </>
    )
}


function mapState(state) {
    return {};
}

const actionCreators = {
    logout: userActions.logout,
    forgot: userActions.forgot,
    errorAlert: alertActions.error,
    clearAlert: alertActions.clearError,
    successAlert: alertActions.success,
    confirmAlert: alertActions.confirm,
};

const connectedForgotPassowrd = connect(mapState, actionCreators)(ForgotPassowrd);
export { connectedForgotPassowrd as ForgotPassowrd };
