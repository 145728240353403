import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { alertActions, instructionActions, moduleActions } from "_actions";
import config from '_configs';
import { history, joinObjects, isNone, sortByStringAttrFactory } from "_helpers";
import { useContainerDimensions } from "_hooks/useResize";
import { Button } from "_components/Base";
import { TreeView, TreeNode } from "_components";
import { GearIcon, HomeIcon } from '@primer/octicons-react';

import { PreviewResourcePanel } from "./PreviewResourcePanel";
import { InstructionTreeView } from "./InstructionTreeView";
import { AssignNewUserModal } from "./AssignNewUserModal";

import { getObjectValueByMultilevelKey } from "_api";

import lodashClonedeep from "lodash.clonedeep";

const styles = {
    container: {
    },
    leftPanel: {
        minWidth: "200px",
        width: "40%",
    },
    middlePanel: {
        minWidth: "200px",
        width: "30%",
    },
    titleText: {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        paddingLeft: "1rem"
    },
    bordered: {
        border: "2px solid",
    },
    rightPanel: {
        minWidth: "200px",
        width: "30%",
    }
}

// custom function for destructure item.level and getting object from items
// ATTENTION: in get_block_hierarchy everything starts from 1 (due to the ordering)
const index_correction = (indx) => indx - 1; // server,db (1) -> array (0)
// const inverted_index_correction = (indx) => indx + 1; // array (0) -> server,db (1) 
const data_reducer_no_correction = (acc, key) => {
    return acc.children ? acc.children[key] : acc[key]
};
const data_reducer = (acc, key) => {
    return data_reducer_no_correction(acc, index_correction(key));
};

// works in place
const open_block_hier = (obj) => {
    obj.open = true;
    if (!obj.children || obj.children.length < 1)
        return;
    obj.children.forEach(child => open_block_hier(child))
}


const InstructionsPage = (props) => {
    const rightPanelRef = useRef();
    const [activeBlockItem, setActiveBlockItem] = useState({});
    const [activeInstruction, setActiveInstruction] = useState({});
    const { width, height } = useContainerDimensions(rightPanelRef);
    const [instructions, setInstructions] = useState([]);
    const [items, setItems] = useState([]);
    const [modal, setModal] = useState({ open: false, type: "", misc: {}, currentModule: {} })
    useEffect(() => {
        async function fetchData() {
            try {
                const _instructions = await props.listOrgInstructions();
                setInstructions(_instructions.sort(sortByStringAttrFactory("name")));
            } catch (err) {
                console.log(err);
            }
        }
        fetchData();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderModalContent = () => {
        let _modal = <></>
        let _rest = {
            open: modal.open,
            currentModule: modal.currentModule,
            handleClose: () => setModal({ ...modal, open: false }),
            misc: modal.misc
        }
        switch (modal.type) {
            case "assign-new-user":
                _modal = <AssignNewUserModal
                    handleAction={async (instruction, selectedUsers) => {
                        const _users = await getAssignemdUsers(instruction);
                        if (!selectedUsers || selectedUsers.length < 1) return;
                        const newInstructions = instructions.map(o => {
                            if (instruction.id !== o.id) return o;
                            let newUsers;
                            if (_users.length > 0) {
                                newUsers = _users
                            } else {
                                newUsers = [...(o.users || []), ...selectedUsers]
                            }
                            const _obj = { ...o, users: newUsers, open: true }
                            return _obj
                        });
                        setInstructions(newInstructions);
                    }}
                    {..._rest} />
                break;
            default:
                break;
        }
        return _modal;
    }

    const modalBehaviorTrigger = (type, module, misc = {}) => {
        return (e) => {
            const open = !(modal.open && modal.type === type);
            setModal({ ...modal, type, open, misc, currentModule: module });
        }
    }

    const menuCommands = {
        assignNewUser: (instr) => modalBehaviorTrigger("assign-new-user", instr, { name: instr.name }),
        withholdUser: (instruction_id, user) => async () => {
            if (!window.confirm(`Are you sure you want to remove user ${user.email} from the instruction`))
                return;
            try {
                await props.withholdInstructionFromUser(instruction_id, user.id)
                const newInstructions = instructions.map(o => {
                    if (instruction_id !== o.id) return o;
                    const _users = o.users.filter(u => u.id !== user.id)
                    const _obj = { ...o, users: _users }
                    return _obj
                });
                setInstructions(newInstructions);
            } catch (err) {
                console.log(err);
            }
        }
    }

    const getAssignemdUsers = async (instr) => {
        let _users = [];
        if (isNone(instr.users)) {
            _users = await props.listAssignedUsers(instr.id);
        }
        return _users;
    }

    const onExpandInstructionClick = async (instr) => {
        const _users = await getAssignemdUsers(instr);
        const newInstructions = instructions.map(o => {
            if (instr.id !== o.id) return o;
            const _obj = { ...o, open: !o.open }
            if (_users.length > 0) _obj.users = _users || []
            else _obj.users = []
            return _obj
        });
        setInstructions(newInstructions);
    }

    const onInstructionClick = async (instr) => {
        setActiveInstruction(instr);
        const { hierarchy } = await props.getBlockHierarchy(instr.id);
        open_block_hier(hierarchy[0])
        setItems(hierarchy);
    }

    const onLeafItemClick = (item) => setActiveBlockItem(item);

    const onExpandBlockClick = (item) => {
        let _items = lodashClonedeep(items);
        let _obj = getObjectValueByMultilevelKey(_items, item.level, ",", data_reducer);
        _obj.open = !_obj.open;
        setItems(_items)
    }

    return <>
        {modal.open && renderModalContent()}
        <div className="d-flex justify-content-start px-4 pt-4" style={{ flex: "0 1 auto" }}>
            <div style={styles.leftPanel} className="mr-5" >
                <div className="d-flex justify-content-start" >
                    <Button variant="outline-secondary" className="mr-2 btn-lg" onClick={() => { }}><GearIcon /> </Button>
                    <Button variant="outline-secondary" className="btn-lg" onClick={() => { history.push(config.clientUrls.HOME) }}><HomeIcon /></Button>
                </div>
            </div>
        </div>
        <div className="d-flex justify-content-start px-4 pt-2" style={{ height: "calc(100vh - 150px)" }}>
            <div style={joinObjects(styles.bordered, styles.leftPanel)} className="mr-2 overflow-auto">
                <p style={styles.titleText}>Instruction list</p>
                <InstructionTreeView
                    instructions={instructions}
                    onExpandClick={onExpandInstructionClick}
                    onItemClick={onInstructionClick}
                    commands={menuCommands}
                    activeElements={new Set([activeInstruction.id])}
                />
            </div>

            <div style={joinObjects(styles.bordered, styles.middlePanel)} className="mr-2 overflow-auto">
                <p style={styles.titleText}>Content</p>
                <TreeView
                    items={items} node={TreeNode}
                    onExpandClick={onExpandBlockClick}
                    onLeafItemClick={onLeafItemClick}
                    top={true}
                    activeElements={new Set([activeBlockItem.id])}
                />
            </div>

            <div style={joinObjects(styles.bordered, styles.rightPanel)} ref={rightPanelRef}>
                <p style={styles.titleText}>Preview window</p>
                {activeBlockItem.id &&
                    (
                        <>
                            <PreviewResourcePanel item={activeBlockItem}
                                width={width - 20} height={height - 100} />
                        </>
                    )
                }
            </div>
        </div>
    </ >
}



function mapState(state) {
    return {};
}
const actionCreators = {
    getBlockHierarchy: moduleActions.getBlockHierarchy,
    listModules: moduleActions.listModules,
    swapBlocks: moduleActions.swapBlocks,
    listOrgInstructions: instructionActions.listOrgInstructions,
    listAssignedUsers: instructionActions.listAssignedUsers,
    withholdInstructionFromUser: instructionActions.withholdInstructionFromUser,
    errorAlert: alertActions.error,
};

const connectedInstructionsPage = connect(mapState, actionCreators)(InstructionsPage);
export { connectedInstructionsPage as InstructionsPage };
