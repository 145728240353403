import { mean, gmean } from "./math";

export const autoCorrelate = (buf, sampleRate) => {
    // Implements the ACF2+ algorithm
    let SIZE = buf.length;
    let rms = 0;

    for (let i = 0; i < SIZE; i++) {
        let val = buf[i];
        rms += val * val;
    }
    rms = Math.sqrt(rms / SIZE);
    if (rms < 0.01) {
        // console.log(`not enough signal ${rms}`)
        return -1;
    }

    let r1 = 0, r2 = SIZE - 1, thres = 0.2;
    for (let i = 0; i < SIZE / 2; i++)
        if (Math.abs(buf[i]) < thres) { r1 = i; break; }
    for (let i = 1; i < SIZE / 2; i++)
        if (Math.abs(buf[SIZE - i]) < thres) { r2 = SIZE - i; break; }

    buf = buf.slice(r1, r2);
    SIZE = buf.length;

    let c = new Array(SIZE).fill(0);
    for (let i = 0; i < SIZE; i++)
        for (let j = 0; j < SIZE - i; j++)
            c[i] = c[i] + buf[j] * buf[j + i];

    let d = 0;
    while (c[d] > c[d + 1]) d++;
    let maxval = -1, maxpos = -1;
    for (let i = d; i < SIZE; i++) {
        if (c[i] > maxval) {
            maxval = c[i];
            maxpos = i;
        }
    }
    let T0 = maxpos;

    const x1 = c[T0 - 1], x2 = c[T0], x3 = c[T0 + 1];
    const a = (x1 + x3 - 2 * x2) / 2;
    const b = (x3 - x1) / 2;
    if (a) T0 = T0 - b / (2 * a);
    return sampleRate / T0;
}

export const noteFromPitch = (frequency) => {
    const noteNum = 12 * (Math.log(frequency / 440) / Math.log(2));
    return Math.round(noteNum) + 69;
}

const __frequencyFromNoteNumber = (note) => {
    return 440 * Math.pow(2, (note - 69) / 12);
}

export const centsOffFromPitch = (frequency, note) => {
    return Math.floor(1200 * Math.log(frequency / __frequencyFromNoteNumber(note)) / Math.log(2));
}


export const spectralFlatness = (fft) => {
    const powerSpectrum = fft.map(e => e ** 2);
    const _mean = mean(powerSpectrum);
    if (_mean === 0)
        return 1;
    const res = gmean(powerSpectrum) / _mean;
    if (res && isFinite(res))
        return res;
    return 1
}