import React, { useState, useEffect, useRef } from "react";
import { normalizeTimer, toSeconds } from "_api/time";

export const Timer = ({ hour, minute, second, onTimerEnd, width, height, ...rest }) => {
    const [timeLeft, setTimeLeft] = useState(-1);
    const speech = useRef();
    useEffect(() => {
        try {
            speech.current = new SpeechSynthesisUtterance();
            speech.current.lang = "en-US";
            speech.current.rate = 1;
            speech.current.volume = 1;
            speech.current.pitch = 1;
            window.speechSynthesis.onvoiceschanged = () => {
                speech.current.voice = window
                    .speechSynthesis
                    .getVoices()
                    .filter(o =>
                        o.localService && o.name === 'Alex')[0];
            };
        } catch (err) {
            console.log(err);
        }
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!(hour || minute || second)) return;
        const seconds = toSeconds(hour, minute, second);
        setTimeLeft(seconds);
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hour, minute, second])

    useEffect(() => {
        if (timeLeft < 0) return;
        let timer;
        timer = setInterval(async () => {
            if (timeLeft > 0) {
                setTimeLeft(timeLeft - 1);
            } else {
                clearInterval(timer);
                timer = null;
                if (onTimerEnd) onTimerEnd();
            }
            // spell last 5 seconds and if speech synthesis is supported
            if (timeLeft < 7 && timeLeft > 0 && speech.current) {
                if (timeLeft > 1) {
                    speech.current.text = (timeLeft - 1).toString();
                } else {
                    speech.current.text = "Time is up! Ding dong!";
                }
                window.speechSynthesis.speak(speech.current);
            }

        }, 1000); // update every second

        // Clear timeout if the component is unmounted
        return () => clearInterval(timer);
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeLeft])

    if (timeLeft < 1) {
        return null;
    }
    if (timeLeft < 5) {

        return <div className='d-flex justify-content-center align-items-center'
            style={{ position: "absolute", width: "100vw", height: "100vh", top: "0px", left: "0px" }} >
            <span
                style={{ color: "black", fontSize: `${Math.floor(height * 0.8)}px` }}>{timeLeft}</span>
        </div>
    }
    const times = normalizeTimer(timeLeft);
    const timerContainerStyle = {
        position: "absolute",
        bottom: "0px",
        right: "0px"
    };
    return <div style={timerContainerStyle}>
        {times.hour > 0 && (<>
            <span className="timer">{times.hour}</span>:
        </>)}
        {(times.hour > 0 || times.minute > 0) && (<>
            <span className="timer">{times.minute}</span>:
        </>)}
        <span className="timer">{times.second}</span>
    </div>;
}