import React, { useEffect, useState } from 'react';
import styles from './ResourceManager.module.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ToggleButton from 'react-bootstrap/ToggleButton'
import {Col, Row, Form } from 'react-bootstrap';
import { InputGroup, Table } from 'react-bootstrap';
import { connect } from "react-redux";
import { resourceActions, alertActions } from "_actions";
var a = 5;

// var resourceList = [{"name":"cool", "location":"Lab Storage B","capacity":"5"},
//                     {"name":"hot" , "location": "Lab Storage A","capacity":"4"}]



const ResourceManager = (props) => {
  const [show, setShow] = useState(false); //ok
  const [show1, setShow1] = useState({'show':false,'resource':""});
  const [showDelete, setDelete] = useState({'show':false,'resource':-1});
  var activeResource;
  const [resourceList, setResources] = useState([
    /*
    {"name":"cool", "location":"Lab Storage B","capacity":"5"},
  {"name":"hot" , "location": "Lab Storage A","capacity":"4"}
    */
    ]);

  console.log(props.user)
  const[error, setError] = useState({
    err: false,
    message: ""
  })

  const [formData, setFormData] = useState({
    name: "",
    location:"",
    max_capacity: 0,
    max_booking_time: "00:00",
    buffer_time: "00:00",
    latest_cancel: "00:00",
    availability: "",
    org_id:props.user.organization.id + "",
    is_room:false
  })

  useEffect(() => {
    async function fetchData() {
        const _resources = await props.listResources(props.user.organization.id + "");
        setResources(_resources);
    }
    fetchData();
    // @ATTENTION: DISABLING ESLING WARNINGS!
    // eslint-disable-next-line react-hooks/exhaustive-deps
    })

  const handleClose = () => { 
    setShow(false) 
  }

  const [checked, setChecked] = useState(false);

  const handleShowDelete = (e) => { 
    setDelete({show:true, resource:e}) 
  }

  const handleCloseDelete = (e) => { 
    setDelete({show:false, resource:-1}) 
  }

  async function handleSubmitDelete(e) { 
    e.preventDefault();
    try {

        await props.deleteResource(showDelete.resource);
    } catch (err) {
        console.log(err);
    }
    handleCloseDelete(); 
  }

  const handleClose1 = () => { 
    setShow1({show:false,resource:""}) 
  }
  const handleShow = () => {
    setShow(true);
    setFormData({
      name: "",
      location:"",
      max_capacity: 0,
      max_booking_time: "00:00",
      buffer_time: "00:00",
      latest_cancel: "00:00",
      availability: "",
      org_id:props.user.organization.id + "",
      is_room: false
    });
  }

  const handleShow1 = (e) => {
    setShow1({show:true,resource:e});
    console.log(e)
    setFormData(e)
  };


    
  async function handleSubmit(e) {
    console.log(formData)
    e.preventDefault();
    var noFormat = false;
    var timeFormat =  new RegExp('^([0-9]?[0-9]):[0-5][0-9]$');

    if(!timeFormat.test(formData.buffer_time)){
      noFormat = true;
      if(!error.message.includes("Buffer")){
        setError({
          err: true,
          message: error.message+"Buffer time is not correctly formatted: " + formData.buffer_time + "."
        })
      }
    }
    if(!timeFormat.test(formData.latest_cancel)){
      noFormat = true;
      if( !error.message.includes("cancel")){
        setError({
          err: true,
          message: error.message+"Latest cancel time is not correctly formatted: " + formData.latest_cancel + "."
        })
      }
    }
    if(!timeFormat.test(formData.max_booking_time)){ 
      noFormat = true;
      if(!error.message.includes("booking")){
        setError({
          err: true,
          message: error.message+"Max booking time is not correctly formatted: " + formData.max_booking_time + "."
        })
      }
    }

    if(!noFormat){
      try {
        setError({
          err: false,
          message:""
        })
        await props.createResource(formData);
        handleClose();
      } catch (err) {
          console.log(err);
      }
      }
  }

  async function handleSubmit1(e) {
    var noFormat = false;
    var timeFormat =  new RegExp('^([0-9]?[0-9]):[0-5][0-9]$');

    if(!timeFormat.test(formData.buffer_time)){
      noFormat = true;
      if(!error.message.includes("Buffer")){
        setError({
          err: true,
          message: error.message+"Buffer time is not correctly formatted: " + formData.buffer_time + "."
        })
      }
    }
    if(!timeFormat.test(formData.latest_cancel)){
      noFormat = true;
      if( !error.message.includes("cancel")){
        setError({
          err: true,
          message: error.message+"Latest cancel time is not correctly formatted: " + formData.latest_cancel + "."
        })
      }
    }
    if(!timeFormat.test(formData.max_booking_time)){ 
      noFormat = true;
      if(!error.message.includes("booking")){
        setError({
          err: true,
          message: error.message+"Max booking time is not correctly formatted: " + formData.max_booking_time + "."
        })
      }
    }

    if(!noFormat){
      try {

        formData.org_id = undefined;
        a = formData.id;
        formData.id = undefined;
  
        await props.updateResource(a, formData);
        setError({
          err: false,
          message:""
        })
        handleClose1();
      } catch (err) {
          console.log(err);
      }
      }
  }


  return (
  <div className={styles.ResourceManager}>
   
   <>
      <Button variant="primary" onClick={handleShow}>
        Add a resource
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add a new resource</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Form.Group className="mb-3" >
          <Form.Label>Name of Resource</Form.Label>
          <Form.Control onChange={(e) => setFormData({...formData, name: e.target.value})}  defaultValue={formData.name} placeholder="Enter resource name" />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Location</Form.Label>
          <Form.Control onChange={(e) => setFormData({...formData, location: e.target.value})}  defaultValue={formData.location} placeholder="Enter Location" />
        </Form.Group>

        <Row>
          <Col sm={6}>
          <Form.Group className="mb-3">
          <Form.Label>Maximum Capacity</Form.Label>
          <Form.Control type="number" onChange={(e) => setFormData({...formData, max_capacity: parseInt(e.target.value)})}  defaultValue={formData.max_capacity}  placeholder="Maximum participants"/>
        </Form.Group>
          </Col>
          <Col sm = {6}>
          <Form.Group className="mb-3">

                <ToggleButton
              className="mb-2"
              id="toggle-check"
              type="checkbox"
              variant="outline-primary"
              checked={formData.is_room}
              defaultValue={formData.is_room}
              onChange={(e) =>  setFormData({...formData, is_room: e.currentTarget.checked})}
            >
              Room
            </ToggleButton>
         
        </Form.Group>
          </Col>
         </Row> 


        <Form.Group className="mb-3">
          <Form.Label>Maximum Booking Time</Form.Label>
          <Row>
            <Col sm={6}>
            <InputGroup className="mb-2">
        <Form.Control id="inlineFormInputGroup" onChange={(e) => setFormData({...formData, max_booking_time: e.target.value})} placeholder="Hours" defaultValue = {formData.max_booking_time}/>
        <InputGroup.Text>HH:MM</InputGroup.Text>
        </InputGroup>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Buffer Time Between Bookings</Form.Label>
          <Row>
            <Col sm={6}>
            <InputGroup className="mb-2">
        <Form.Control id="inlineFormInputGroup" onChange={(e) => setFormData({...formData, buffer_time: e.target.value})} 
        placeholder="Hours" defaultValue = {formData.buffer_time}/>
        <InputGroup.Text>HH:MM</InputGroup.Text>
        </InputGroup>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Latest possible cancellation</Form.Label>
          <Row>
            <Col sm={6}>
            <InputGroup className="mb-2">
        <Form.Control id="inlineFormInputGroup" onChange={(e) => setFormData({...formData, latest_cancel: e.target.value})} 
        placeholder="Hours" defaultValue = {formData.latest_cancel}/>
        <InputGroup.Text>HH:MM</InputGroup.Text>
        </InputGroup>
            </Col>
          </Row>
        </Form.Group>


        <Form.Group className="mb-3">
          <Form.Label>Resource Availability</Form.Label>
          <div key={`inline-radio`} className="mb-3">
        
      <Form.Check
        inline
        label="Available"
        name="group1"
        onChange={(e) => setFormData({...formData, availability: 'available'})}
        type={'radio'}
        id={`inline-radio-1`}
      />
      <Form.Check
        inline
        label="Unavailable"
        name="group1"
        onChange={(e) => setFormData({...formData, availability: 'unavailable'})}
        type={'radio'}
        id={`inline-radio-2`}
      />
      <Form.Check
        inline
        label="Hidden"
        name="group1"
        onChange={(e) => setFormData({...formData, availability: 'hidden:'})}
        type={'radio'}
        id={`inline-radio-3`}
      />
    </div>

      {formData.availability.includes("hidden") ? 
      <Form.Group className="mb-3">
      <Form.Label>Hidden Exceptions</Form.Label>
      <Form.Control onChange={(e) => setFormData({...formData, availability: 'hidden:'+e.target.value})}  defaultValue={formData.availability.substr(7)}  placeholder="Hidden exceptions"/>
    </Form.Group>
      : null}



        </Form.Group>

        {error.err ? <p style={{color:'red'}}>{error.message}</p>: null}


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1.show} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Modify resource</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Form.Group className="mb-3" >
          <Form.Label>Name of Resource</Form.Label>
          <Form.Control onChange={(e) => setFormData({...formData, name: e.target.value})}  defaultValue={formData.name} placeholder="Enter resource name" />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Location</Form.Label>
          <Form.Control onChange={(e) => setFormData({...formData, location: e.target.value})}  defaultValue={formData.location} placeholder="Enter Location" />
        </Form.Group>

        <Row>
          <Col sm={6}>
          <Form.Group className="mb-3">
          <Form.Label>Maximum Capacity</Form.Label>
          <Form.Control type="number" onChange={(e) => setFormData({...formData, max_capacity: parseInt(e.target.value)})}  defaultValue={formData.max_capacity}  placeholder="Maximum participants"/>
        </Form.Group>
          </Col>
          <Col sm = {6}>
          <Form.Group className="mb-3">
                <ToggleButton
              className="mb-2"
              id="toggle-check"
              type="checkbox"
              variant="outline-primary"
              checked={formData.is_room}
              defaultValue={formData.is_room}
              onChange={(e) =>  setFormData({...formData, is_room: e.currentTarget.checked})}
            >
              Room
            </ToggleButton>
         
        </Form.Group>
          </Col>
         </Row> 

        <Form.Group className="mb-3">
          <Form.Label>Maximum Booking Time</Form.Label>
          <Row>
            <Col sm={6}>
            <InputGroup className="mb-2">
        <Form.Control id="inlineFormInputGroup" onChange={(e) => setFormData({...formData, max_booking_time: e.target.value})} placeholder="Hours" defaultValue = {formData.max_booking_time}/>
        <InputGroup.Text>HH:MM</InputGroup.Text>
        </InputGroup>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Buffer Time Between Bookings</Form.Label>
          <Row>
            <Col sm={6}>
            <InputGroup className="mb-2">
        <Form.Control id="inlineFormInputGroup" onChange={(e) => setFormData({...formData, buffer_time: e.target.value})} 
        placeholder="Hours" defaultValue = {formData.buffer_time}/>
        <InputGroup.Text>HH:MM</InputGroup.Text>
        </InputGroup>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Latest possible cancellation</Form.Label>
          <Row>
            <Col sm={6}>
            <InputGroup className="mb-2">
        <Form.Control id="inlineFormInputGroup" onChange={(e) => setFormData({...formData, latest_cancel: e.target.value})} 
        placeholder="Hours" defaultValue = {formData.latest_cancel}/>
        <InputGroup.Text>HH:MM</InputGroup.Text>
        </InputGroup>
            </Col>
          </Row>
        </Form.Group>


        <Form.Group className="mb-3">
          <Form.Label>Resource Availability</Form.Label>
          <div key={`inline-radio`} className="mb-3">
        
      <Form.Check
        inline
        label="Available"
        name="group1"
        onChange={(e) => setFormData({...formData, availability: 'available'})}
        type={'radio'}
        id={`inline-radio-1`}
      />
      <Form.Check
        inline
        label="Unavailable"
        name="group1"
        onChange={(e) => setFormData({...formData, availability: 'unavailable'})}
        type={'radio'}
        id={`inline-radio-2`}
      />
      <Form.Check
        inline
        label="Hidden"
        name="group1"
        onChange={(e) => setFormData({...formData, availability: 'hidden:'})}
        type={'radio'}
        id={`inline-radio-3`}
      />
    </div>

      {formData.availability.includes("hidden") ? 
      <Form.Group className="mb-3">
      <Form.Label>Hidden Exceptions</Form.Label>
      <Form.Control onChange={(e) => setFormData({...formData, availability: 'hidden:'+e.target.value})}  defaultValue={formData.availability.substr(7)}  placeholder="Hidden exceptions"/>
    </Form.Group>
      : null}


        Current value: {formData.availability}
        </Form.Group>

        {error.err ? <p style={{color:'red'}}>{error.message}</p>: null}


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit1}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showDelete.show} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete resource</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Are you sure you wish to delete resource {showDelete.resource}? All of its schedule slots will be lost forever. 
        

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Close
          </Button>
          <Button variant="danger" onClick={handleSubmitDelete}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Table striped bordered hover size="sm">
  <thead>
    <tr>
      <th>ID</th>
      <th>Resource</th>
      <th>Location</th>
      <th>Capacity</th>
      <th>View more</th>
      <th>Delete</th>
    </tr>
  </thead>
  <tbody>
    {
    Object.keys(resourceList).map(function (key, element){

      return (<tr key={element}>
      <td>{resourceList[element].id}</td>
      <td>{resourceList[element].name}</td>
      <td>{resourceList[element].location}</td>
      <td>{resourceList[element].max_capacity}</td>
      <td><Button variant="secondary" onClick={()=>handleShow1(resourceList[element])}>
        Edit
      </Button></td>
      <td><Button variant="danger" onClick={()=>setDelete({show:true, resource:resourceList[element].id})}>
        Delete
      </Button></td>
    </tr>);
    })
  }
  </tbody>
</Table>
    </>
  
  </div>);
}


function mapState(state) {
  // const resourceList = state.resourceList;
  const { loggedIn, user } = state.authentication;
  return { loggedIn, user };

  // return {resourceList};
}
const actionCreators = {
  listResources: resourceActions.listResources,
  createResource: resourceActions.createResource,
  updateResource: resourceActions.updateResource,
  deleteResource: resourceActions.deleteResource,
  errorAlert: alertActions.error()
};

const connectedResourceManager = connect(mapState, actionCreators)(ResourceManager);
export { connectedResourceManager as ResourceManager };

