import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { instructionActions } from "_actions";
import { Modal, ListGroup } from "_components/Base";

const AssignNewUserModal = ({ open, handleClose, handleAction, currentModule, misc = {}, ...props }) => {
    const [users, setUsers] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const _users = await props.listApplicableUsers(currentModule.id);
                setUsers(_users
                    .sort((o1, o2) => o1.email.localeCompare(o2.email)));
            } catch (err) {
                console.log(err);
            }
        }
        fetchData();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!users.some(o => o.active)) {
                window.alert("Please select user(s) first.");
                return;
            }
            const selectedUsers = users.filter(o => o.active);
            const promises = selectedUsers.map(user => props.assignInstructionToUser(currentModule.id, user.id));
            await Promise.all(promises)
            handleAction(currentModule, selectedUsers);
        } catch (err) {
            console.log(err)
        }
        handleClose(e);
    }

    const handleUserClick = (indx) => {
        const _users = users.map((mdl, i) =>
            i !== indx ?
                mdl :
                { ...mdl, active: !mdl.active }
        );
        setUsers(_users);
    }

    const modalBody = <ListGroup>
        {users.map((user, indx) => (
            <ListGroup.Item as="li" key={`list-item-${indx}`}
                onClick={(e) => { e.preventDefault(); handleUserClick(indx); }}
                active={user.active}>
                {user.email}
            </ListGroup.Item>))}
    </ListGroup>;
    return <Modal isopen={open}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleOk={handleSubmit}
        modalTitle="Assign New User(s)"
        modalBody={modalBody}
    />
}

function mapState(state) {
    return {};
}
const actionCreators = {
    listApplicableUsers: instructionActions.listApplicableUsers,
    assignInstructionToUser: instructionActions.assignInstructionToUser,
};

const connectedAssignNewUserModal = connect(mapState, actionCreators)(AssignNewUserModal);
export { connectedAssignNewUserModal as AssignNewUserModal };
