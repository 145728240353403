import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { moduleActions } from "_actions";
import { Modal, Form } from "_components/Base";

import "_css/file_uploader.css";

const UpdateBlockModal = ({ open, handleClose, handleAction, currentModule, misc = {}, ...props }) => {
    const [data, setData] = useState({ name: "", links: [], selectedFile: null, selectedAudioFile: null, goal: "", is_important: false });
    const [dropAudio, setDropAudio] = useState(false);
    const [modified, setModified] = useState({ name: false, links: false, selectedFile: false, selectedAudioFile: false, goal: false, is_important: false });

    useEffect(() => {
        setData({ ...data, name: misc.name, links: misc.links, goal: misc.goal, is_important: misc.is_important || false })
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [misc])
    const finalizeUpdate = async (body) => {
        let updateModule = false;
        if (Object.keys(body).length > 0) {
            const res = await props.updateBlock(currentModule.id, misc.id, body);
            if (res.updated) updateModule = res.updated.module;
        }
        if (misc.is_leaf) {
            if (data.selectedFile) {
                const { aspect_ratio } = await props.updateBlockResource(currentModule.id, misc.id, data.selectedFile);
                body.aspect_ratio = aspect_ratio;
            }
            if (data.selectedAudioFile) {
                await props.updateBlockAudioResource(currentModule.id, misc.id, data.selectedAudioFile);
                body.audio_url = true;
            }
            if (body.dropAudio) {
                body.audio_url = false;
            }
        }

        handleAction(body, misc.level, updateModule);
        handleClose();
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const body = {}
            if (modified.name) body.name = data.name;
            if (modified.goal) body.goal = data.goal;
            if (modified.links) body.links = data.links;
            if (modified.is_important) body.is_important = data.is_important;
            if (dropAudio) body.dropAudio = true;
            if (Object.keys(body).length < 1 && !data.selectedFile && !data.selectedAudioFile) {
                window.alert("Please modify any field first")
                return;
            }
            let fields = Object.keys(body);
            if (data.selectedFile) fields.push('resource');
            if (data.selectedAudioFile) fields.push('audio_resource');
            const { conflicts } = await props.verifyBlockUpdate(currentModule.id, misc.id, fields);
            if (conflicts && conflicts.length > 0 &&
                !window.confirm(
                    `These modifications will affect ${conflicts.length} configuration${conflicts.length > 1 ? 's' : ''} (${conflicts.map(o => o.config_name).join(', ')}).\n` +
                    'Assigned users will be able to see the changes immediately (unless they have already requested related segment before).\n' +
                    'Do you want to continue?')
            ) return;
            await finalizeUpdate(body);
        } catch (err) {
            console.log(err)
            handleClose();
        }
    }

    const modalBody = <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control name="name" type="text" value={data.name} onChange={(e) => {
                setData({ ...data, name: e.target.value })
                setModified({ ...modified, name: e.target.value.trim() !== misc.name.trim() });
            }} />
        </Form.Group>

        <Form.Group controlId="formBasicName">
            <Form.Label>Links (separated by new line)</Form.Label>
            <Form.Control name="links" as="textarea" rows={3} value={data.links.join("\n")}
                onChange={(e) => {
                    const links = e.target.value.split("\n");
                    setData({ ...data, links: links })
                    setModified({ ...modified, links: links.filter((val, indx) => Boolean(val.trim())).length !== misc.links.length || links.filter((val, indx) => Boolean(val.trim())).sort().some((val, indx) => misc.links[indx].trim() !== val.trim()) });
                }}
            />
        </Form.Group>
        <Form.Group controlId="formImportantCheckbox">
            <Form.Check type="checkbox" label="Flag for instructor?"
                name="is_important" checked={data.is_important}
                onChange={(e) => {
                    const checked = e.target.checked;
                    setData({ ...data, is_important: checked })

                    setModified({ ...modified, is_important: checked !== misc.is_important });
                }}
            />
        </Form.Group>

        {misc.is_leaf ?
            <>
                <div className="form-group files color">
                    <label>Update resource file</label>
                    <input type="file" className="form-control" multiple=""
                        onChange={(e) => {
                            e.preventDefault();
                            setData({ ...data, selectedFile: e.target.files[0] });
                        }} />
                </div>
                <div className="form-group files color">
                    <label>{misc.audio_url ? 'Update' : 'Add'} Audio resource</label>
                    <input type="file" className="form-control" multiple=""
                        onChange={(e) => {
                            e.preventDefault();
                            setData({ ...data, selectedAudioFile: e.target.files[0] });
                        }} />
                </div>
                {misc.audio_url &&
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Drop audio" onChange={(e) => setDropAudio(e.target.checked)} />
                    </Form.Group>
                }
            </>
            :
            <Form.Group controlId="formBasicGoal">
                <Form.Label>Goal (for blocks with resources will be ignored)</Form.Label>
                <Form.Control name="goal" type="text" value={data.goal} onChange={(e) => {
                    setData({ ...data, goal: e.target.value })
                    setModified({ ...modified, goal: e.target.value.trim() !== (misc.goal || "").trim() });
                }} />
            </Form.Group>
        }

    </Form>;
    return <Modal isopen={open}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleOk={handleSubmit}
        modalTitle="Modify Block"
        modalBody={modalBody}
    />
}

function mapState(state) {
    return {};
}
const actionCreators = {
    verifyBlockUpdate: moduleActions.verifyBlockUpdate, // instructionId, blockId,
    updateBlock: moduleActions.updateBlock, // instructionId, blockId, data
    updateBlockResource: moduleActions.updateBlockResource,
    updateBlockAudioResource: moduleActions.updateBlockAudioResource,
};

const connectedUpdateBlockModal = connect(mapState, actionCreators)(UpdateBlockModal);
export { connectedUpdateBlockModal as UpdateBlockModal };
