export const isNone = (val) => val === undefined || val === null;

export const findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i++) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

export const joinObjects = (...styles) => {
    return Object.assign({}, ...styles);
}

export const sortModulesInstructions = (o1, o2) => {
    if (o1.is_instruction !== o2.is_instruction) {
        return o2.is_instruction - o1.is_instruction
    }
    return o1.name.toLowerCase().localeCompare(o2.name.toLowerCase())
}

export const sortByStringAttrFactory = (attr = "name") =>
    (o1, o2) => {
        return o1[attr].toLowerCase().localeCompare(o2[attr].toLowerCase())
    }