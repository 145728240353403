import React from "react";

import { VCL_DESCRIPTION, VOICE_CONFIG_NAMES } from "_api/voiceCommandLibrary";
import { CONFIG_MAP } from "_api/voice";

export const Help = ({ mode, infoMode, ...props }) => {
    const hidden = ![
        VOICE_CONFIG_NAMES.HELP_HOME,
        VOICE_CONFIG_NAMES.HELP_INSTRUCTION_HOME,
        VOICE_CONFIG_NAMES.HELP_INSTRUCTION_INSTRUCTION,
        VOICE_CONFIG_NAMES.HELP_LOGS,
        VOICE_CONFIG_NAMES.HELP_NOTES,
    ].includes(mode);
    if (hidden) return null;
    const commandPs = [...CONFIG_MAP[infoMode]]
        .map(command => VCL_DESCRIPTION[command])
        .sort((o1, o2) => o1.priority - o2.priority)
        .map((o, indx) => <li key={indx}>{o.example}</li>);
    return <>
        <h4>Available voice commands (Say 'close' to exit)</h4>
        <ul>
            {commandPs}
        </ul>
    </>

}