import React, { useEffect, useRef, useState } from "react";
import moment from "moment";

export const TooltipProgressBar = ({ name, size = 0, logComments, timeranges = [] }) => {
    const [duration, setDuration] = useState()
    const timer = useRef()
    useEffect(() => {
        let dur;
        if (timeranges.length < 1) {
            setDuration(0);
            return;
        }
        for (let i in timeranges) {
            const { from, to } = timeranges[i];
            const _dur = moment.duration({ from, to }).as('milliseconds');
            if (!dur) dur = _dur;
            else dur += _dur
        }
        setDuration(dur);
        const { to } = timeranges[timeranges.length - 1];
        if (!to) {
            if (timer.current) {
                clearInterval(timer.current); // first drop previous timer
                timer.current = null;
            }
            timer.current = setInterval(() => {
                setDuration(duration + 1);
            }, 1000);
            return () => clearInterval(timer.current);
        }
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeranges])
    return <div className="d-flex flex-column text-left">
        <span>Module name: {name}</span>
        <span>Total steps: {size}</span>
        <span>Comments: {logComments.length}</span>
        {duration > 0 &&
            <span>Time passed: {moment.utc(duration).format("mm:ss")}</span>}
    </div>
}
