import config from "_configs";
import { authHeader, handleResponse } from "_helpers";

export const genericService = {
    getVoiceCommandStack,
    createVoiceCommandStack,
    updateVoiceCommandStack,
    swapVoiceCommandStacks
};

function getVoiceCommandStack() {
    // Returns {data: [{onCommandName, search: [{keys, options}], after: {key, options}, example, priority }]}

    const requestOptions = {
        method: "GET",
        headers: authHeader() // could be used by CL3 (org) and admin*
    };

    return fetch(`${config.apiUrl}/generic/command-stack`, requestOptions).then(
        handleResponse
    );
}

function updateVoiceCommandStack(id, data) {
    // Update voice command stack
    // possible update data fields {search, after, example, priority}
    console.log(data);
    const requestOptions = {
        method: "PATCH",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}/generic/command-stack/${id}`, requestOptions).then(
        handleResponse
    );
}

function createVoiceCommandStack(data) {
    // Create voice command stack
    // possible update data fields {onCommandName, search, after, example, priority}
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}/generic/command-stack`, requestOptions).then(
        handleResponse
    );
}

function swapVoiceCommandStacks(id1, id2) {
    // Update voice command stack
    // possible update data fields {search, after, example, priority}
    const requestOptions = {
        method: "PATCH",
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/generic/command-stack/swap/${id1}/${id2}`, requestOptions).then(
        handleResponse
    );
}