/**
 * LOGIC SOURCE: https://github.com/mdn/samples-server/tree/7e79e8fb43b20c59f5d78eb8fc20316db9fb3ac7/s/webrtc-from-chat
 */
import React, { useEffect, useState, useRef } from "react";
import { Container, Button } from "react-bootstrap";
import { connect } from "react-redux";

import "_css/ChatStyles.css";

import camera from '_icons/camera.svg'
import camerastop from '_icons/camera-stop.svg'
import microphone from '_icons/microphone.svg'
import microphonestop from '_icons/microphone-stop.svg'
import share from '_icons/share.svg'
import hangup from '_icons/hang-up.svg'
import fullscreen from '_icons/fullscreen.svg'
import minimize from '_icons/minimize.svg'
import { Howl } from 'howler';
import ringtone from '_sounds/ringtone.mp3'

// const mediaConstraints = {
//     audio: true,            // We want an audio track
//     video: {
//         aspectRatio: {
//             ideal: 1.333333     // 3:2 aspect is preferred
//         }
//     }
// };
const iceServers = {
    iceServers: [
        { urls: 'stun:stun.l.google.com:19302' },
        { urls: 'stun:stun1.l.google.com:19302' },
        { urls: 'stun:stun2.l.google.com:19302' },
        { urls: 'stun:stun3.l.google.com:19302' },
        { urls: 'stun:stun4.l.google.com:19302' },
    ],
}


/**
 * source: https://w3c.github.io/webrtc-pc/#example-18
 * source: https://blog.mozilla.org/webrtc/perfect-negotiation-in-webrtc/
 */

const MicroTalkConnect = ({ socketRef, usertype, callRequest = {}, isOutgoingCall = false, onHangUp, ...props }) => {
    const { callRequestSid, callRequestEmail } = callRequest;
    const [instructorName, setInstructorName] = useState();

    const [hasMic, setHasMic] = useState(true);
    const [hasCam, setHasCam] = useState(true);

    const [stream, setStream] = useState();
    const [audioMuted, setAudioMuted] = useState(true);
    const [videoMuted, setVideoMuted] = useState(true);
    const [isfullscreen, setFullscreen] = useState(false);
    const [ringtoneSound, setRingtoneSound] = useState(false);

    const remoteVideoTag = useRef();
    const localVideoTag = useRef();
    const socket = useRef();
    const peerConnection = useRef();
    const isIncoming = useRef();
    const onCallWith = useRef();
    const makingOffer = useRef();
    const ignoreOffer = useRef();
    const isPolite = useRef(); // - the one who is calling is polite
    const isSettingRemoteAnswerPending = useRef();

    const { RTCPeerConnection, RTCSessionDescription } = window;

    function socketSendMessage(data) {
        if (!socket.current) return;
        socket.current.emit("microtalk-message", data)
    }
    function socketForceDisconnect() {
        if (!socket.current) return;
        socket.current.emit("microtalk-force-disconnect")
    }
    function socketChangeAvailability(available = true) {
        if (!socket.current) return;
        socket.current.emit("microtalk-change-availability", { available })
    }

    function closeVideoCall(forceDisconnect = false) {
        if (onCallWith.current) {
            socketSendMessage({ hangup: true, to: onCallWith.current });
            onCallWith.current = null;
        }
        console.log("Closing the call");
        // Close the RTCPeerConnection

        if (peerConnection.current) {
            console.log("--> Closing the peer connection");

            // Disconnect all our event listeners; we don't want stray events
            // to interfere with the hangup while it's ongoing.
            peerConnection.current.ontrack = null;
            peerConnection.current.onnicecandidate = null;
            peerConnection.current.oniceconnectionstatechange = null;
            peerConnection.current.onsignalingstatechange = null;
            peerConnection.current.onicegatheringstatechange = null;
            peerConnection.current.onnotificationneeded = null;

            // Stop all transceivers on the connection
            if (navigator.userAgent && navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
                peerConnection.current.getTransceivers().forEach(transceiver => {
                    transceiver.stop();
                });
            }

            // Stop the webcam preview as well by pausing the <video>
            // element, then stopping each of the getUserMedia() tracks
            // on it.
            if (localVideoTag.current && localVideoTag.current.srcObject) {
                localVideoTag.current.pause();
                localVideoTag.current.srcObject.getTracks().forEach(track => {
                    track.stop();
                });
                localVideoTag.current.srcObject = null;
            }
            if (remoteVideoTag.current && remoteVideoTag.current.srcObject) {
                remoteVideoTag.current.pause();
                remoteVideoTag.current.srcObject = null;
            }
            // Close the peer connection
            peerConnection.current.close();
            peerConnection.current = null;
            setStream(null);
            isPolite.current = false;
        }
        setInstructorName(null);
        isIncoming.current = true;

        // Disable the hangup button @TODO
        // document.getElementById("hangup-button").disabled = true;

        if (forceDisconnect) {
            socketForceDisconnect()
            console.log("FORCE DISCONNECT 222")
        } else {
            socketChangeAvailability(true)
            console.log("Change availability WITH on call")
        }
        onHangUp()
    }

    function peerConnectionOnTrack({ transceiver, streams: [stream] }) {
        console.log("pc.ontrack with transceiver and streams");
        stream.onaddtrack = () => console.log("stream.onaddtrack");
        stream.onremovetrack = () => {
            console.log("stream.onremovetrack");
            if (remoteVideoTag.current) {
                remoteVideoTag.current.srcObject = null;
            }
        }
        stream.onnegotiationneeded = () => {
            console.log("stream.onnegotiationneeded")
        }
        transceiver.receiver.track.onmute = () => {
            console.log("transceiver.receiver.track.onmute");
            if (remoteVideoTag.current) remoteVideoTag.current.srcObject = null;
            if (localVideoTag.current) localVideoTag.current.srcObject = null;
        }
        transceiver.receiver.track.onended = () => {
            console.log("transceiver.receiver.track.onended");
            if (remoteVideoTag.current) remoteVideoTag.current.srcObject = null;
            if (localVideoTag.current) localVideoTag.current.srcObject = null;
        }
        transceiver.receiver.track.onunmute = () => {
            console.log("transceiver.receiver.track.onunmute");
            if (remoteVideoTag.current) {
                remoteVideoTag.current.srcObject = stream;
            }
        };
    }

    function peerConnectionOnConnectionStateChange() {
        switch (peerConnection.current.connectionState) {
            case "connected":
                console.log("The connection has become fully connected");
                break;
            case "disconnected":
            case "failed":
                console.log("One or more transports has terminated unexpectedly or in an error");
                closeVideoCall();
                break;
            case "closed":
                console.log("The connection has been closed");
                closeVideoCall();
                break;
            default:
                break;
        }
    }

    function peerConnectionOnICEConnectionStateChange() {
        console.log("*** ICE connection state changed to " + peerConnection.current.iceConnectionState);
        switch (peerConnection.current.iceConnectionState) {
            case "closed":
            case "failed":
            case "disconnected":
                closeVideoCall();
                break;
            default:
                break;
        }
    }

    async function onNegotiationNeeded() {
        try {
            // if (peerConnection.current.signalingState !== "stable") return;
            makingOffer.current = true;
            // const offer = await peerConnection.current.createOffer();
            console.log("Setting local descr onnegotiationneeded", peerConnection.current.signalingState);
            await peerConnection.current.setLocalDescription();
            // await peerConnection.current.setLocalDescription(offer);
            console.log("RETURN Setting local descr onnegotiationneeded");
            socketSendMessage({ to: onCallWith.current, offer: peerConnection.current.localDescription })
        } catch (err) {
            console.error(err);
        } finally {
            makingOffer.current = false;
        }
    };

    // because it manipulate sockets we need to emit message to another person
    function onIceCandidate({ candidate }) {
        if (candidate) {
            socketSendMessage({
                candidate,
                to: onCallWith.current
            })
        } else {
            console.log("End of ice candidates")
        }
    };

    function initiatePeerConnection() {
        console.log("create new RTCPeerConnection")
        peerConnection.current = new RTCPeerConnection(iceServers);
        isIncoming.current = true;
        peerConnection.current.ontrack = peerConnectionOnTrack;
        peerConnection.current.onconnectionstatechange = peerConnectionOnConnectionStateChange;
        peerConnection.current.oniceconnectionstatechange = peerConnectionOnICEConnectionStateChange;
        peerConnection.current.onicegatheringstatechange = () => {
            console.log("*** ICE gathering state changed to: " + peerConnection.current.iceGatheringState);
        }
        peerConnection.current.onnegotiationneeded = onNegotiationNeeded;
        peerConnection.current.onicecandidate = onIceCandidate;
    }

    useEffect(() => {
        // skip make call stuff if it is not outgoing call
        console.log("instructor-contact", callRequestSid, callRequestEmail, isOutgoingCall);
        if (!isOutgoingCall) return;
        async function realizeCallRequest() {
            /**
             * Start call handshake if callRequestSid is present
             */
            if (!callRequestSid || !callRequestEmail) return;
            try {
                isPolite.current = true;
                if (!peerConnection.current) {
                    initiatePeerConnection();
                }
                setInstructorName(callRequestEmail);
                console.log("Set incoming to false");
                isIncoming.current = false;

                makingOffer.current = true;
                const offer = await peerConnection.current.createOffer();
                console.log("Setting local descr 2", peerConnection.current.signalingState);
                await peerConnection.current.setLocalDescription(offer);
                console.log("SETTING setLocalDescription", offer);
                socketSendMessage({
                    offer: peerConnection.current.localDescription,
                    to: callRequestSid
                });
            } catch (err) {
                console.log("Should never happen");
                console.error(err);
            } finally {
                makingOffer.current = false;
            }
        }
        realizeCallRequest();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callRequestSid, callRequestEmail, isOutgoingCall])

    useEffect(() => {
        return () => closeVideoCall(true);
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!socketRef) return;
        socket.current = socketRef;

        async function handleOffer(data) {
            try {
                console.log("HANDLE OFFER");
                console.log("offer", data);
                if (!peerConnection.current) {
                    initiatePeerConnection();
                }
                const readyForOffer = !makingOffer.current &&
                    (peerConnection.current.signalingState === "stable" || isSettingRemoteAnswerPending.current);
                const offerCollision = !readyForOffer;
                ignoreOffer.current = !isPolite.current && offerCollision;
                if (ignoreOffer.current) {
                    return;
                }
                console.log("Checking if isIncoming is true", isIncoming);
                if (isIncoming.current) {
                    if (ringtoneSound) {
                        ringtoneSound.play();
                    }
                    // confirmation is done on the host-component side
                    // const confirmed = window.confirm(
                    //     `User "Socket: ${data.from}" wants to call you. Do you accept this call?`
                    // );
                    if (ringtoneSound) {
                        ringtoneSound.stop();
                    }
                    // if (!confirmed) {
                    //     socketSendMessage({
                    //         rejectCall: true,
                    //         to: data.from
                    //     });
                    //     return;
                    // }
                    isIncoming.current = false;
                }
                await peerConnection.current.setRemoteDescription(data.offer);

                if (!localVideoTag.current.srcObject) {
                    const devices = await navigator.mediaDevices.enumerateDevices();

                    const _hasCam = devices.some(function (d) { return d.kind === "videoinput"; });
                    const _hasMic = devices.some(function (d) { return d.kind === "audioinput"; });
                    setHasCam(_hasCam)
                    setHasMic(_hasMic)

                    const newStream = await navigator.mediaDevices.getUserMedia({ video: _hasCam, audio: _hasMic });
                    setStream(newStream);
                    if (localVideoTag.current) {
                        localVideoTag.current.srcObject = newStream;
                        const audioTracks = localVideoTag.current.srcObject.getAudioTracks();
                        const videoTracks = localVideoTag.current.srcObject.getVideoTracks();
                        if (audioMuted && audioTracks && audioTracks.length > 0) {
                            console.log("Audio Track muted")
                            audioTracks[0].enabled = !audioMuted;
                        }
                        if (videoMuted && videoTracks && videoTracks.length > 0) {
                            console.log("Video Track muted")
                            videoTracks[0].enabled = !videoMuted;
                        }
                    }
                    // Add the camera stream to the RTCPeerConnection
                    newStream.getTracks().forEach(
                        // track => peerConnection.current.addTransceiver(track, { streams: [newStream] })
                        track => peerConnection.current.addTrack(track, newStream)
                    );
                }
                console.log("Setting local descr", peerConnection.current.signalingState);
                await peerConnection.current.setLocalDescription();
                console.log("Setting local descr FINISHED");
                socketSendMessage({ answer: peerConnection.current.localDescription, to: data.from })
                onCallWith.current = data.from;
            } catch (err) {
                console.log(err);

            }
        }

        async function handleAnswer(data) {
            console.log("HANDLE ANSWER");
            console.log('answer', data)
            try {
                isSettingRemoteAnswerPending.current = true;
                console.log("onCallWith = ", onCallWith.current)
                const desc = new RTCSessionDescription(data.answer);
                await peerConnection.current.setRemoteDescription(desc);
                onCallWith.current = data.from;
                console.log("setting onCallWith = ", data.from)
                socketChangeAvailability(false)
            } catch (err) {
                console.error(err);
            } finally {
                isSettingRemoteAnswerPending.current = false;
            }
        }

        async function handleNewIceCandidate(data) {
            const candidate = new RTCIceCandidate(data.candidate);
            try {
                await peerConnection.current.addIceCandidate(candidate);
            } catch (err) {
                if (!ignoreOffer.current) console.log(err);
            }
        }

        function handleHangUp(data) {
            console.log("*** Received hang up notification from other peer");
            closeVideoCall();
            setAudioMuted(true);
            setVideoMuted(true);
        }

        socket.current.on("microtalk-notify-message", async (data) => {
            try {
                console.log(`message data`, data);
                if (data.offer) {
                    await handleOffer(data);
                } else if (data.answer) {
                    await handleAnswer(data);
                } else if (data.candidate) {
                    await handleNewIceCandidate(data);
                } else if (data.hangup) {
                    handleHangUp(data);
                } else if (data.rejectCall) {
                    alert(`User: "Socket: ${data.from}" rejected your call.`);
                    closeVideoCall();
                } else {
                    console.err("Message is unknown!");
                }
            } catch (error) {
                console.error(error);
            }
        });

        socket.current.on("remove-user", ({ socketId }) => {
            if (onCallWith.current === socketId) {
                // setOnCallWith(null);
                onCallWith.current = null;
                setInstructorName(null);
                remoteVideoTag.current.srcObject = null;
                localVideoTag.current.srcObject = null;
            }
        });
        socket.current.on("disconnect", () => {
            alert("This chat session was disconnected from server. Please close the tab.");
        });
        return () => closeVideoCall();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketRef]);
    function isMobileDevice() {
        let check = false;
        (function (a) {
            // if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };

    async function shareScreen() {
        const screenStream = await navigator.mediaDevices.getDisplayMedia({ cursor: true });
        const videoTrack = screenStream.getVideoTracks()[0];
        console.log(peerConnection.current.getSenders());
        const sender = peerConnection.current.getSenders().find((s) => {
            console.log(s);
            return s.track.kind === videoTrack.kind;
        });
        console.log('found sender:', sender);
        sender.replaceTrack(videoTrack);
        if (localVideoTag.current) {
            localVideoTag.current.srcObject = screenStream;
        }
        screenStream.getTracks()[0].onended = () => {
            const videoTrack = stream.getVideoTracks()[0];
            const sender = peerConnection.current.getSenders().find((s) => {
                return s.track.kind === videoTrack.kind;
            });
            console.log('found sender:', sender);
            sender.replaceTrack(videoTrack);
            if (localVideoTag.current) {
                localVideoTag.current.srcObject = stream;
            }
        }

    }

    function toggleMuteAudio() {
        if (stream) {
            setAudioMuted(!audioMuted)
            console.log(stream.getAudioTracks());
            const audioTrack = stream.getAudioTracks()[0];
            if (audioTrack) {
                stream.getAudioTracks()[0].enabled = audioMuted;
            }
        }
    }

    function toggleMuteVideo() {
        if (stream) {
            setVideoMuted(!videoMuted);
            if (stream.getVideoTracks()[0]) {
                stream.getVideoTracks()[0].enabled = videoMuted;
            }
            // if (!videoMuted) {
            //     const videoTrack = stream.getVideoTracks()[0];
            //     const sender = peerConnection.current.getSenders().find((s) => {
            //         return s.track && s.track.kind === videoTrack.kind;
            //     });
            //     if (sender) {
            //         // sender.track.stop();
            //         peerConnection.current.removeTrack(sender);
            //     }
            // } else {
            //     navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(newStream => {
            //         setStream(newStream);
            //         const senders = peerConnection.current.getSenders();
            //         senders.forEach((sender) => { peerConnection.current.removeTrack(sender) });
            //         newStream.getTracks().forEach(track => peerConnection.current.addTrack(track, newStream));
            //         if (localVideoTag.current) {
            //             localVideoTag.current.srcObject = newStream;
            //         }
            //     }).catch(err => console.log(err));

            // }

        }
    }

    let audioControl;
    if (audioMuted) {
        audioControl = <span className="iconContainer" onClick={() => toggleMuteAudio()}>
            <img src={microphonestop} alt="Unmute audio" />
        </span>
    } else {
        audioControl = <span className="iconContainer" onClick={() => toggleMuteAudio()}>
            <img src={microphone} alt="Mute audio" />
        </span>
    }

    let videoControl;
    if (videoMuted) {
        videoControl = <span className="iconContainer" onClick={() => toggleMuteVideo()}>
            <img src={camerastop} alt="Resume video" />
        </span>
    } else {
        videoControl = <span className="iconContainer" onClick={() => toggleMuteVideo()}>
            <img src={camera} alt="Stop audio" />
        </span>
    }

    let screenShare = <span className="iconContainer" onClick={async () => shareScreen()}>
        <img src={share} alt="Share screen" />
    </span>
    if (isMobileDevice()) {
        screenShare = <></>
    }

    let hangUp = <span className="iconContainer" onClick={() => closeVideoCall()}>
        <img src={hangup} alt="End call" />
    </span>

    let fullscreenButton;
    if (isfullscreen) {
        fullscreenButton = <span className="iconContainer" onClick={() => { setFullscreen(false) }}>
            <img src={minimize} alt="fullscreen" />
        </span>
    } else {
        fullscreenButton = <span className="iconContainer" onClick={() => { setFullscreen(true) }}>
            <img src={fullscreen} alt="fullscreen" />
        </span>
    }
    // console.log(fullscreenButton)
    if (!callRequestSid) return <></>

    return <>
        {callRequestEmail && <p>On call with {callRequestEmail}</p>}
        {usertype === 'instructor' && !ringtoneSound && (
            <div>
                <p>Please, give your consent to use an audio signal (ringtone) to notify you about incoming call.</p>
                <Button onClick={(e) => {
                    e.preventDefault();
                    setRingtoneSound(new Howl({
                        src: [ringtone],
                        loop: true,
                        preload: true
                    }));
                }} >Give consent</Button>
            </div>
        )}
        <Container>
            <div className="video-container">
                <video autoPlay muted className="local-video" id="local-video" onEnded={(event) => { console.log(event) }}
                    ref={localVideoTag}
                    poster="https://www.globalpharmatek.com/wp-content/uploads/2016/10/orionthemes-placeholder-image.jpg"
                ></video>
                <video autoPlay className="remote-video" id="remote-video"
                    ref={remoteVideoTag}
                    poster="https://www.globalpharmatek.com/wp-content/uploads/2016/10/orionthemes-placeholder-image.jpg"
                    onEnded={(event) => { console.log(event) }}
                ></video>
            </div>
            <div className="controlsContainer flex">
                {audioControl}
                {videoControl}
                {screenShare}
                {/* {fullscreenButton} */}
                {hangUp}
            </div>
            {!hasCam && <p>No Camera</p>}
            {!hasMic && <p>No Microphone</p>}
        </Container>
    </>;
}


function mapState(state) {
    return {};
}
const actionCreators = {};

const connectedMicroTalkConnect = connect(mapState, actionCreators)(MicroTalkConnect);
export { connectedMicroTalkConnect as MicroTalkConnect };
