import React, { useEffect } from 'react';
import { Router, Switch } from "react-router-dom";
import { connect } from "react-redux";

import config from "_configs";
import { history } from "_helpers";
import './App.css';
import './custom.css';
import '_css/common.css';
import '_css/adminProgress.css';

import { PageNotFound, PrivateRoute, CustomRoute, CacheBuster } from "_components";
import { PageHome, Login, ResetPassword, ForgotPassowrd, NoConnection } from "_pages";
import { PageChat, PageChatRedirect } from "_pages/MicroTalk";

import { PageManagerCabinet, PageInstructorCabinet, PageSystemCabinet } from "_pages";
import { VoiceCommandsDesignPage } from "_pages/admin/VoiceDesign";
import { ModulesPage } from "_pages/admin/Modules";
import { InstructionsPage } from "_pages/admin/Instructions";
import { ResourceManager } from '_pages/admin/Resources/ResourceManager';
import { PageUserCabinet } from "_pages/user";
import { OrganizationLogin } from "_pages/CL3/auth";
import { Client as CL3Client } from "_pages/CL3/client";
import { SessionManager as CL3SessionManager } from "_pages/CL3/sessionManager";
import { Train, Client } from "_pages";
import { userActions } from '_actions';

import { pdfjs } from "react-pdf";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AnalyticsPage } from '_pages/admin/Analytics';
import { InstrInProgressPage } from '_pages/admin/Analytics/InstrInProgress/InstrInProgressPage';
import { InstrUserDbPage } from '_pages/admin/Analytics/InstrUserDb';
import { SocketTest } from '_pages/sockets';

//Kyle
import {schedule} from '_pages/user/schedule/schedule'
//

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CL3Routes = () => {
  return [
    <CustomRoute exact key="cl3_auth_route_0" path={config.clientUrls.CL3__ORGANIZATION_LOGIN} component={OrganizationLogin} />,

    <PrivateRoute exact
      key="cl3_client_route_0" allowedRoles={['organization']}
      path={config.clientUrls.CL3__CLIENT_CABINET} component={CL3Client}
      variant={{ navbar: false }}
    />,
    <PrivateRoute exact key="cl3_session_manager_route_0" allowedRoles={['organization']} path={config.clientUrls.CL3__SESSION_MANAGER_CABINET} component={CL3SessionManager} />,
  ]
}

const AuthRoutes = () => {
  return [
    <CustomRoute exact key="auth_route_0" path={config.clientUrls.LOGIN} component={Login} />,
    <CustomRoute exact key="auth_route_1" path={config.clientUrls.RESET_PASSWORD(":token")} component={ResetPassword} />,
    <CustomRoute exact key="auth_route_2" path={config.clientUrls.FORGOT_PASSWORD} component={ForgotPassowrd} />,
  ];
}

const ChatRoutes = () => {
  return [
    <PrivateRoute exact key="chat_route_0" allowedRoles={['instructor']} path={config.clientUrls.CHAT_ROOM__INSTRUCTOR__REDIRECT} component={PageChatRedirect} />,
    <PrivateRoute exact key="chat_route_1" allowedRoles={['instructor']} path={config.clientUrls.CHAT_ROOM__INSTRUCTOR(":usertype(instructor)", ":token")} component={PageChat} />,
    <CustomRoute exact key="chat_route_2" path={config.clientUrls.CHAT_ROOM__USER(":usertype(user)", ":token")} component={PageChat} />
  ];
}

const CabinetRoutes = () => {
  return [
    <PrivateRoute exact key="cabinet_route_0" allowedRoles={['instructor']} path={config.clientUrls.INSTRUCTOR_CABINET} component={PageInstructorCabinet} />,
    <PrivateRoute exact key="cabinet_route_3" allowedRoles={['admin']} path={config.clientUrls.ADMIN_CABINET__VOICE_COMMANDS_DISIGN} component={VoiceCommandsDesignPage} />,
    <PrivateRoute exact key="cabinet_route_3" allowedRoles={['manager']} path={config.clientUrls.MANAGER_CABINET} component={PageManagerCabinet} />,
    <PrivateRoute exact key="cabinet_route_4" allowedRoles={['system']} path={config.clientUrls.SYSTEM_CABINET} component={PageSystemCabinet} />,

    <PrivateRoute exact key="cabinet_route_5" allowedRoles={['user', 'admin']} path={config.clientUrls.USER_CABINET} component={PageUserCabinet} />,

    <PrivateRoute exact key="cabinet_route_6" allowedRoles={['admin']} path={config.clientUrls.ADMIN_CABINET__MODULES} component={ModulesPage} />,
    

    <PrivateRoute exact key="cabinet_route_7" allowedRoles={['admin']} path={config.clientUrls.ADMIN_CABINET__ANALYTICS} component={AnalyticsPage} />,
    <PrivateRoute exact key="cabinet_route_8" allowedRoles={['admin']} path={config.clientUrls.ADMIN_CABINET__ANALYTICS__INSTR_IN_PROGRESS} component={InstrInProgressPage} />,
    <PrivateRoute exact key="cabinet_route_9" allowedRoles={['admin']} path={config.clientUrls.ADMIN_CABINET__ANALYTICS__INSTR_USER_DB} component={InstrUserDbPage} />,

    <PrivateRoute exact key="cabinet_route_10" allowedRoles={['admin']} path={config.clientUrls.ADMIN_CABINET__INSTRUCTIONS} component={InstructionsPage} />,
    <PrivateRoute exact key="cabinet_route_11" allowedRoles={['admin','user','manager','instructor']} path={config.clientUrls.ADMIN_CABINET__RESOURCES} component={ResourceManager} />,
    <PrivateRoute exact key="cabinet_route_00" allowedRoles={['manager']} path={config.clientUrls.SECRET_SOCKET_TEST} component={SocketTest} />,
    <PrivateRoute exact key="cabinet_route_12" allowedRoles={['admin','user','manager']} path={config.clientUrls.SCHEDULE_ROUTE} component={schedule} />,

  ];
}

const App = (props) => {

  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, [])

  return (
    <>
      <Router history={history}>
        <CacheBuster>
          <ToastContainer />
          <Switch >
            <CustomRoute exact path={config.clientUrls.HOME} component={PageHome} />
            <PrivateRoute path={config.clientUrls.TRAIN} component={Train} />
            <PrivateRoute path={config.clientUrls.CLIENT} component={Client} />
            {AuthRoutes()}
            {ChatRoutes()}
            {CabinetRoutes()}
            {CL3Routes()}
            <CustomRoute path={config.clientUrls.NETWORK__PROBLEM} component={NoConnection} />
            <CustomRoute component={PageNotFound} />
          </Switch>
        </CacheBuster>
      </Router>
    </>
  );
}

function mapState(state) {
  return {};
}

const actionCreators = {
  getProfile: userActions.getProfile
};

const connectedApp = connect(
  mapState,
  actionCreators
)(App);
export { connectedApp as App };
