import { instructionService } from "_services";
import { alertActions, loadingAPIActions } from "_actions";


export const instructionActions = {

    listOrgInstructions,
    listUserInstructions,
    assignInstructionToUser,
    withholdInstructionFromUser,

    listAssignedUsers,
    listApplicableUsers,
    getInstruction,
    getBlocksPerInstruction,
    getBlocksPerInstruction_ConnectedBlock,
    getBlockResourcePerInstruction,
    getBlockResourcePerInstruction_ConnectedBlock,
    getBlockAudioResourcePerInstruction,
    getBlockAudioResourcePerInstruction_ConnectedBlock,
    sendFeedbackPerBlock,
    finishInstruction,
};

function listAssignedUsers(instruction_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return instructionService.listAssignedUsers(instruction_id).then(
            ({ message, data }) => {
                // dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = [{finished, id, email}]
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function listApplicableUsers(instruction_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return instructionService.listApplicableUsers(instruction_id).then(
            ({ message, data }) => {
                // dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = [{id, email}]
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}
function getInstruction(instruction_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return instructionService.getInstruction(instruction_id).then(
            ({ message, data }) => {
                // dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {id, name, description, created_at}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function listOrgInstructions() {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return instructionService.listOrgInstructions().then(
            ({ message, data }) => {
                // dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = [{id, name, description, created_at}]
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function listUserInstructions(instruction_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return instructionService.listUserInstructions(instruction_id).then(
            ({ message, data }) => {
                // dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = [{id, name, description, created_at }]
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}



function assignInstructionToUser(instruction_id, userId) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return instructionService.assignInstructionToUser(
            instruction_id, userId
        ).then(
            ({ message, data }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {user_id, instruction_id}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function withholdInstructionFromUser(instruction_id, userId) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return instructionService.withholdInstructionFromUser(
            instruction_id, userId
        ).then(
            ({ message, data }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {user_id, instruction_id}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}


function getBlocksPerInstruction(instruction_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return instructionService.getBlocksPerInstruction(
            instruction_id
        ).then(
            ({ data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {user_id, instruction_id}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function getBlocksPerInstruction_ConnectedBlock(instruction_id, connected_block_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return instructionService.getBlocksPerInstruction_ConnectedBlock(
            instruction_id, connected_block_id
        ).then(
            ({ data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function getBlockResourcePerInstruction(instruction_id, block_id) {
    return (dispatch) => {
        // dispatch(loadingAPIActions.startLoadingAPI());
        return instructionService.getBlockResourcePerInstruction(
            instruction_id, block_id
        ).then(
            ({ data }) => {
                // dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {block_id, url, expires_at}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                // dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}


// Attention - block_id and connected_block_id switch positions (for generalization)
function getBlockResourcePerInstruction_ConnectedBlock(instruction_id, block_id, connected_block_id) {
    return (dispatch) => {
        // dispatch(loadingAPIActions.startLoadingAPI());
        return instructionService.getBlockResourcePerInstruction_ConnectedBlock(
            instruction_id, connected_block_id, block_id
        ).then(
            ({ data }) => {
                // dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {block_id, url, expires_at}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                // dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function getBlockAudioResourcePerInstruction(instruction_id, block_id) {
    return (dispatch) => {
        // dispatch(loadingAPIActions.startLoadingAPI());
        return instructionService.getBlockAudioResourcePerInstruction(
            instruction_id, block_id
        ).then(
            ({ data }) => {
                // dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {block_id, url, expires_at}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                // dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

// Attention - block_id and connected_block_id switch positions (for generalization)
function getBlockAudioResourcePerInstruction_ConnectedBlock(instruction_id, block_id, connected_block_id) {
    return (dispatch) => {
        // dispatch(loadingAPIActions.startLoadingAPI());
        return instructionService.getBlockAudioResourcePerInstruction_ConnectedBlock(
            instruction_id, connected_block_id, block_id
        ).then(
            ({ data }) => {
                // dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {block_id, url, expires_at}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                // dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function sendFeedbackPerBlock(instruction_id, blockId, feedback) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return instructionService.sendFeedbackPerBlock(instruction_id, blockId, feedback).then(
            ({ message, data }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {id} // feedback_id
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function finishInstruction(instruction_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return instructionService.finishInstruction(instruction_id).then(
            ({ message }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                return true;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}
