import { ArrowLeftIcon, ArrowRightIcon, ReplyIcon } from "@primer/octicons-react";
import React, { useEffect, useState, useRef } from "react";
import { instructionActions } from "_actions";
import { connect } from "react-redux";
import { isNone } from "_helpers";
import { Resource, PDFResource } from "_components";
import { Button, ButtonGroup, Dropdown, ProgressBar } from "_components/Base";
import pdfPlaceholder from "_images/placeholder.pdf";

const WINDOW_SIZE = 5;

const styles = {
    progressBar: {
        position: "relative",
    },
    overlayText: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        fontSize: "15px",
        fontWeight: "bold"
    }
}

const GoalProgressBar = ({ progress, goals, ...props }) => {
    const prN = progress[progress.length - 1];
    return (
        <div style={{ textAlign: "center" }}>
            <span style={styles.overlayText}>Current Goal({props.level}/{props.nbOfInstructions}): {goals[0]}</span>
            <ProgressBar style={styles.progressBar}>
                <ProgressBar key={1} striped variant="success" now={Math.ceil(100 * (prN.current / prN.total))} />
                <ProgressBar key={2} animated now={Math.ceil(100 * (1 / prN.total))} />
            </ProgressBar>
        </div>
    )
}

const Content = ({ children, open = true, leaves = [], selected_connected_block, connected_blocks = [], instruction = {}, selectBlockId, onItemSelect, onFinishInstruction, onConnectedBlockClick, onReturnToInstruction, width, height, ...props }) => {
    // define functions according to the mode (if connected_blocks is null or not)
    const getBlockResourcePerInstruction = isNone(connected_blocks) ? props.getBlockResourcePerInstruction_ConnectedBlock : props.getBlockResourcePerInstruction;
    const getBlockAudioResourcePerInstruction = isNone(connected_blocks) ? props.getBlockAudioResourcePerInstruction_ConnectedBlock : props.getBlockAudioResourcePerInstruction;
    const audioSource = useRef();
    const audioBuffer = useRef();
    const context = useRef();
    const [resources, setResources] = useState([]);
    const [leavesLoading, setLeavesLoading] = useState(false);
    // array of indices of leaves in resources [1,5,7]
    const [relationsMap, setRelationsMap] = useState([]);
    const [audioPlaying, setAudioPlaying] = useState(false);
    const [ctrlIndices, setCtrlIndices] = useState({ preload: 0, current: 0 })

    const getLeafs = (_resources) => _resources.filter(({ isLeaf }) => isLeaf);
    const getLeafResourceWindow = (_resources, amount = WINDOW_SIZE) => {
        return getLeafs(_resources)
            // just to be ready to jump over the menu
            .slice(
                Math.max(ctrlIndices.current - amount + 1, 0),
                ctrlIndices.current + amount
            );
    }
    /**
     * Downloads the Leaf urls from server in the range [currindx - amount, currindx + amount]
     * @param {Array} customResources [optional] - local copy of resources in case of in between operations (useEffect)
     * @param {Number} amount [optional] - range from currentResourceIndex to retrieve urls
     * @returns new resources with mapped urls if custerResources is provided
     */
    const getUrlsBatch = async (customResources, amount = WINDOW_SIZE, force = false) => {
        // if customResources is provided - do not  setResources and return it as a result
        const _resources = customResources || resources;
        if (!_resources || _resources.length < 1) {
            return [];
        }
        const promises = getLeafResourceWindow(_resources, amount)
            .filter(({ url, failedToLoad }) => force || !url || failedToLoad)
            .map(async ({ id }) => {
                // run generalized function
                const res = await getBlockResourcePerInstruction(instruction.id, id, selected_connected_block);
                return { url: `${res.url}#toolbar=0`, id };
            })
        if (!promises || promises.length < 1) return;
        const res = await Promise.all(promises);
        const urlsDict = res.reduce((acc, { id, url }) => { acc[id] = url; return acc; }, {});
        const resultResources = _resources.map(resource => {
            return { ...resource, url: urlsDict[resource.id] || resource.url }
        });
        if (customResources) return resultResources;
        setResources(resultResources)
    }
    const resetAudio = () => {
        context.current = new window.AudioContext();
        if (audioSource.current) {
            audioSource.current.stop();
        }
        setAudioPlaying(false);
    }

    useEffect(() => {
        if (!selectBlockId || resources.length < 1 ||
            resources[resources.findIndex(o => o.id === selectBlockId)] === ctrlIndices.current
        ) return;
        const indx = resources.findIndex(o => o.id === selectBlockId)
        setCtrlIndices({ ...ctrlIndices, preload: indx, current: indx });
        resetAudio();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectBlockId]);


    useEffect(() => {
        async function fetchData() {
            setLeavesLoading(true);
            if (onItemSelect)
                onItemSelect(0)
            const _resources = leaves.map((block) => {
                return { ...block, isLeaf: block.is_leaf } //id: block.id, name: block.name,
            });
            let _populatedResources = [],
                _relationsMap = [];
            if (_resources && _resources.length > 0) {
                _populatedResources = await getUrlsBatch(_resources);
                _relationsMap = _resources.reduce((acc, { isLeaf }, indx) => {
                    if (isLeaf) acc.push(indx);
                    return acc;
                }, [])
            }
            setRelationsMap(_relationsMap);
            setResources(_populatedResources);
            setCtrlIndices({ ...ctrlIndices, preload: 0, current: 0 });
            setLeavesLoading(false);
        }
        fetchData();
        resetAudio()
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leaves]);

    useEffect(() => {
        async function fetchData() {
            if (!resources || resources.length < 1) return;
            await getUrlsBatch(); // works on default @resources
        }
        fetchData();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctrlIndices.preload])

    useEffect(() => {
        async function fetchData() {
            const newResources = await getUrlsBatch(resources, 1);
            setResources(newResources)
        }
        if (!resources || resources.length < 1) return;
        if (isNone(ctrlIndices.current)) return;
        if (!resources[relationsMap[ctrlIndices.current]].failedToLoad) return;
        fetchData();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctrlIndices.current])

    const showPreviousResource = (e) => {
        if (ctrlIndices.current > 0) {
            const newCurrentIndx = ctrlIndices.current - 1;
            setCtrlIndices({ ...ctrlIndices, current: newCurrentIndx, preload: newCurrentIndx });
            if (onItemSelect)
                onItemSelect(newCurrentIndx)
        }
    }
    const showNextResource = (e) => {
        if (ctrlIndices.current < resources.length - 1) {
            const newCurrentIndx = ctrlIndices.current + 1;
            setCtrlIndices({ ...ctrlIndices, current: newCurrentIndx, preload: newCurrentIndx });
            if (onItemSelect)
                onItemSelect(newCurrentIndx)
        } else {
            if (onFinishInstruction && window.confirm("This is the end of the instruction. Are you sure you want to finish it?")) {
                onFinishInstruction();
            }
        }
    }

    const createAudioSourceAndStart = () => {
        audioSource.current = context.current.createBufferSource();
        audioSource.current.buffer = audioBuffer.current;
        audioSource.current.connect(context.current.destination);
        audioSource.current.start();
        audioSource.current.current_block_id = leafs[ctrlIndices.current].id;
        audioSource.current.addEventListener('ended', () => {
            setAudioPlaying(false);
        }, false);
        setAudioPlaying(true);
    }

    const playAudioResource = async (e) => {
        if (!leafs[ctrlIndices.current].audio_url) {
            return;
        }
        try {
            if (audioSource.current && leafs[ctrlIndices.current].id === audioSource.current.current_block_id) {
                createAudioSourceAndStart();
                return;
            }
            context.current = new window.AudioContext();
            // run generalized function
            const response = await getBlockAudioResourcePerInstruction(instruction.id, leafs[ctrlIndices.current].id, selected_connected_block)
            const audioResponse = await fetch(response.url);
            const arrayBuffer = await audioResponse.arrayBuffer();
            audioBuffer.current = await context.current.decodeAudioData(arrayBuffer);
            createAudioSourceAndStart()
        } catch (err) {
            console.log(err);
        }
    }
    const stopAudioResource = (e) => {
        if ((!leafs[ctrlIndices.current].audio_url) ||
            (!audioPlaying)) {
            return;
        }
        try {
            audioSource.current.stop();
            setAudioPlaying(false);
        } catch (err) {
            console.log(err);
        }
    }

    const definePreloadIndx = (leafs) => {
        for (let dist = 0; dist < WINDOW_SIZE; dist++) {
            for (let sgn of [-1, 1]) {
                let indx = ctrlIndices.current + sgn * dist;
                if (leafs[indx] && !leafs[indx].loaded) {
                    return indx
                }
            }
        }
        return null;
    }

    if (!open) return null;

    const leafs = getLeafs(resources);
    const preloadIndx = definePreloadIndx(leafs)
    const prerenderContent = (
        <div className="prerender-resource-container">
            {preloadIndx !== undefined && preloadIndx !== null && preloadIndx >= 0 &&
                <>
                    <p> {preloadIndx} / {leafs.length}</p>
                    {leafs[preloadIndx] && leafs[preloadIndx].url && !leafs[preloadIndx].loaded && (
                        <Resource url={leafs[preloadIndx].url}
                            width={50}
                            onLoad={() => {
                                console.log(`Loaded ${preloadIndx}`);
                                const _resources = resources.map((r, i) => {
                                    return { ...r, loaded: r.loaded || relationsMap[preloadIndx] === i }
                                });
                                setResources(_resources)
                            }}
                            onError={(e) => {
                                console.error("ERROR: RESOURCE PRELOAD - set to reupload ERROR");
                                console.error(e);
                                const _resources = resources.map((r, i) => {
                                    if (relationsMap[preloadIndx] !== i) return r;
                                    return { ...r, failedToLoad: true };
                                });
                                setResources(_resources)
                            }}
                        />
                    )}
                </>

            }
        </div>
    );


    let content = null;
    if (!leafs || leafs.length < 1) {
        content = (
            <>
                Nothing to show here! Maybe ads here?
                <PDFResource url={pdfPlaceholder} width={width} height={height} />
            </>
        )
    } else if ((leavesLoading && !leafs[ctrlIndices.current]) || !leafs[ctrlIndices.current].loaded) {
        content = (
            <>
                Loading
                <PDFResource url={pdfPlaceholder} width={width} height={height} />
            </>
        )
    } else {
        content = (
            <>
                <GoalProgressBar
                    goals={leafs[ctrlIndices.current].goals || leafs[ctrlIndices.current].name}
                    progress={leafs[ctrlIndices.current].goalProgress}
                    level={leafs[ctrlIndices.current].level.split(",").at(1)}
                    nbOfInstructions={leafs[leafs.length - 1].level.split(",").at(1)}
                />
                <div className="d-flex">
                    <ButtonGroup>
                        <Button variant="outline-primary" onClick={showPreviousResource}><ArrowLeftIcon /></Button>
                        <Button variant="outline-primary" onClick={showNextResource}><ArrowRightIcon /></Button>
                        {leafs[ctrlIndices.current].audio_url &&
                            <>
                                <Button variant="outline-primary" onClick={playAudioResource}>Play audio</Button>
                                <Button variant="outline-primary" onClick={stopAudioResource}
                                    disabled={!audioPlaying}
                                >
                                    Stop audio
                                </Button>
                            </>
                        }
                        {isNone(connected_blocks) &&
                            <Button variant="outline-primary" onClick={onReturnToInstruction}><ReplyIcon /></Button>
                        }
                    </ButtonGroup>
                    {!isNone(connected_blocks) && connected_blocks.length > 0 &&
                        <Dropdown items={
                            connected_blocks
                                .map(o => {
                                    return {
                                        onClick: () => onConnectedBlockClick(o),
                                        name: o.name
                                    }
                                })}
                            variant="outline-primary"
                            className="ml-5"
                            title="Details"
                        />
                    }
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Resource url={leafs[ctrlIndices.current].url} width={width} height={height}
                        onError={async (e) => {
                            console.error("ERROR: RESOURCE PREVIEW - reupload immediately");
                            // get url resource right away
                            if (resources[relationsMap[ctrlIndices.current]].failedAttempts > 5) {
                                console.error("ERROR: RESOURCE PREVIEW - exceeded reupload attempts (5)");
                                return;
                            }
                            const newResources = await getUrlsBatch(resources, 1, true);
                            const _resources = newResources.map((r, i) => {
                                if (relationsMap[ctrlIndices.current] !== i) return r;
                                const failedAttempts = (r.failedAttempts || 0) + 1;
                                return { ...r, failedAttempts };
                            })
                            setResources(_resources);
                        }}
                    />
                </div>
            </>
        )
    }

    return <div className="instruction-content-container d-block full-width" style={{ height: `${height + 38}px` }}>
        {children}
        {content}
        {prerenderContent}
    </div>
}


function mapState(state) {
    return {};
}

const actionCreators = {
    getBlockResourcePerInstruction: instructionActions.getBlockResourcePerInstruction,
    getBlockResourcePerInstruction_ConnectedBlock: instructionActions.getBlockResourcePerInstruction_ConnectedBlock,
    getBlockAudioResourcePerInstruction: instructionActions.getBlockAudioResourcePerInstruction,
    getBlockAudioResourcePerInstruction_ConnectedBlock: instructionActions.getBlockAudioResourcePerInstruction_ConnectedBlock,
};

const connectedContent = connect(mapState, actionCreators)(Content);
export { connectedContent as Content };
