import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { userActions, alertActions } from "_actions";
import { Form, Button } from "react-bootstrap";

const ResetPassword = (props) => {
    const [credentials, setCredentials] = useState({ password: "", passwordConfirm: "" });
    useEffect(() => {
        props.clearAlert();
        props.logout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        props.clearAlert();
        setCredentials({ ...credentials, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { token } = props.match.params;
            props.reset(token, credentials.password, credentials.passwordConfirm);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <h3>Please enter your new password</h3>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicPassword1">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control name="password" type="password" placeholder="Password" value={credentials.password} onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="formBasicPassword2">
                    <Form.Label>Confirm New Password</Form.Label>
                    <Form.Control name="passwordConfirm" type="password" placeholder="Password" value={credentials.passwordConfirm} onChange={handleChange} />
                </Form.Group>
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Reset
            </Button>
            </Form>
        </>
    )
}


function mapState(state) {
    return {};
}

const actionCreators = {
    logout: userActions.logout,
    reset: userActions.reset,
    errorAlert: alertActions.error,
    clearAlert: alertActions.clearError,
    successAlert: alertActions.success,
    confirmAlert: alertActions.confirm,
};

const connectedResetPassword = connect(mapState, actionCreators)(ResetPassword);
export { connectedResetPassword as ResetPassword };
