import React, { useEffect, useState } from "react";
import { XIcon, CommentIcon, NoteIcon, PeopleIcon } from "@primer/octicons-react";

const styles = {
    slideSidebar: (height, width = "200px") => {
        return {
            height: `${height - 32}px`,
            width: width,
            opacity: 1,
            transition: "width 0.s, opacity 0.s, transform 0.s", // used to be 0.7s
            overflowY: "auto"
        }
    },
    slideSidebarClose: {
        width: "0px",//!important
        opacity: "0",
        transform: "translate(-50px, 0)"
    },
    slideSidebarContainer: {
        backgroundColor: "rgba(211, 211, 211, 0.35)",
        transition: "background-color 0.7s",
    },
    slideSidebarContainerClose: {
        backgroundColor: "transparent",
    }
}

const MAIcon = ({ onClick, icon: Component, ...rest }) => {
    return <span onClick={onClick} {...rest}>
        <Component size='medium' />
    </span>
}

const MACommentIcon = (rest) => <MAIcon icon={CommentIcon} {...rest} />
const MANotesIcon = (rest) => <MAIcon icon={NoteIcon} {...rest} />
const MACallInstructorIcon = (rest) => <MAIcon icon={PeopleIcon} {...rest} />
const CloseIcon = (rest) => <MAIcon icon={XIcon} {...rest} />

const IconSet = ({ children, ...rest }) => {
    return <div className="d-flex flex-column">
        {children}
    </div>
}

export const OPEN_STATES = {
    COMMENT: "comment",
    NOTE: "note",
    CALL_INSTRUCTOR: "call",
    RECEIVE_CALL: "receive",

    NONE: false,
}

export const RightSidebar = ({ open, hidden, children, onIsOpenChange, availablePanels, width = "200px", height }) => {
    const [isOpen, setIsOpen] = useState(OPEN_STATES.NONE);
    useEffect(() => {
        if (isOpen !== open) {
            setIsOpen(!isOpen);
            onIsOpenChange(open);
        }
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])
    if (hidden) return null;
    let contentStyle = { ...styles.slideSidebar(height, width) };
    let containerStyle = { ...styles.slideSidebarContainer }
    if (!isOpen) {
        contentStyle = { ...contentStyle, ...styles.slideSidebarClose }
        containerStyle = { ...containerStyle, ...styles.slideSidebarContainerClose }
    }
    const switchOpenState = (_state) => {
        setIsOpen(_state);
        if (onIsOpenChange) onIsOpenChange(_state);
    }
    const availableMicrotalk = !availablePanels || availablePanels.includes("microtalk")
    const availableNote = !availablePanels || availablePanels.includes("note")
    const availableComment = !availablePanels || availablePanels.includes("comment")
    return <div style={containerStyle} >
        <div className="d-flex justify-content-end slide-sidebar-icon">
            {isOpen ?
                <CloseIcon onClick={() => switchOpenState(OPEN_STATES.NONE)} />
                :
                <IconSet>
                    {availableComment && <MACommentIcon onClick={() => switchOpenState(OPEN_STATES.COMMENT)} />}
                    {availableNote && <MANotesIcon onClick={() => switchOpenState(OPEN_STATES.NOTE)} />}
                    {availableMicrotalk && <MACallInstructorIcon onClick={() => switchOpenState(OPEN_STATES.CALL_INSTRUCTOR)} />}
                </IconSet>
            }
        </div>

        <div style={contentStyle}>
            {children}
        </div>
    </div >
}