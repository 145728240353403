import React from 'react';
import { connect } from "react-redux";
import { organizationActions } from "_actions";
import { UserManagerPanel } from './UserManagerPanel';

const PageManagerCabinet = (props) => {

    return <>
        <UserManagerPanel />
    </>
}


function mapState(state) {
    return {};
}
const actionCreators = {
    listOrgUsers: organizationActions.listOrgUsers
};

const connectedPageManagerCabinet = connect(mapState, actionCreators)(PageManagerCabinet);
export { connectedPageManagerCabinet as PageManagerCabinet };
