import React, { useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { chatActions, userActions, instructionActions } from "_actions";
import { Sidebar } from "./Sidebar";
import { RightSidebar, OPEN_STATES } from "./RightSidebar";
import { Content } from "./Content";
import { Button, Form, Modal } from "_components/Base";
import { TreeView, TreeNode } from "_components";
import { useContainerDimensions } from "_hooks/useResize";
import { Howl } from 'howler';
import ringtone from '_sounds/ringtone.mp3'

import "_css/userCabinet.css";
import useMAUserCabinetWrapper from "_hooks/sockets/useMAUserCabinetWrapper";
import { MicroTalkConnect } from "_pages/MicroTalk/MicroTalkConnect";
import { history } from "_helpers";

const MA_RESOURCE_RATIO = 1.3;
const COMMENT_PANEL = { width: 300 }

const PageUserCabinet = (props) => {
    const [modal, setModal] = useState({ open: false });
    const [instructions, setInstructions] = useState([]);
    const [supBlock, setSupBlock] = useState(null);
    const [selectedInstruction, setSelectedInstruction] = useState({});
    const [isOpen, setIsOpen] = useState(false); // sidebar is closed at the beginning
    const [rightSidebarState, setRightSidebarState] = useState(OPEN_STATES.NONE);
    const [selectedElement, setSelectedElement] = useState({
        aspect_ratio: null,
        selectBlockId: null,
        activeElements: new Set(),
        goals: []
    });
    const [selectedSupBlockElement, setSelectedSupBlockElement] = useState({
        aspect_ratio: null,
        selectBlockId: null,
        activeElements: new Set()
    });

    const [data, setData] = useState({ leaves: [], blocks: [], connected_blocks: [] })
    const [supBlockData, setSupBlockData] = useState({ leaves: [], blocks: [] })
    const [showInstructions, setShowInstructions] = useState(true);
    const [rsCommentContent, setRSCommentContent] = useState("");
    const [rsNoteContent, setRSNoteContent] = useState({});

    const containerRef = useRef();

    const [
        instructors, socket, callRequest,
        isOutgoingCall, forceRerender,
        socketInitiateCall
    ] = useMAUserCabinetWrapper({
        usertype: "user",
        onAcceptIncomingCall: onAcceptIncomingCall,
        onPreConfirmCallAction: onPreConfirmCallAction,
        onPostConfirmCallAction: onPostConfirmCallAction,
        onSocketIncomingCallPlug: onSocketIncomingCallPlug,
        onHangUp: handleHangup,
        ...props
    });
    const ringtoneSound = useRef();
    const { width, height } = useContainerDimensions(containerRef);

    // useLayoutEffect instead of useEffect to help reduce flickering
    useLayoutEffect(() => {
        if (history.action === "PUSH") {
            ringtoneSound.current = new Howl({
                src: [ringtone],
                loop: true,
                preload: true
            });
        }
        const fetchData = async () => {
            try {
                // data = [{id, name, description, created_at}]
                const _instructions = await props.listUserInstructions();
                setInstructions(_instructions);
                if (_instructions.length !== 1) {
                    // if many instructions to choose from, let user choose from sidebar
                    setIsOpen(true);
                    return;
                }
                // if (_instructions.length === 1)
                // @TODO: very similar to handle instruction select below, refactor!
                let instr = _instructions[0];
                setSelectedInstruction(instr);
                setRSNoteContent({})
                setSelectedElement({
                    ...selectedElement,
                    selectBlockId: null,
                    activeElements: new Set(),
                    goals: []
                });

                const res = await props.getBlocksPerInstruction(instr.id);
                const _blocks = res.hierarchy;
                const _connected_blocks = res.connected_blocks
                    .sort((o1, o2) => o1.name.localeCompare(o2.name));
                if (!_blocks || _blocks.length < 1) {
                    setData({ ...data, leaves: [], blocks: [], connected_blocks: [] });
                    window.alert("This instruction has no resources in it. Please, let administrator know.")
                    return;
                }
                // hierarchy always starts with array of 1 element
                let _leaves = extractLeaves(_blocks[0]);
                _leaves = addGoalProgressField(_leaves); // just in case (in place)

                setData({
                    ...data,
                    leaves: _leaves,
                    blocks: _blocks,
                    connected_blocks: _connected_blocks
                });

                if (!instr.disableMenu) { // just to use as default - could be changed later
                    setShowInstructions(false)
                }

            } catch (err) {
                console.log(err);
            }
        }
        fetchData();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Extracts leaves from the hierarchy with attached parentIds (for a progress bar) and goal(s)
     * @param {*} obj 
     * @param {*} parentIds 
     * @param {*} goals 
     * @returns 
     */
    const extractLeaves = (obj, parentIds = [], goals = []) => {
        if (obj.is_leaf) return [{ ...obj, parentIds, goals }];
        return obj.children
            .map(child => extractLeaves(child, [obj.id, ...parentIds], [obj.goal || obj.name, ...goals]))
            .reduce((acc, cur, indx) => {
                // each element is array
                acc.push(...cur);
                return acc;
            }, [])
    }

    // @Attention: IN PLACE: modifies _leaves
    // Adds goalProgress to the object
    const addGoalProgressField = (_leaves) => {
        const counterDict = _leaves.reduce((acc, cur, i) => {
            for (let j in cur.parentIds) {
                const key = cur.parentIds.slice(j)
                acc[key] = (acc[key] || 0) + 1;
            }
            return acc;
        }, { [[]]: _leaves.length });

        _leaves.reduce((acc, cur, i) => {
            _leaves[i].goalProgress = []
            for (let j in cur.parentIds) {
                const key = cur.parentIds.slice(j)
                const currentState = (acc[key] != null ? acc[key] : -1) + 1;
                acc[key] = currentState;
                _leaves[i].goalProgress.push({ current: currentState, total: counterDict[key] })
            }
            // the last one is total counter
            _leaves[i].goalProgress.push({ current: i, total: counterDict[''] })
            return acc;
        }, { [[]]: _leaves.length });
        return _leaves
    }

    const handleSelectInstruction = (instr) =>
        async (e) => {
            e.preventDefault();
            if (selectedInstruction.id === instr.id) {
                if (!instr.disableMenu) { // just to use as default - could be changed later
                    setShowInstructions(false)
                }
                return;
            }
            setSelectedInstruction(instr);
            setRSNoteContent({})
            setSelectedElement({
                ...selectedElement,
                selectBlockId: null,
                activeElements: new Set(),
                goals: []
            });
            try {
                const res = await props.getBlocksPerInstruction(instr.id);
                const _blocks = res.hierarchy;
                const _connected_blocks = res.connected_blocks
                    .sort((o1, o2) => o1.name.localeCompare(o2.name));
                if (!_blocks || _blocks.length < 1) {
                    setData({ ...data, leaves: [], blocks: [], connected_blocks: [] });
                    window.alert("This instruction has no resources in it. Please, let administrator know.")
                    return;
                }
                // hierarchy always starts with array of 1 element
                let _leaves = extractLeaves(_blocks[0]);
                _leaves = addGoalProgressField(_leaves); // just in case (in place)
                setData({
                    ...data,
                    leaves: _leaves,
                    blocks: _blocks,
                    connected_blocks: _connected_blocks
                });

                if (!instr.disableMenu) { // just to use as default - could be changed later
                    setShowInstructions(false)
                }
            } catch (err) {
                console.log(err);
            }
        }

    let calcWidth = width - (isOpen ? 250 : 75) - (rightSidebarState ? COMMENT_PANEL.width : 0);
    let calcHeight = height - 75; /* - navbar*/
    let _MA_RESOURCE_RATIO = selectedElement.aspect_ratio || MA_RESOURCE_RATIO;
    if (calcWidth / _MA_RESOURCE_RATIO > calcHeight) {
        calcWidth = calcHeight * _MA_RESOURCE_RATIO;
    } else {
        calcHeight = calcWidth / _MA_RESOURCE_RATIO;
    }

    /**
     * Helper function to find object with id inside obj structure (through children)
     * @param {*} obj 
     * @param {*} id 
     * @returns 
     */
    const findBlocks = (obj, id) => {
        if (obj.id === id) return [{ id: obj.id, aspect_ratio: obj.aspect_ratio, goal: obj.goal || obj.name }];
        else if (obj.is_leaf) return null;
        else {
            for (let child of obj.children) {
                const res = findBlocks(child, id);
                if (res) return [...res, { id: obj.id, aspect_ratio: obj.aspect_ratio, goal: obj.goal || obj.name }]
            }
        }
        return null;
    }

    const onItemSelect = (item) => {
        let newItem = item;
        if (typeof (newItem) === 'number') {
            // newItem in this case is the index in the leaves array
            newItem = data.leaves[newItem];
        }

        if (!newItem) {
            // there is nothing
            setSelectedElement({
                ...selectedElement,
                selectBlockId: null,
                aspect_ratio: null,
                activeElements: new Set(),
                goals: []
            });
            return;
        }

        while (!newItem.is_leaf) {
            if (!newItem.children || newItem.children.length < 1) {
                window.alert("Error: No children found")
                return;
            }
            newItem = newItem.children[0]
        }

        const _activeBlocks = findBlocks(data.blocks[0], newItem.id);

        if (_activeBlocks && _activeBlocks.length > 0) {
            const prevSelectBlockId = selectedElement.selectBlockId;
            setSelectedElement({
                ...selectedElement,
                selectBlockId: _activeBlocks[0].id,
                aspect_ratio: _activeBlocks[0].aspect_ratio,
                activeElements: new Set(_activeBlocks.map(o => o.id)),
                goals: _activeBlocks.slice(1).reverse().map(o => o.goal).join(' -> '),
            });
            socket.current.emit("ma-client-progress-step", {
                from: prevSelectBlockId,
                to: _activeBlocks[0].id,
                instruction_id: selectedInstruction.id
            });
        }
    }

    const onFinishInstruction = async () => {
        try {
            await props.finishInstruction(selectedInstruction.id);
            socket.current.emit("ma-client-progress-finish", {
                instruction_id: selectedInstruction.id
            });
            // reload the page // LAZY WAY
            window.location.reload()
        } catch (err) {
            console.log(err);
        }
    }

    // exact copy of the onItemSelect but with supBlock orientation. Could be generalized (@TODO)
    const onSupBlockItemSelect = (item) => {
        let newItem = item;
        if (typeof (newItem) === 'number') {
            // newItem in this case is the index in the leaves array
            newItem = supBlockData.leaves[newItem];
        }

        if (!newItem) {
            // there is nothing
            setSelectedSupBlockElement({
                ...selectedSupBlockElement,
                selectBlockId: null,
                aspect_ratio: null,
                activeElements: new Set()
            });
            return;
        }

        while (!newItem.is_leaf) {
            if (!newItem.children || newItem.children.length < 1) {
                window.alert("Error: No children found")
                return;
            }
            newItem = newItem.children[0]
        }

        const _activeBlocks = findBlocks(supBlockData.blocks[0], newItem.id);
        if (_activeBlocks && _activeBlocks.length > 0) {
            setSelectedSupBlockElement({
                ...selectedSupBlockElement,
                selectBlockId: _activeBlocks[0].id,
                aspect_ratio: _activeBlocks[0].aspect_ratio,
                activeElements: new Set(_activeBlocks.map(o => o.id))
            });
        }
    }

    const onConnectedBlockClick = async (block) => {
        if (supBlock && block.id === supBlock.id) {
            setShowInstructions(false)
            return;
        }
        setSelectedSupBlockElement({
            ...selectedSupBlockElement,
            selectBlockId: null,
            activeElements: new Set()
        });
        try {
            const res = await props.getBlocksPerInstruction_ConnectedBlock(selectedInstruction.id, block.id);
            const _blocks = res.hierarchy;
            if (!_blocks || _blocks.length < 1) {
                setSupBlockData({ ...supBlockData, leaves: [], blocks: [] });
                window.alert("This instruction has no resources in it. Please, let administrator know.")
                return;
            }
            // hierarchy always starts with array of 1 element
            let _leaves = extractLeaves(_blocks[0]);
            if (!_leaves || _leaves.length < 1) {
                setSupBlockData({ ...supBlockData, leaves: [], blocks: [] });
                window.alert("This instruction has no resources in it. Please, let administrator know.")
                return;
            }
            _leaves = addGoalProgressField(_leaves); // just in case (in place)
            setSupBlockData({
                ...supBlockData,
                leaves: _leaves,
                blocks: _blocks
            });

            setShowInstructions(false);
            setSupBlock(block);
        } catch (err) {
            console.log(err);
        }
    }

    const handleExportNotes = (e) => {
        e.preventDefault();
        const element = document.createElement("a");
        const separator = "\n" + "=".repeat(20) + "\n";
        let _content = Object.values(rsNoteContent)
            .sort((o1, o2) => o1.order - o2.order)
            .map(o => `Goals: ${o.goals}\nNote: ${o.note}`)
            .join(separator);
        if (_content.length > 0) {
            _content = separator + _content;
        }
        const file = new Blob([_content], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `ma_notes_${new Date().getTime()}.txt`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }
    const handleSendFeedback = async (e) => {
        e.preventDefault();
        try {
            if (rsCommentContent && rsCommentContent.length > 0) {
                const res = await props.sendFeedbackPerBlock(
                    selectedInstruction.id,
                    selectedElement.selectBlockId,
                    rsCommentContent
                )
                socket.current.emit("ma-client-progress-comment", {
                    comment_id: res.id,
                    comment: rsCommentContent,
                    block_id: selectedElement.selectBlockId,
                    instruction_id: selectedInstruction.id
                });
                setRSCommentContent("")
            }
        } catch (err) {
            console.log(err);
        }
    }
    function handleHangup() {
        callRequest.current = {}
        isOutgoingCall.current = false;
        setRightSidebarState(OPEN_STATES.NONE)
        forceRerender();
    }

    const getRightSidebarContent = () => {
        let content = null
        if (rightSidebarState === OPEN_STATES.COMMENT) {
            content = <>
                <Form >
                    <Form.Label>Tell us what you think</Form.Label>
                    <Form.Control name="links" as="textarea" rows={Math.floor((height - 75) / 30)}
                        value={rsCommentContent}
                        onChange={(e) => { setRSCommentContent(e.target.value) }}
                    />
                    <Button onClick={handleSendFeedback} >Send</Button>
                </Form>
            </>
        } else if (rightSidebarState === OPEN_STATES.NOTE) {
            content = <div className="d-flex flex-column justify-content-between">
                <Form>
                    <Form.Label>Note to myself</Form.Label>
                    <Form.Control name="links" as="textarea" rows={Math.floor((height - 75) / 30)}
                        value={(rsNoteContent[selectedElement.selectBlockId] || {}).note || ""}
                        onChange={(e) => {
                            setRSNoteContent({
                                ...rsNoteContent,
                                [selectedElement.selectBlockId]: {
                                    order: (data.leaves || []).indexOf(selectedElement.selectBlockId),
                                    note: e.target.value,
                                    goals: selectedElement.goals
                                }
                            })
                        }}
                    />
                </Form>
                <Button variant="secondary" onClick={handleExportNotes}>Export all notes</Button>
            </div>
        } else if (rightSidebarState === OPEN_STATES.CALL_INSTRUCTOR) {
            // microtalk is added as constantly present element of right side bar
            // content = <MicroTalk
            //     usertype={microtalkSettings.usertype}
            //     token={microtalkSettings.token} />
        }
        return content;
    }
    function onAcceptIncomingCall() {
        setRightSidebarState(OPEN_STATES.RECEIVE_CALL)
    }

    function onPreConfirmCallAction() {
        if (ringtoneSound.current) {
            ringtoneSound.current.play()
        }
    }

    function onPostConfirmCallAction() {
        if (ringtoneSound.current) {
            ringtoneSound.current.stop()
        }
    }

    function closeModal() {
        setModal({ ...modal, open: false });
    }
    function onSocketIncomingCallPlug(user, usertype, callback) {
        setModal({
            open: true,
            handleClose: () => { closeModal(); callback(false); },
            handleOk: () => { closeModal(); callback(true); },
            content: `Do you want to accept call from ${user.email}(${usertype}) ? `
        })
    }



    let sidebarContent = null,
        supBlockContent = null;
    // if supplemental block was selected and must be shown here
    if (supBlock) {
        if (showInstructions) {
            sidebarContent = <Button style={{ fontWeight: 500 }} isLink key={`sup-block-list-0`}
                className='active'
                onClick={() => { setShowInstructions(false) }} //nothing happens because it's the only one button
            >
                {supBlock.name}
            </Button>
        } else if (supBlockData.blocks && supBlockData.blocks.length > 0) {
            sidebarContent = <>
                <span className="clickable"
                    style={{ fontWeight: 500 }}
                    onClick={() => setShowInstructions(true)}
                >{`...${supBlock.name}`}</span>
                <TreeView
                    items={supBlockData.blocks ? supBlockData.blocks[0].children : []}
                    node={TreeNode} top={false}
                    activeElements={selectedSupBlockElement.activeElements}
                    onItemClick={onSupBlockItemSelect} />
            </>
        }
        supBlockContent = <Content leaves={supBlockData.leaves}
            connected_blocks={null} // <- this means we are in the connected_block mode
            instruction={selectedInstruction} selectBlockId={selectedSupBlockElement.selectBlockId}
            width={calcWidth} height={calcHeight}
            onItemSelect={onSupBlockItemSelect}
            onReturnToInstruction={() => setSupBlock(null)}
            selected_connected_block={supBlock.id}
        />
    } else {
        // Standard instruction and NO supplemental block was selected
        if (showInstructions) {
            sidebarContent = <div className="d-flex flex-column">
                {instructions.length < 1 && <p>No unfinished Instructions </p>}
                {instructions.map((instr, indx) => (
                    <Button isLink key={`instr-list-${indx}`}
                        style={{ fontWeight: 500 }}
                        className={instr.id === selectedInstruction.id ? 'active' : ''}
                        onClick={handleSelectInstruction(instr)}
                    >
                        {instr.name}{ /*({instr.progress || "0"}%) */}
                    </Button>
                ))}
            </div>

        } else if (data.blocks && data.blocks.length > 0) {
            sidebarContent = <>
                <span className="clickable"
                    style={{ fontWeight: 500 }}
                    onClick={() => setShowInstructions(true)}
                >{`...${selectedInstruction.name}`}</span>
                <TreeView
                    items={data.blocks ? data.blocks[0].children : []}
                    node={TreeNode} top={false}
                    activeElements={selectedElement.activeElements}
                    onItemClick={onItemSelect} />
            </>
        }
    }

    const rightSidebarContent = getRightSidebarContent();

    return <>
        {modal.open && <Modal isopen={modal.open}
            handleClose={modal.handleClose}
            handleCancel={modal.handleClose}
            handleOk={modal.handleOk}
            modalTitle="Incoming call"
            modalBody={modal.content}
        />}
        <div className="d-flex" ref={containerRef} style={{ height: "calc(100vh - 54px)" }}>
            <Sidebar open={isOpen} onIsOpenChange={(_isOpen) => setIsOpen(_isOpen)} height={height}>
                {sidebarContent}
            </Sidebar>
            {supBlockContent}

            <Content open={!supBlock} leaves={data.leaves}
                connected_blocks={data.connected_blocks}
                instruction={selectedInstruction} selectBlockId={selectedElement.selectBlockId}
                width={calcWidth} height={calcHeight}
                onItemSelect={onItemSelect}
                onFinishInstruction={onFinishInstruction}
                onConnectedBlockClick={onConnectedBlockClick}
            >
                {!ringtoneSound.current &&
                    <div>
                        <Button onClick={(e) => {
                            e.preventDefault();
                            ringtoneSound.current = new Howl({
                                src: [ringtone],
                                loop: true,
                                preload: true
                            });
                            forceRerender();
                        }} >Allow MicroTalk to notify me about a call</Button>
                    </div>
                }
            </Content>

            <RightSidebar open={rightSidebarState} hidden={false /*!selectedElement.selectBlockId*/}
                availablePanels={!selectedElement.selectBlockId ? ["microtalk"] : null}
                height={height} width={`${COMMENT_PANEL.width}px`}

                onIsOpenChange={async (isOpen) => {
                    if (isOpen === OPEN_STATES.CALL_INSTRUCTOR) {
                        const availableInstructors = (instructors.current || []).filter(o => o.available)
                        if (availableInstructors.length < 1) {
                            window.alert("No instructors available at this moment.")
                        }
                        socketInitiateCall(availableInstructors[0]);
                    }
                    setRightSidebarState(isOpen);
                }}

            >
                {rightSidebarContent}
                <MicroTalkConnect
                    socketRef={socket.current} usertype="user"
                    callRequest={callRequest.current}
                    isOutgoingCall={isOutgoingCall.current}
                    onHangUp={() => handleHangup()} />
            </RightSidebar>

        </div>
    </>
}


function mapState(state) {
    const { user } = state.authentication;
    return { user };
}

const actionCreators = {
    listUserInstructions: instructionActions.listUserInstructions,
    getBlocksPerInstruction: instructionActions.getBlocksPerInstruction,
    getBlocksPerInstruction_ConnectedBlock: instructionActions.getBlocksPerInstruction_ConnectedBlock,
    sendFeedbackPerBlock: instructionActions.sendFeedbackPerBlock,
    createUserChatSession: chatActions.createUserChatSession,
    getMAClientToken: userActions.getMAClientToken,
    finishInstruction: instructionActions.finishInstruction,
};

const connectedPageUserCabinet = connect(mapState, actionCreators)(PageUserCabinet);
export { connectedPageUserCabinet as PageUserCabinet };
