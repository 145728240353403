import nlp from "compromise";
import nlpDates from 'compromise-dates';
import nlpNumbers from 'compromise-numbers';
import levenshtein from 'js-levenshtein';
import { VCL, VOICE_CONFIG_NAMES, VCL_DESCRIPTION } from "./voiceCommandLibrary";
import { findWithAttr } from "_helpers/data";

/*
const nlp = require("compromise"),
    blpDates = require("compromise-dates"),
    nlpNumbers = require("compromise-numbers");
*/

nlp.extend(nlpDates);
nlp.extend(nlpNumbers);

const COMMON_CONFIG_ELEMENTS = [
    VCL.onHelp,
    VCL.onStartTimer,
    VCL.onStopTimer,
    VCL.onMute,
    VCL.onUnmute,
    VCL.onFarewell,
    VCL.onOpenInstructions, // wherever user is - this command shows the home for instructions
    VCL.onOpenLogs, // wherever user is - this command shows the home for logs
    VCL.onOpenNotes, // wherever user is - this command shows the home for notes (?)

    VCL.onShowAllInstructions,
    VCL.onShowAllLogs,
    VCL.onShowAllNotes,

],
    NONE_CONFIG = new Set([
        ...COMMON_CONFIG_ELEMENTS,
    ]),
    INSTRUCTION_HOME_CONFIG = new Set([
        ...COMMON_CONFIG_ELEMENTS,
        VCL.onHome,
        VCL.onShowInstruction,
    ]),
    INSTRUCTION_INSTRUCTION_CONFIG = new Set([
        ...COMMON_CONFIG_ELEMENTS,
        VCL.onHome,
        VCL.onShowInstruction,
        VCL.onNext, VCL.onBack, VCL.onRestart,
        VCL.onPlayAudio, VCL.onStopAudio,
    ]),
    LOG_CONFIG = new Set([
        ...COMMON_CONFIG_ELEMENTS,
        VCL.onHome,
        VCL.onShowLog,
    ]),
    NOTE_CONFIG = new Set([
        ...COMMON_CONFIG_ELEMENTS,
        VCL.onHome,
    ]),
    // at this moment the same config but could be different for each help
    HELP_CONFIG = new Set([
        VCL.onClose
    ]);


const CONFIG_MAP = {
    [VOICE_CONFIG_NAMES.NONE]: NONE_CONFIG,
    [VOICE_CONFIG_NAMES.INSTRUCTIONS_HOME]: INSTRUCTION_HOME_CONFIG,
    [VOICE_CONFIG_NAMES.INSTRUCTIONS_INSTRUCTION]: INSTRUCTION_INSTRUCTION_CONFIG,
    [VOICE_CONFIG_NAMES.LOGS]: LOG_CONFIG,
    [VOICE_CONFIG_NAMES.NOTES]: NOTE_CONFIG,

    [VOICE_CONFIG_NAMES.HELP_HOME]: HELP_CONFIG,
    [VOICE_CONFIG_NAMES.HELP_INSTRUCTION_HOME]: HELP_CONFIG,
    [VOICE_CONFIG_NAMES.HELP_INSTRUCTION_INSTRUCTION]: HELP_CONFIG,
    [VOICE_CONFIG_NAMES.HELP_LOGS]: HELP_CONFIG,
    [VOICE_CONFIG_NAMES.HELP_NOTES]: HELP_CONFIG,
}


/**
 * Oversimplification of the various combinations of standard nlp().has() functionality
 **/
function _has(doc, phrases, options = null, useOr = true) {
    if (!phrases) return false;
    let joinFunc;
    if (useOr) joinFunc = (o1, o2) => o1 || o2;
    else joinFunc = (o1, o2) => o1 && o2;
    let res = !useOr; // or case : start with false, and - start with true
    if (phrases instanceof Array) {
        for (let phrase of phrases) {
            const args = [phrase];
            if (options) args.push(options);
            res = joinFunc(res, doc.has(...args))
        }
    } else {
        const args = [phrases];
        if (options) args.push(options);
        res = doc.has(...args);
    }
    return res;
}

function _has_vcl(config, doc, vcl) {
    if (!config.has(vcl)) {
        return false
    }
    const vcl_obj = VCL_DESCRIPTION[vcl];
    return vcl_obj.search.reduce((acc, cur, indx) => {
        if (!acc) return acc; //if false - don't compute the rest
        const res = _has(doc, cur.keys, cur.options);
        return acc && res;
    }, true);
}

function _has_vcl_from_obj(config, doc, comObj) {
    if (!config.has(comObj.onCommandName)) {
        return false
    }
    return comObj.search.reduce((acc, cur, indx) => {
        if (!acc) return acc; //if false - don't compute the rest
        const res = _has(doc, cur.keys, cur.options);
        return acc && res;
    }, true);
}

/**
 * @param {Object} cbs Object with all the callbacks 
 * @param {*} name Name of the callbacks to be called
 * @param  {...any} args Any argument possibly included in the callback
 * @returns the result of the callback with the arguments
 */
function callCbsFunction(cbs, name, ...args) {
    if (!cbs[name]) throw Error(`${name} callback must be supplied`);
    return cbs[name](...args);
}

/**
 * Parse the phrase on the time duration pattern
 * 
 * @param {String} command 
 * @returns Object with {hour, minute, second} keywords
 */
function getTimesDictFromPhrase(command) {
    const docImpl = nlp(command.replace('second', 'seconds').replace('secondss', 'seconds'));
    const timesDict = {};
    for (let range of docImpl.durations().get()) {
        for (let [k, v] of Object.entries(range)) {
            if (timesDict[k]) continue;
            timesDict[k] = v;
        }
    }
    return timesDict;
}

/**
 * Process config in case of standardized strings.
 * Returns associated with standardized string config. If Object is given - returns _config itself
 * 
 * Supported configs: none, logs, instructions, notes
 * 
 * @param {Object} config is an object containing allowed commands. Standardized strings are also allowed.
 */
function processConfig(config, ignore_error = true) {
    if (typeof config === "object") return config;
    const resultConfig = CONFIG_MAP[config];
    if (!ignore_error && !resultConfig)
        throw Error("[VOICE] Can't process config");
    return resultConfig || CONFIG_MAP["none"];
}

/**
 * Process voice commands with standardized set of callbacks 
 * 
 * Supported cbs functions (no brackets means no arguments):
 *  onShowInstruction(candidateName),
 *  onShowLog(candidateName),
 *  onShowAllInstructions,
 *  onShowAllLogs,
 * 
 *  onStopTimer, onStartTimer({hour, minute, second}),
 *  onPlayAudio, onStopAudio,
 *  onRestart, onBack, onNext,
 *  onFarewell,
 *  onMute, onUnmute, // both refer to reaction and not muting microphone!!!
 *  onHelp,
 *  onClose,
 *  onHome,
 * 
 *  onOpenInstructions,
 *  onOpenLogs,
 *  onOpenNotes,
 * 
 *  default // must be present to be default callback in case of commands is not classified
 * 
 * @param {String} command  s a phrase that will be compared with different patterns
 * @param {Object} cbs is an object containing callbacks. For exampe: cbs.onStartTimer or cbs.onNext 
 * @param {Object} config is an object containing allowed commands. Standardized strings are also allowed.
 */
const processCommand = (command, cbs, config = VOICE_CONFIG_NAMES.NONE, muted = false) => {
    let commandKey = "default",
        args = [command];
    const configImpl = processConfig(config);
    const doc = nlp(command);
    if (muted) {
        console.log("MUTED")
        if (_has_vcl(configImpl, doc, VCL.onUnmute)) {
            // interrupt for the mute -> unmute
            commandKey = VCL.onUnmute;
        }
        return callCbsFunction(cbs, commandKey, ...args);
    }
    const docVerbs = doc.clone();
    // const verbs = docVerbs.verbs().toInfinitive();
    if (_has_vcl(configImpl, doc, VCL.onShowInstruction)) {
        commandKey = VCL.onShowInstruction;
        const { keys, options } = VCL_DESCRIPTION[commandKey].after;
        let candidateName;
        for (let key of keys) {
            const _candidateName = doc.after(key, options).text();
            if (_candidateName && _candidateName.length > 0) {
                candidateName = _candidateName;
                break;
            }
        }
        args.push(normalizeString(candidateName));
    } else if (_has_vcl(configImpl, doc, VCL.onShowLog)) {
        commandKey = VCL.onShowLog;
        const { keys, options } = VCL_DESCRIPTION[commandKey].after;
        let candidateName;
        for (let key of keys) {
            const _candidateName = doc.after(key, options).text();
            if (_candidateName && _candidateName.length > 0) {
                candidateName = _candidateName;
                break;
            }
        }
        args.push(normalizeString(candidateName));
    } else if (_has_vcl(configImpl, doc, VCL.onShowAllInstructions)) {
        commandKey = VCL.onShowAllInstructions;
    } else if (_has_vcl(configImpl, doc, VCL.onShowAllLogs)) {
        commandKey = VCL.onShowAllLogs;
    } else if (_has_vcl(configImpl, doc, VCL.onShowAllNotes)) {
        commandKey = VCL.onShowAllNotes;
    } else if (_has_vcl(configImpl, doc, VCL.onStopTimer)) {
        commandKey = VCL.onStopTimer;
    } else if (_has_vcl(configImpl, doc, VCL.onStartTimer)) {
        const timesDict = getTimesDictFromPhrase(command);
        commandKey = VCL.onStartTimer;
        args.push(timesDict);
    } else if (_has_vcl(configImpl, doc, VCL.onStopAudio)) {
        commandKey = VCL.onStopAudio;
    } else if (_has_vcl(configImpl, doc, VCL.onPlayAudio)) {
        commandKey = VCL.onPlayAudio;
    } else if (_has_vcl(configImpl, docVerbs, VCL.onRestart)) { // to identify from VERBS
        commandKey = VCL.onRestart;
    } else if (_has_vcl(configImpl, doc, VCL.onBack)) {
        commandKey = VCL.onBack;
    } else if (_has_vcl(configImpl, doc, VCL.onNext)) {
        commandKey = VCL.onNext;
    } else if (_has_vcl(configImpl, doc, VCL.onFarewell)) {
        commandKey = VCL.onFarewell;
    } else if (_has_vcl(configImpl, doc, VCL.onMute)) {
        commandKey = VCL.onMute;
    } else if (_has_vcl(configImpl, doc, VCL.onUnmute)) {
        commandKey = VCL.onUnmute;
    } else if (_has_vcl(configImpl, doc, VCL.onHelp)) {
        commandKey = VCL.onHelp;
    } else if (_has_vcl(configImpl, doc, VCL.onHome)) {
        commandKey = VCL.onHome;
    } else if (_has_vcl(configImpl, doc, VCL.onOpenInstructions)) {
        commandKey = VCL.onOpenInstructions;
    } else if (_has_vcl(configImpl, doc, VCL.onOpenNotes)) {
        commandKey = VCL.onOpenNotes;
    } else if (_has_vcl(configImpl, doc, VCL.onOpenLogs)) {
        commandKey = VCL.onOpenLogs;
    } else if (_has_vcl(configImpl, doc, VCL.onClose)) {
        commandKey = VCL.onClose;
    }
    return callCbsFunction(cbs, commandKey, ...args);
}

/**
 * Process commands using a stack received from server
 * @param {*} commandStack 
 * @param {*} command 
 * @param {*} cbs 
 * @param {*} config 
 * @param {*} muted 
 * @returns 
 */
const processCommandAPI = (commandStack, command, cbs, config = VOICE_CONFIG_NAMES.NONE, muted = false) => {
    let commandKey = "default",
        args = [command];
    const configImpl = processConfig(config);
    const doc = nlp(command);
    if (muted) {
        const indexUnmute = findWithAttr(commandStack, 'onCommandName', VCL.onUnmute);
        const comObj = commandStack[indexUnmute];
        console.log("MUTED");
        if (_has_vcl_from_obj(configImpl, doc, comObj)) {
            // interrupt for the mute -> unmute
            commandKey = comObj.onCommandName;
        }
        return callCbsFunction(cbs, commandKey, ...args);
    }
    // const docVerbs = doc.clone();
    // const verbs = docVerbs.verbs().toInfinitive();
    for (let comObj of commandStack) {
        if (!_has_vcl_from_obj(configImpl, doc, comObj)) {
            continue;
        }
        commandKey = comObj.onCommandName;
        // describe specific rules over here
        if (comObj.after && comObj.after.keys && comObj.after.options) {
            // --in case it contains ".after" parameter
            const { keys, options } = comObj.after;
            let candidateName;
            for (let key of keys) {
                const _candidateName = doc.after(key, options).text();
                if (_candidateName && _candidateName.length > 0) {
                    candidateName = _candidateName;
                    break
                }
            }
            args.push(normalizeString(candidateName));
        } else if (commandKey === VCL.onStartTimer) {
            const timesDict = getTimesDictFromPhrase(command);
            args.push(timesDict);
        }
        break;
    }
    return callCbsFunction(cbs, commandKey, ...args);
}

/**
 * Converts numbers to text for easy lexical comparison
 * 
 * @param {String} text
 * @returns phrase
 */
const normalizeString = (phrase) => {
    return nlp(phrase).numbers().toText().parent().text().toLowerCase();
}

const getLeviDistance = (s1, s2) => levenshtein(s1, s2);

export {
    normalizeString, getLeviDistance, processCommand, processCommandAPI, CONFIG_MAP
}