import config from "_configs";
import { authHeader, handleResponse } from "_helpers";

export const userService = {
	login,
	logout,
	forgot,
	reset,
	verifyEmail,
	getProfile,
	getMAClientToken,

	createAdministrativeUser,
	createIamUser,
	createUserWithRoles,
	addRoles,
};

function login(email, password) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ email, password }),
	};

	return fetch(`${config.apiUrl}/auth/login`, requestOptions).then(
		handleResponse
	);
}

function forgot(email) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ email }),
	};

	return fetch(`${config.apiUrl}/auth/forgot`, requestOptions).then(
		handleResponse
	);
}

function verifyEmail(token) {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
	};

	return fetch(
		`${config.apiUrl}/auth/verifyEmail/${token}`,
		requestOptions
	).then(handleResponse);
}

function reset(token, newPassword, confirmNew) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ token, newPassword, confirmNew }),
	};

	return fetch(`${config.apiUrl}/auth/reset`, requestOptions).then(
		handleResponse
	);
}

function getProfile() {
	console.log(authHeader())
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
	};
	return fetch(`${config.apiUrl}/auth/user/profile`, requestOptions)
		.then(handleResponse)
	// .then(response => {
	// 	const { user } = response;
	// 	// store user details and jwt token in local storage to keep user logged in between page refreshes
	// 	const oldUser = {} localStorage.getItem("microassistant_user")
	// 	localStorage.setItem("microassistant_user", JSON.stringify(user));
	// 	return response;
	// });
}

function getMAClientToken() {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
	};
	return fetch(`${config.apiUrl}/auth/user/get_ma_client_token`, requestOptions)
		.then(handleResponse)
}

function logout() {
	// remove user from local storage to log user out
	localStorage.removeItem("microassistant_user");
}



function createAdministrativeUser(email, type) {
	if (!["instructor", "admin"].includes(type)) {
		return Promise.reject({ error: "Administrative accounts include instructor and admin" })
	}
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), "Content-Type": "application/json" },
		body: JSON.stringify({ email }),
	};

	return fetch(`${config.apiUrl}/user/${type}`, requestOptions).then(
		handleResponse
	);
}

function createIamUser(email, password) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), "Content-Type": "application/json" },
		body: JSON.stringify({ email, password }),
	};

	return fetch(`${config.apiUrl}/user/user`, requestOptions).then(
		handleResponse
	);
}

function createUserWithRoles(email, password, roles, isIAMUser) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), "Content-Type": "application/json" },
		body: JSON.stringify({ email, password, roles, isIAMUser }),
	};

	return fetch(`${config.apiUrl}/user`, requestOptions).then(
		handleResponse
	);
}



function addRoles(userId, roles) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), "Content-Type": "application/json" },
		body: JSON.stringify({ roles }),
	};

	return fetch(`${config.apiUrl}/user/${userId}/new_roles`, requestOptions).then(
		handleResponse
	);
}