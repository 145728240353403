import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { instructionCL3Actions } from "_actions";
import { Resource, PDFResource } from "_components";
import pdfPlaceholder from "_images/placeholder.pdf";
import { VCL } from "_api/voiceCommandLibrary";

const WINDOW_SIZE = 5;
const MA_RESOURCE_RATIO = 2;

const InstructionsShow = ({ leaves = [], instruction = {}, controlCommand, height, width, ...props }) => {
    const audioSource = useRef();
    const audioBuffer = useRef();
    const context = useRef();
    const [resources, setResources] = useState([]);
    const [audioPlaying, setAudioPlaying] = useState(false);
    const [ctrlIndices, setCtrlIndices] = useState({ preload: 0, current: 0 })

    const controlFunctionMapping = {
        [VCL.onNext]: showNextResource,
        [VCL.onBack]: showPreviousResource,
        [VCL.onRestart]: restartShow,
        [VCL.onPlayAudio]: playAudioResource,
        [VCL.onStopAudio]: stopAudioResource,
    };

    function getLeafs(_resources) { return _resources.filter(({ is_leaf }) => is_leaf); }
    function getLeafResourceWindow(_resources, customIndex, amount = WINDOW_SIZE) {
        return getLeafs(_resources)
            // just to be ready to jump over the menu
            .slice(
                Math.max((customIndex || ctrlIndices.current) - amount, 0),
                (customIndex || ctrlIndices.current) + amount
            );
    }

    /**
     * Downloads the Leaf urls from server in the range [currindx - amount, currindx + amount]
     * @param {Array} customResources [optional] - local copy of resources in case of in between operations (useEffect)
     * @param {Number} customIndex [optional] - index where to start
     * @param {Number} amount [optional] - range from currentResourceIndex to retrieve urls
     * @returns new resources with mapped urls if custerResources is provided
     */
    async function getUrlsBatch(customResources, customIndex, amount = WINDOW_SIZE) {
        // if customResources is provided - do not  setResources and return it as a result
        const _resources = customResources || resources;
        if (!_resources || _resources.length < 1) {
            return [];
        }
        const promises = getLeafResourceWindow(_resources, customIndex, amount)
            .filter(({ url }) => !url)
            .map(async ({ id }) => {
                const res = await props.getBlockResourcePerInstruction(instruction.id, id);
                return { url: `${res.url}#toolbar=0`, id };
            })
        if (!promises || promises.length < 1) return;
        const res = await Promise.all(promises);
        const urlsDict = res.reduce((acc, { id, url }) => { acc[id] = url; return acc; }, {});
        const resultResources = _resources.map(resource => {
            return { ...resource, url: urlsDict[resource.id] || resource.url }
        });
        if (customResources) return resultResources;
        setResources(resultResources)
    }
    function resetAudio() {
        context.current = new window.AudioContext();
        if (audioSource.current) {
            audioSource.current.stop();
        }
        setAudioPlaying(false);
    }

    /**
     * External control over the buttons (mostly for voice based control)
     */
    useEffect(() => {
        if (!controlCommand || !controlCommand.command || controlCommand.command.trim().length < 1) return;
        const func = controlFunctionMapping[controlCommand.command];
        if (!func) {
            console.log(`CAN'T FIND function for ${controlCommand}`)
        }
        func();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlCommand])


    useEffect(() => {
        async function fetchData() {
            setCtrlIndices({ ...ctrlIndices, preload: 0, current: 0 });
            // const _resources = leaves.map((block) => {
            //     return { ...block, isLeaf: block.is_leaf } //id: block.id, name: block.name,
            // });
            // let _populatedResources = [];
            // if (_resources && _resources.length > 0) {
            //     _populatedResources = await getUrlsBatch(_resources);
            // }
            let _populatedResources = [];
            if (leaves && leaves.length > 0) {
                console.log("LOADIN URLs BATCH")
                _populatedResources = await getUrlsBatch(leaves);
                console.log("LOADED URLs BATCH")
            }
            setResources(_populatedResources);
        }
        // if (!leaves || leaves.length < 1) return;
        fetchData();
        resetAudio()
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leaves])

    function showPreviousResource(e) {
        if (ctrlIndices.current > 0) {
            const newCurrentIndx = ctrlIndices.current - 1;
            getUrlsBatch(null, newCurrentIndx);
            setCtrlIndices({ ...ctrlIndices, current: newCurrentIndx, preload: newCurrentIndx });
        }
    }

    function showNextResource(e) {
        console.log("executing NEXT")
        if (ctrlIndices.current < resources.length - 1) {
            const newCurrentIndx = ctrlIndices.current + 1;
            getUrlsBatch(null, newCurrentIndx);
            setCtrlIndices({ ...ctrlIndices, current: newCurrentIndx, preload: newCurrentIndx });
        }
    }

    function restartShow(e) {
        setCtrlIndices({ ...ctrlIndices, preload: 0, current: 0 });
    }

    function createAudioSourceAndStart() {
        audioSource.current = context.current.createBufferSource();
        audioSource.current.buffer = audioBuffer.current;
        audioSource.current.connect(context.current.destination);
        audioSource.current.start();
        audioSource.current.current_block_id = leafs[ctrlIndices.current].id;
        audioSource.current.addEventListener('ended', () => {
            setAudioPlaying(false);
        }, false);
        setAudioPlaying(true);
    }

    async function playAudioResource(e) {
        if (!leafs[ctrlIndices.current] || !leafs[ctrlIndices.current].audio_url) {
            return;
        }
        try {
            if (audioSource.current && leafs[ctrlIndices.current].id === audioSource.current.current_block_id) {
                createAudioSourceAndStart();
                return;
            }
            context.current = new window.AudioContext();
            const response = await props.getBlockAudioResourcePerInstruction(instruction.id, leafs[ctrlIndices.current].id)
            const audioResponse = await fetch(response.url);
            const arrayBuffer = await audioResponse.arrayBuffer();
            audioBuffer.current = await context.current.decodeAudioData(arrayBuffer);
            createAudioSourceAndStart()
        } catch (err) {
            console.log(err);
        }
    }

    function stopAudioResource(e) {
        if (!leafs[ctrlIndices.current] ||
            !leafs[ctrlIndices.current].audio_url ||
            !audioPlaying) {
            return;
        }
        try {
            audioSource.current.stop();
            setAudioPlaying(false);
        } catch (err) {
            console.log(err);
        }
    }

    /**
     * Analyzes leafs array and given the ctrlIndices.current and +/- WINDOW_SIZE
     *  finds those leafs that were not loaded and returns first index of
     *  not loaded leaf that needs to be preloaded
     *  (from ctrlIndices.current to outside)
     */
    function definePreloadIndx(leafs) {
        for (let dist = 0; dist < WINDOW_SIZE; dist++) {
            for (let sgn of [-1, 1]) {
                let indx = ctrlIndices.current + sgn * dist;
                if (leafs[indx] && !leafs[indx].loaded) {
                    return indx
                }
            }
        }
        return null;
    }
    console.log('resources', resources)
    const leafs = getLeafs(resources);

    let calcWidth = width;
    let calcHeight = height;
    console.log(ctrlIndices.current, leafs[ctrlIndices.current])
    let _MA_RESOURCE_RATIO = MA_RESOURCE_RATIO;
    if (leafs && leafs[ctrlIndices.current]) {
        _MA_RESOURCE_RATIO = leafs[ctrlIndices.current].aspect_ratio || MA_RESOURCE_RATIO;
    }

    if (calcWidth / _MA_RESOURCE_RATIO > calcHeight) {
        calcWidth = calcHeight * _MA_RESOURCE_RATIO;
    } else {
        calcHeight = calcWidth / _MA_RESOURCE_RATIO;
    }

    const preloadIndx = definePreloadIndx(leafs)
    const prerenderContent = (
        <div className="prerender-resource-container">
            {preloadIndx !== undefined && preloadIndx !== null && preloadIndx >= 0 &&
                <>
                    <p> {preloadIndx} / {leafs.length}</p>
                    {leafs[preloadIndx] && leafs[preloadIndx].url && !leafs[preloadIndx].loaded && (
                        <Resource url={leafs[preloadIndx].url}
                            width={50}
                            onLoad={() => {
                                console.log(`Loaded ${preloadIndx}`);
                                const _resources = resources.map((r, i) => {
                                    return { ...r, loaded: r.loaded || (preloadIndx === i) }
                                });
                                setResources(_resources)
                            }}
                        />
                    )}
                </>
            }
        </div>
    );


    let content = null;
    console.log(leafs, ctrlIndices.current)
    if (!leafs || leafs.length < 1) {
        content = (
            <>
                <PDFResource url={pdfPlaceholder} width={calcWidth} height={calcHeight} />
            </>
        )
    } else if (!leafs[ctrlIndices.current] || !leafs[ctrlIndices.current].loaded) {
        content = (
            <>
                <PDFResource url={pdfPlaceholder} width={calcWidth} height={calcHeight} />
            </>
        )
    } else {
        content = (
            <>
                <Resource url={leafs[ctrlIndices.current].url} width={calcWidth} height={calcHeight} />
            </>
        )
    }

    return <div className="cl3-instruction-content-container d-block full-width">
        {content}
        {prerenderContent}
    </div>
}


function mapState(state) {
    return {};
}

const actionCreators = {
    getBlockResourcePerInstruction: instructionCL3Actions.getBlockResourcePerInstruction,
    getBlockAudioResourcePerInstruction: instructionCL3Actions.getBlockAudioResourcePerInstruction,
};

const connectedInstructionsShow = connect(mapState, actionCreators)(InstructionsShow);
export { connectedInstructionsShow as InstructionsShow };
