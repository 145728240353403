import { handleResponse, authHeader } from "_helpers";

export const audioService = {
    speech2text
};

function speech2text(data, serviceType = "wit", rate = 16000) {
    if (!["wit", "gcp"].includes(serviceType.toLowerCase())) {
        return Promise.reject({ "error": "Service type is not supported" });
    }
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify({ data })
    };
    return fetch(`/api/speech/speech2text/${serviceType}/${rate}`, requestOptions).then(
        handleResponse
    );
}
