import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import io from 'socket.io-client';
import { alertActions, moduleActions, userActions } from "_actions";
import { InstructionProgressBar } from "./InstructionProgressBar";
import config from "_configs";
import { getTupleKey } from "_api";
import { MicroTalk } from "_pages/MicroTalk/MicroTalk_V2";
import { isNone } from "_helpers";



function getTraineeKey(user_id, instruction_id) {
    return getTupleKey(user_id, instruction_id);
}

/**
 * goes over hierarchy (item -> children) and
 *      extract leavesToBlocks (dictionary {leaf id: {parent_id, version}} )
 *      and parents list (to form list of blocks for progressbar) with all the required parameters(id, version, goal, is_important)
 */
function extractInstructionData(item) {
    const leavesToBlocks = {};
    const children = item.children || [];
    const parents = [];
    children.forEach(child => {
        if (child.is_leaf) {
            const previousParent = parents[parents.length - 1];
            let version, indx;
            let importantElement = child.is_important ? child : null;
            if (previousParent && previousParent.id === item.id) {
                // the same block continues
                version = previousParent.version;
                indx = previousParent.size;
                parents[parents.length - 1].size = indx + 1;
                parents[parents.length - 1].importants.push(importantElement);
            } else {
                // new block has started
                version = parents.length; // just to avoid extra iteration - can be random number (unique)
                indx = 0;
                parents.push({
                    id: item.id,
                    version,
                    goal: item.goal || item.name,
                    name: item.name,
                    is_important: item.is_important,
                    importants: [importantElement],
                    size: indx + 1
                })
            }
            leavesToBlocks[child.id] = { parent_id: item.id, version, indx };
        } else {
            const [childLeavesToBlocks, childParents] = extractInstructionData(child);
            parents.push(...childParents);
            for (let key in childLeavesToBlocks) { leavesToBlocks[key] = childLeavesToBlocks[key]; }
        }
    })
    return [leavesToBlocks, parents];
}

const InstrInProgressPage = (props) => {
    const socketPath = config.SOCKET_PATH;
    const socket = useRef()
    const [instructions, setInstructions] = useState({}); // {instruction_id: {goalBlocks, leavesToBlocks}}
    const [trainees, setTrainees] = useState({});

    const prevTraineesRef = useRef();
    const prevInstructionsRef = useRef();

    async function notifyNewUser({ id, email }) { }
    async function notifyStartInstruction({ id, email, instruction_id }) { }
    async function notifyRemoveUser({ id, email }) {
        const updatedTrainees = Object
            .keys(prevTraineesRef.current)
            .reduce((acc, key, i) => {
                if (!key.startsWith(`${id},`)) acc[key] = prevTraineesRef.current[key];
                else {
                    acc[key] = { ...prevTraineesRef.current[key], offline: true }
                }
                return acc;
            }, {})
        prevTraineesRef.current = updatedTrainees;
        setTrainees(updatedTrainees);
    }
    async function notifyUserDBUpdate(users = []) {
        let newTrainees = prevTraineesRef.current || {};
        let newInstructions = prevInstructionsRef.current || {};
        console.log(users);
        for (let { id, email, logs, instruction_id } of users) {
            const traineeKey = getTraineeKey(id, instruction_id);
            if (!newInstructions[instruction_id]) {
                const res = await props.getBlockHierarchy(instruction_id);
                const [leavesToBlocks, goalBlocks] = extractInstructionData(res.hierarchy[0]);
                newInstructions = {
                    ...newInstructions,
                    [instruction_id]: { goalBlocks, leavesToBlocks, id, name: res.hierarchy[0].name }
                };
            }
            newTrainees = { ...newTrainees, [traineeKey]: { id, email, instruction_id, logs } }
        }
        prevInstructionsRef.current = newInstructions;
        prevTraineesRef.current = newTrainees;
        setInstructions(newInstructions);
        setTrainees(newTrainees);
    }

    /**
     * when log.from_block_id = null && log.to_block_id=id  OR log.from_block_id=id1 && log.to_block_id=id2 - valid standard for ongoing instruction
     * when log.from_block_id == log.to_block_id => quit current instruction/switched to another one (unfinished - pause all the counters)
     * when log.from_block_id == id && log.to_block_id = null => finished instruction
     * @param {*} param0 
     */
    async function notifyInstructionChangeStep({ id, email, log, instruction_id }) {
        if (log.type === "step" && !log.to_block_id) {
            props.successAlert(`User ${email} (${id}) finished instruction ${instruction_id}`)
        }
        const traineeKey = getTraineeKey(id, instruction_id);
        try {
            let newTrainees;
            if (!prevTraineesRef.current[traineeKey]) {
                // no instruction - user combo
                if (!prevInstructionsRef.current[instruction_id]) {
                    const res = await props.getBlockHierarchy(instruction_id);
                    const [leavesToBlocks, goalBlocks] = extractInstructionData(res.hierarchy[0]);
                    const newInstructions = {
                        ...prevInstructionsRef.current,
                        [instruction_id]: { goalBlocks, leavesToBlocks, id, name: res.hierarchy[0].name }
                    };
                    prevInstructionsRef.current = newInstructions
                    setInstructions(prevInstructionsRef.current);
                }
                newTrainees = { ...prevTraineesRef.current, [traineeKey]: { id, email, instruction_id, logs: [log] } }
            } else {
                newTrainees = {
                    ...prevTraineesRef.current,
                    [traineeKey]: {
                        ...prevTraineesRef.current[traineeKey],
                        logs: [...prevTraineesRef.current[traineeKey].logs, log],
                        offline: false
                    }
                }
            }
            prevTraineesRef.current = newTrainees;
            setTrainees(newTrainees);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const maClientToken = await props.getMAClientToken();
                // create a socket connection
                socket.current = io(socketPath, {
                    transports: ["websocket"],
                    path: '/socket',
                    reconnectionAttempts: 5
                });
                socket.current.on("connect", () => {
                    console.log("CONNECTED");
                    socket.current.emit("ma-client-progress-instructor-auth", {
                        token: maClientToken
                    })
                });
                socket.current.on("ma-client-progress-notify-new-user", notifyNewUser);

                socket.current.on("ma-client-progress-notify-start-instruction", notifyStartInstruction);

                socket.current.on("ma-client-progress-notify-change", notifyInstructionChangeStep);

                socket.current.on("ma-client-progress-notify-remove-user", notifyRemoveUser);
                socket.current.on("ma-client-progress-notify-db-update", notifyUserDBUpdate);
            } catch (err) {
                console.log(err);
            }
        }

        prevTraineesRef.current = {};
        prevInstructionsRef.current = {};
        fetchData();
        return () => { if (socket.current) socket.current.disconnect() }
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    async function handleDeleteComment(traineeKey, comment_id) {
        let commentLog;
        const newTrainees = Object.entries(trainees)
            .reduce((acc, [key, val]) => {
                if (key !== traineeKey) { acc[key] = val }
                else {
                    const newLogs = val.logs.map(o => {
                        if (o.comment_id !== comment_id) return o;
                        commentLog = o;
                        return { ...o, deleted: true };
                    });
                    acc[key] = { ...val, logs: newLogs }
                }
                return acc;
            }, {});
        const { instruction_id } = trainees[traineeKey];
        const { block_id } = commentLog;
        await props.deleteBlockFeedback(instruction_id, block_id, comment_id)
        setTrainees(newTrainees);
    }
    return <>
        <MicroTalk usertype="instructor" socketRef={socket.current} />
        <div className="striped-rows">
            {Object.entries(trainees)
                .map(([traineeKey, { email, instruction_id, logs, offline }], i) => {
                    return <InstructionProgressBar key={`prb-${i}`}
                        className={i % 2 === 0 ? "even" : "odd"}
                        instruction={instructions[instruction_id]}
                        logs={logs} email={email} online={!offline}
                        handleDeleteComment={(comment_id) => handleDeleteComment(traineeKey, comment_id)}
                    />
                })}

        </div>
    </>
}

function mapState(state) {
    return {};
}

const actionCreators = {
    getMAClientToken: userActions.getMAClientToken,
    successAlert: alertActions.success,
    getBlockHierarchy: moduleActions.getBlockHierarchy,
    deleteBlockFeedback: moduleActions.deleteBlockFeedback,

};

const connectedInstrInProgressPage = connect(mapState, actionCreators)(InstrInProgressPage);
export { connectedInstrInProgressPage as InstrInProgressPage };
