import React from "react";
import { Table, Button } from "_components/Base";

export const RoomsPanel = ({ rooms, moveToRoom, remoteLogoutOfRoom, user_id, ...rest }) => {
    if (!rooms || rooms.length < 1)
        return null;
    const baseHeaders = ["id", "name", "version", "user_id", "created_at"];
    let headers;
    if (user_id) {
        headers = [...baseHeaders, ""];
    } else {
        headers = baseHeaders;
    }
    let rows = [];
    for (let room of rooms) {
        const rowElements = baseHeaders.map(h => room[h]);
        // console.log*
        if (user_id) {
            let switchButton = "";
            if (!room["user_id"])
                switchButton = <Button
                    onClick={(e) => { e.preventDefault(); moveToRoom(room["id"]) }}
                >
                    Switch
                </Button>
            else if (room["user_id"] === user_id)
                switchButton = <Button
                    onClick={(e) => { e.preventDefault(); remoteLogoutOfRoom(user_id, room["id"]) }}
                >
                    I'm not there
                </Button>
            rowElements.push(switchButton)
        }
        rows.push(rowElements);
    }

    return <>
        <h4>Rooms</h4>
        <Table headers={headers} rows={rows} className="table-sm" />
    </>
}
