import { roomCL3Service } from "_services";
import { alertActions, loadingAPIActions } from "_actions";

export const roomCL3Actions = {
    listRooms,
    getUserInRoom,
};

function listRooms(locked = true) {
    return (dispatch) => {
        if (locked) dispatch(loadingAPIActions.startLoadingAPI());
        return roomCL3Service.listRooms().then(
            ({ data }) => {
                if (locked) dispatch(loadingAPIActions.stopLoadingAPI());
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                if (locked) dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

// locked = false - means the operation will be performed without any lock screen (in parallel)
function getUserInRoom(locked = false) {
    return (dispatch) => {
        if (locked) dispatch(loadingAPIActions.startLoadingAPI());
        return roomCL3Service.getUserInRoom().then(
            ({ data }) => {
                if (locked) dispatch(loadingAPIActions.stopLoadingAPI());
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                if (locked) dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}