export const userConstants = {
	CLEAR_ALL: "CLEAR_ALL",

	LOGIN: "USERS_LOGIN",

	LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
	LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
	LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

	LOGOUT: "USERS_LOGOUT",

	USERS_GET_PROFILE: "USERS_GET_PROFILE",

	USERS_GET_PROFILE_REQUEST: "USERS_GET_PROFILE_REQUEST",
	USERS_GET_PROFILE_SUCCESS: "USERS_GET_PROFILE_SUCCESS",
	USERS_GET_PROFILE_FAILURE: "USERS_GET_PROFILE_FAILURE",

};
