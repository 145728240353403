import { alertConstants } from "_constants";
import { toast } from 'react-toastify';

export const alertActions = {
	// success,
	// error,
	// clear,

	success,
	error,
	confirm,
	clearError,
	clearSuccess,
	clearAll,
	clearConfirmation,
};

function error(messageObj) {
	let message = messageObj;
	if (typeof messageObj === "object") {
		message = messageObj.message;
	}
	toast.error(message)
	return { type: alertConstants.ERROR, message };
}

function success(messageObj) {
	let message = messageObj;
	if (typeof messageObj === "object") {
		message = messageObj.message;
	}
	toast.success(message)
	return { type: alertConstants.SUCCESS, message };
}

function confirm(message, successFunction) {
	return { type: alertConstants.CONFIRM, message, successFunction };
}

function clearError() {
	return { type: alertConstants.CLEAR_ERROR };
}

function clearSuccess() {
	return { type: alertConstants.CLEAR_SUCCESS };
}

function clearConfirmation() {
	return { type: alertConstants.CLEAR_CONFIRMATION };
}

function clearAll() {
	return { type: alertConstants.CLEAR_ALL };
}
