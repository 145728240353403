const Stream = require('stream');

export const createRingBuffer = function (length) {
    /* https://stackoverflow.com/a/4774081 */
    var pointer = 0, buffer = [];

    return {
        get: function (key) {
            if (key < 0) {
                return buffer[pointer + key];
            } else if (key === false) {
                return buffer[pointer - 1];
            } else {
                return buffer[key];
            }
        },
        push: function (item) {
            buffer[pointer] = item;
            pointer = (pointer + 1) % length;
            return item;
        },
        prev: function () {
            var tmp_pointer = (pointer - 1) % length;
            if (buffer[tmp_pointer]) {
                pointer = tmp_pointer;
                return buffer[pointer];
            }
        },
        next: function () {
            if (buffer[pointer]) {
                pointer = (pointer + 1) % length;
                return buffer[pointer];
            }
        },
        clear: function () {
            pointer = 0;
            buffer = [];
        },
        tolist: function () {
            if (buffer[pointer] === undefined) {
                return buffer.slice(0, pointer);
            }
            return [...buffer.slice(pointer, length - 1), ...buffer.slice(0, pointer)];
        },
        toElementList: function () {
            if (buffer[pointer] === undefined) {
                return [].concat.apply([], buffer.slice(0, pointer));
            }
            return [].concat(...buffer.slice(pointer, length - 1), ...buffer.slice(0, pointer));
        }
    };
};


export const lastElement = (arr, func = (el) => el) => {
    for (let i = arr.length - 1; i > -1; i--) {
        if (func(arr[i])) {
            return i;
        }
    }
}

/* @VERIFY */
export const convertArrayToWriteStream = (items) => {
    const readable = new Stream.Readable({ objectMode: true })
    const writable = new Stream.Writable({ objectMode: true })
    writable._write = (object, encoding, done) => {
        console.log(object)
        // ready to process the next chunk
        done()
    }
    readable.pipe(writable)
    items.forEach(item => readable.push(item))

    // end the stream
    readable.push(null)
    return readable;
}

export const convertArrayToReadStream = (items) => {
    const readable = new Stream.Readable({ objectMode: true })
    items.forEach(item => readable.push(item))
    items.push(null);
    return readable;
}

export const getObjectValueByMultilevelKey = (object, strKey, separator = ".", reducer = (acc, key) => acc[key], raise = true) => {
    try {
        return strKey.split(separator)
            .map((v) => parseInt(v))
            .reduce(reducer, object);
    } catch (error) {
        if (raise) {
            throw error;
        }
        return undefined;
    }
}

export const getTupleKey = (...keys) => {
    return keys.join(',');
}