const localServerUrl = "127.0.0.1:5001";
const config = {
    DEBUG: true,
    apiUrl: "/api",
    clientUrls: {
        HOME: "/",
        TRAIN: "/train/:serviceType",
        TRAIN_WIT: "/train/wit",
        CLIENT: "/client",

        // auth
        LOGIN: "/auth/login",
        FORGOT_PASSWORD: "/auth/forgot",
        RESET_PASSWORD: (token) => `/auth/reset/${token}`,

        // others
        CHAT_ROOM__INSTRUCTOR__REDIRECT: "/chat_room/instructor",
        CHAT_ROOM__USER: (usertype, token) =>
            token ? `/chat_room/${usertype}/${token}` : `/chat_room`,
        CHAT_ROOM__INSTRUCTOR: (usertype, token) =>
            token ? `/chat_room/${usertype}/${token}` : `/chat_room`,


        INSTRUCTOR_CABINET: "/instructor",
        ADMIN_CABINET: "/admin",
        ADMIN_CABINET__MODULES: "/admin/modules",
        ADMIN_CABINET__INSTRUCTIONS: "/admin/instructions",
        ADMIN_CABINET__ANALYTICS: "/admin/analytics",
        ADMIN_CABINET__ANALYTICS__INSTR_IN_PROGRESS: "/admin/analytics/instructions-in-progress",
        ADMIN_CABINET__ANALYTICS__INSTR_USER_DB: "/admin/analytics/instructions-users-db",
        ADMIN_CABINET__RESOURCES:"/admin/resources",


        SCHEDULE_ROUTE: "/schedule",

        ADMIN_CABINET__VOICE_COMMANDS_DISIGN: "/admin/voice-commands-design",
        MANAGER_CABINET: "/manager",
        SYSTEM_CABINET: "/system",
        USER_CABINET: "/user",

        NETWORK__PROBLEM: "/oups",

        // CL3 related
        CL3__ORGANIZATION_LOGIN: "/cl3/auth/organization/login",
        CL3__CLIENT_CABINET: "/cl3/client",
        CL3__SESSION_MANAGER_CABINET: "/cl3/session_manager",


        SECRET_SOCKET_TEST: "/testsocket"

    },
    billing: {},

    SOCKET_PATH: process.env.REACT_APP_SOCKET_PATH || (process.env.NODE_ENV === "production" ? "/" : localServerUrl),
    localServerUrl: localServerUrl,
    WIT_AI_Endpoint: "https://api.wit.ai/speech?v=20200513",
    WIT_AUTH_TOKEN: process.env.WIT_AUTH_TOKEN || process.env.REACT_APP_WIT_AUTH_TOKEN || ""
};

console.log(config.SOCKET_PATH)

export default config;
