import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { userActions, alertActions } from "_actions";
import { Form, Button } from "react-bootstrap";

const Login = (props) => {
    const [credentials, setCredentials] = useState({ email: "", password: "" });
    useEffect(() => {
        const preFunc = async () => {
            console.log("LOGOUT!!!!");
            props.clearAlert();
            await props.logout();
        }
        preFunc();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        props.clearAlert();
        setCredentials({ ...credentials, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await props.login(credentials.email, credentials.password);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control name="email" type="email" placeholder="Enter email" value={credentials.email} onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control name="password" type="password" placeholder="Password" value={credentials.password} onChange={handleChange} />
            </Form.Group>
            <Button variant="primary" type="submit" onClick={handleSubmit}>
                Submit
            </Button>
        </Form>
    )
}


function mapState(state) {
    return {};
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    errorAlert: alertActions.error,
    clearAlert: alertActions.clearError,
    successAlert: alertActions.success,
    confirmAlert: alertActions.confirm,
};

const connectedLogin = connect(mapState, actionCreators)(Login);
export { connectedLogin as Login };
