import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Button, Form } from "_components/Base";

import { UpdateVoiceCommandModal } from "./UpdateVoiceCommandModal";
import { CreateVoiceCommandModal } from "./CreateVoiceCommandModal";
import { genericActions } from "_actions";
import { processCommandAPI } from "_api/voice"

const VoiceCommandsDesignPage = (props) => {
    const [voiceCommandStacks, setVoiceCommandStacks] = useState([]);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [testText, setTestText] = useState("");
    const [testHighligtedID, setTestHighligtedID] = useState(null);
    const [modal, setModal] = useState({ open: false });
    const recognition = useRef();

    const passCommand = (command = testText, _voiceCommandStacks = voiceCommandStacks) => {
        console.log("command", command, testText);
        const mode = new Set(_voiceCommandStacks.map(o => o.onCommandName));
        const cbs = _voiceCommandStacks.reduce((acc, cur) => {
            acc[cur.onCommandName] = () => setTestHighligtedID(cur.id)
            return acc;
        }, { default: (cmd) => { setTestHighligtedID(null); window.alert(`DEFAULT "${cmd}"`) } })
        console.log(cbs);
        console.log(mode);
        processCommandAPI(_voiceCommandStacks, command.trim(), cbs, mode, false);
    }

    const initVoiceRecognition = () => {
        try {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            recognition.current = new SpeechRecognition();
            recognition.current.continuous = true;
            recognition.current.lang = 'en-US';
            recognition.current.interimResults = false;
            recognition.current.maxAlternatives = 5;

            recognition.current.onresult = async function (event) {
                window.alert('We are not ready to process your speech recognition results')
            }

            recognition.current.onnomatch = function (event) {
                console.log("I didn't recognise that command.");
            }

            recognition.current.onerror = function (event) {
                console.log('Error occurred in recognition: ' + event.error);
            }

            recognition.current.onend = function (event) {
            }

            recognition.current.onspeechend = function () {
            }
        } catch (err) {
            console.log("The browser is not supported. Fall back to the default functionality.")
        }
    }


    useEffect(() => {
        async function fetchData() {
            const data = await props.getVoiceCommandStack();
            setVoiceCommandStacks(data);
        }
        fetchData();
        initVoiceRecognition();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        recognition.current.onresult = async function (event) {
            const lastIndx = event.results.length - 1;
            if (event.results[lastIndx].isFinal) {
                const alternatives = event.results[lastIndx];
                let command = "", confidence = 0;
                // analyze all the alternatives and go on
                for (let i = 0; i < alternatives.length; i++) {
                    const _command = event.results[lastIndx][0].transcript.trim().toLowerCase();
                    const _confidence = event.results[lastIndx][0].confidence;
                    if (_confidence > confidence) {
                        command = _command;
                        confidence = _confidence;
                    }
                }
                if (confidence > 0) {
                    setTestText(command);
                    passCommand(command, voiceCommandStacks);
                }
                recognition.current.stop();
            }
        }
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voiceCommandStacks])


    const handleTest = (e) => {
        e.preventDefault();
        passCommand();
    }

    const renderCreateModalContent = () => {
        let _rest = {
            open: createModalOpen,
            handleClose: () => setCreateModalOpen(false),
            handleAction: (voiceCommandStack) => {
                setVoiceCommandStacks([...voiceCommandStacks, voiceCommandStack])
            }
        }
        const _modal = <CreateVoiceCommandModal {..._rest} />
        return _modal;
    }

    const renderModalContent = () => {
        let _rest = {
            open: modal.open,
            handleClose: () => setModal({ ...modal, open: false }),
            misc: modal.misc
        }
        const _modal = <UpdateVoiceCommandModal
            voiceCommandStack={modal.voiceCommandStack}
            handleAction={(voiceCommandStack) => {
                setVoiceCommandStacks(
                    voiceCommandStacks.map(
                        o => (o.id === voiceCommandStack.id) ? voiceCommandStack : o
                    )
                )
            }}
            {..._rest}
        />

        return _modal;
    }

    return <>
        {modal.open && renderModalContent()}
        {createModalOpen && renderCreateModalContent()}
        <h1>Voice Commands Design Page</h1>
        <p className="small">The commands are in the lookup order. The Test speech button works only for a duration of one phrase.</p>
        <Form onSubmit={handleTest}>
            <Form.Group controlId="formTestText" className="full-width">
                <Form.Label>Test text</Form.Label>
                <div className='d-flex'>
                    <Form.Control name="testText" value={testText}
                        onChange={(e) => setTestText(e.target.value)}
                    />
                    <Button style={{ minWidth: "100px" }} onClick={handleTest}>Test text</Button>
                    <Button style={{ minWidth: "120px" }} variant='outline-secondary' onClick={(e) => { e.preventDefault(); recognition.current.start() }}>Test speech</Button>
                </div>

            </Form.Group>
        </Form>
        <table className="table table-sm">
            <thead><tr><th><Button isLink onClick={() => { setCreateModalOpen(true) }}>Event</Button></th><th>Example</th></tr>
            </thead>
            <tbody>
                {voiceCommandStacks.map((o) => <tr
                    onClick={(e) => { e.preventDefault(); setModal({ ...modal, open: true, voiceCommandStack: o }) }}
                    key={`tr-${o.id}`}
                    className={o.id === testHighligtedID ? 'table-active' : ''}
                    style={{ cursor: "pointer" }}
                >
                    <td>
                        {o.onCommandName}
                    </td>
                    <td>
                        {o.example}
                    </td>
                </tr>)}
            </tbody>
        </table>
    </>
}

function mapState(state) {
    return {};
}

const actionCreators = {
    getVoiceCommandStack: genericActions.getVoiceCommandStack,
    updateVoiceCommandStack: genericActions.updateVoiceCommandStack,
    swapVoiceCommandStacks: genericActions.swapVoiceCommandStacks,
};

const connectedVoiceCommandsDesignPage = connect(mapState, actionCreators)(VoiceCommandsDesignPage);
export { connectedVoiceCommandsDesignPage as VoiceCommandsDesignPage };