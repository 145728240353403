/**
 * Calculates summ of the elements of the array [arr] with possible function [func] applied to each element.
 * @param {[Number]} arr 
 * @param {Function} func 
 */
const __sum = (arr, func) => {
    if (func) {
        return arr.reduce((acc, cur) => acc + func(cur), 0);
    }
    return arr.reduce((acc, cur) => acc + cur, 0);
}

/**
 * Calculates average
 */
export const mean = (arr) => __sum(arr) / arr.length;

/**
 * Calculates geometrical mean
 */
export const gmean = (arr) => Math.exp(__sum(arr, Math.log) / arr.length);