import { instructionCL3Service } from "_services";
import { alertActions, loadingAPIActions } from "_actions";


export const instructionCL3Actions = {
    listUserInstructions,
    getInstruction,
    getBlocksPerInstruction,
    getBlockResourcePerInstruction,
    getBlockAudioResourcePerInstruction,
};



function getInstruction(instruction_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return instructionCL3Service.getInstruction(instruction_id).then(
            ({ message, data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {id, name, description, created_at}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function listUserInstructions() {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return instructionCL3Service.listUserInstructions().then(
            ({ message, data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = [{id, name, description, created_at}]
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function getBlocksPerInstruction(instruction_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return instructionCL3Service.getBlocksPerInstruction(instruction_id).then(
            ({ data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function getBlockResourcePerInstruction(instruction_id, block_id) {
    return (dispatch) => {
        // dispatch(loadingAPIActions.startLoadingAPI());
        return instructionCL3Service.getBlockResourcePerInstruction(instruction_id, block_id).then(
            ({ data }) => {
                // dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {block_id, url, expires_at}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                // dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function getBlockAudioResourcePerInstruction(instruction_id, block_id) {
    return (dispatch) => {
        // dispatch(loadingAPIActions.startLoadingAPI());
        return instructionCL3Service.getBlockAudioResourcePerInstruction(instruction_id, block_id).then(
            ({ data }) => {
                // dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {block_id, url, expires_at}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                // dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}