import { userConstants, userCL3Constants } from "_constants";

let storageUser = localStorage.getItem("microassistant_user");
let user;
if (!storageUser || storageUser === "undefined") {
	user = null;
} else {
	user = JSON.parse(storageUser);
}

const initialState = {
	...(user ? { loggedIn: true, user } : {}),
	states: {},
};

const emptyInitialState = {
	states: {},
};

// Helper function (not allowed to call outside function)
function stateFlagManager(stateImpl, type) {
	const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
	if (!matches) return stateImpl;
	const [, requestName, requestState] = matches;
	let flagData = {
		["loading_" + requestName]: requestState === "REQUEST",
		["loaded_" + requestName]: requestState === "SUCCESS",
	};
	return Object.assign({}, stateImpl, {
		states: {
			...stateImpl.states,
			...flagData,
		},
	});
}

/**
 * My own implementation of "loading" flag management
 * */
export function authentication(state = initialState, action) {
	const stateImpl = authenticationImpl(state, action);
	return stateFlagManager(stateImpl, action.type);
}

function authenticationImpl(state = initialState, action) {
	switch (action.type) {
		case userConstants.CLEAR_ALL:
			return emptyInitialState;
		case userConstants.LOGOUT:
			return emptyInitialState;
		case userConstants.LOGIN_REQUEST:
			return emptyInitialState;
		case userConstants.LOGIN_SUCCESS:
			return {
				loggedIn: true,
				user: action.user,
			};
		case userConstants.LOGIN_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
			});
		case userConstants.USERS_GET_PROFILE_REQUEST:
			return state;
		case userConstants.USERS_GET_PROFILE_SUCCESS:
			return Object.assign({}, state, { user: { ...state.user, ...action.user } });
		case userConstants.USERS_GET_PROFILE_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
			});

		// = CL3 == CL3 == CL3 == CL3 == CL3 == CL3 =
		case userCL3Constants.CL3_CLEAR_ALL:
			return emptyInitialState;
		case userCL3Constants.ORGANIZATION_LOGOUT:
			return emptyInitialState;
		case userCL3Constants.ORGANIZATION_LOGIN_REQUEST:
			return emptyInitialState;
		case userCL3Constants.ORGANIZATION_LOGIN_SUCCESS:
			return {
				loggedIn: true,
				user: action.user,
			};
		case userCL3Constants.ORGANIZATION_LOGIN_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
			});

		default:
			return state;
	}
}
