import { chatService } from "_services";
import { alertActions, loadingAPIActions } from "_actions";
import { history } from "_helpers";
import config from "_configs";

export const chatActions = {
    createInstructorChatSession,
    createUserChatSession
};

function createInstructorChatSession(redirect = true) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return chatService.createInstructorChatSession().then(
            ({ data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                if (redirect) {
                    history.push(config.clientUrls.CHAT_ROOM__INSTRUCTOR("instructor", data.chat_session_id));
                }
                return data.chat_session_id;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function createUserChatSession(redirect = true) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return chatService.createUserChatSession().then(
            ({ data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                if (redirect) {
                    history.push(config.clientUrls.CHAT_ROOM__USER("user", data.chat_session_id));
                }
                return data.chat_session_id;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}
