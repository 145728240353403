import React from "react";
import { connect } from "react-redux";
import config from "_configs";
import { ItemList } from "_components/Base";
import { history } from "_helpers";

const styles = {
    container: {
        width: "50%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
}

const PageHome = (props) => {
    const { user, loggedIn } = props;
    if (!loggedIn || !user) {
        return (
            <h3>Welcome to MicroAssistant World!</h3>
        )
    }

    // if user is a simple user only, take them directly to the user cabinet
    if (JSON.stringify(user.roles) === JSON.stringify(["user"])) {
        history.push(config.clientUrls.USER_CABINET)
    }

    const adminOptions = [],
        managerOptions = [],
        systemOptions = [],
        cl3UserOptions = [],
        userOptions = [];

    if (user.roles.includes("system")) {
        systemOptions.push({ name: "System", path: config.clientUrls.SYSTEM_CABINET })
    }

    if (user.roles.includes("manager")) {
        managerOptions.push({ name: "Manage team", path: config.clientUrls.MANAGER_CABINET })
        managerOptions.push({ name: "Resource Management", path: config.clientUrls.ADMIN_CABINET__RESOURCES })
        managerOptions.push({ name: "Schedule", path: config.clientUrls.SCHEDULE_ROUTE })
    }

    if (user.roles.includes("admin")) {
        adminOptions.push({ name: "Modules", path: config.clientUrls.ADMIN_CABINET__MODULES })
        adminOptions.push({ name: "Instructions", path: config.clientUrls.ADMIN_CABINET__INSTRUCTIONS })
        adminOptions.push({ name: "Analytics", path: config.clientUrls.ADMIN_CABINET__ANALYTICS })
    }

    if (user.roles.includes("organization")) {
        cl3UserOptions.push({ name: "CL3 User cabinet", path: config.clientUrls.CL3__CLIENT_CABINET })
    }

    if (user.roles.includes("user")) {
        userOptions.push({ name: "User cabinet", path: config.clientUrls.USER_CABINET })
        managerOptions.push({ name: "Schedule", path: config.clientUrls.SCHEDULE_ROUTE })
    }

    const content = [systemOptions, managerOptions, adminOptions, cl3UserOptions, userOptions]
        .filter(o => o && o.length > 0)
        .map((items, i) => <ItemList key={`items-${i}`} items={items} extraTopMargin={i > 0} />)
    return <div style={styles.container}>{content}</div>
}

function mapState(state) {
    const { loggedIn, user } = state.authentication;
    return { loggedIn, user };
}

const actionCreators = {
};

const connectedPageHome = connect(mapState, actionCreators)(PageHome);
export { connectedPageHome as PageHome };
