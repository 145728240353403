import config from "_configs";
import { authOrgHeader, handleResponse } from "_helpers";

export const userCL3Service = {
	organizationLogin,
	organizationLogout,
	loginToRoom,
	changeUserRoom,
	remoteLogoutOfRoom
};

function organizationLogin(email, password, room, version) {
	// Return data: {room_id, token, id, email, version, roles}
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ email, password, room, version }),
	};

	return fetch(`${config.apiUrl}/cl3/auth/organization/login`, requestOptions).then(
		handleResponse
	);
}


function organizationLogout() {
	// remove org user from local storage to log user out
	// + destroys the room
	// Return data: {room_id: Number}
	const _authHeader = authOrgHeader();
	if (Object.keys(_authHeader).length < 1) {
		return;
	}
	const requestOptions = {
		method: "POST",
		headers: _authHeader
	};

	localStorage.removeItem("microassistant_user");

	return fetch(`${config.apiUrl}/cl3/auth/organization/logout`, requestOptions).then(
		handleResponse
	);
}

function loginToRoom(email, password, room_id) {
	// For manager: User login to room (credentials are from non org user) 
	// Return data: {user_id: Number, room_id: Number}
	const requestOptions = {
		method: "POST",
		headers: { ...authOrgHeader(), "Content-Type": "application/json" },
		body: JSON.stringify({ email, password, room_id }),
	};

	return fetch(`${config.apiUrl}/cl3/auth/organization/login_to_room`, requestOptions).then(
		handleResponse
	);
}

function changeUserRoom(source_room_id, target_room_id) {
	// For manager: Change user’s room 
	// body{source_room_id, target_room_id}
	// (source must be occupied by current requesting user, target must be empty)
	// Return data: [{user_id: Number, room_id: Number}, {user_id: Number, room_id: Number}]
	const requestOptions = {
		method: "POST",
		headers: { ...authOrgHeader(), "Content-Type": "application/json" },
		body: JSON.stringify({ source_room_id, target_room_id }),
	};

	return fetch(`${config.apiUrl}/cl3/auth/organization/change_room`, requestOptions).then(
		handleResponse
	);
}

function remoteLogoutOfRoom(user_id, room_id) {
	// For manager: remote user logout from the room
	// body{user_id, room_id}
	// Return data: {user_id: Number, room_id: Number}
	const requestOptions = {
		method: "POST",
		headers: { ...authOrgHeader(), "Content-Type": "application/json" },
		body: JSON.stringify({ user_id, room_id }),
	};

	return fetch(`${config.apiUrl}/cl3/auth/organization/remote_logout_of_room`, requestOptions).then(
		handleResponse
	);
}
