/**
 * LOGIC SOURCE: https://github.com/mdn/samples-server/tree/7e79e8fb43b20c59f5d78eb8fc20316db9fb3ac7/s/webrtc-from-chat
 */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { chatActions } from "_actions";



const PageChatRedirect = (props) => {
    useEffect(() => {
        async function anyNameFunction() {
            try {
                await props.createInstructorChatSession();
            } catch (err) {
                console.log(err)
            }
        }
        anyNameFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
}


function mapState(state) {
    return {};
}
const actionCreators = {
    createInstructorChatSession: chatActions.createInstructorChatSession
};

const connectedPageChatRedirect = connect(mapState, actionCreators)(PageChatRedirect);
export { connectedPageChatRedirect as PageChatRedirect };
