import React from "react";

export const TreeView = ({ items, ...rest }) => {
    return <>
        {items.map((item) => (<TreeBranch item={item} key={`branch-${item.id}`} {...rest} />))}
    </>
};

export const TreeBranch = ({ item, onExpandClick, onModuleExpandClick, node: Component, top = false, ...rest }) => {
    const expandable = !!onExpandClick;
    // const [open, setOpen] = useState(!expandable || item.open);
    const _onExpandClick = () => {
        // setOpen(!open)
        onExpandClick(item)
    }
    const _onModuleExpandClick = () => {
        // setOpen(!open)
        onModuleExpandClick(item)
    }
    let classNames = ["treeview-offset"]
    if (rest.className) classNames.push(rest.className);
    if (top) classNames.push("mt-3");
    return <div className={classNames.join(" ")}>
        <Component key={`node-${item.id}`}
            item={item} open={!expandable || item.open}
            onExpandClick={expandable ? _onExpandClick : null}
            onModuleExpandClick={expandable ? _onModuleExpandClick : null}
            top={top}
            {...rest}
        />
        {/* {(!expandable || open) && */}
        {(!expandable || item.open) &&
            <TreeView key={`view-${item.id}`}
                items={item.children}
                node={Component}
                onExpandClick={onExpandClick}
                {...rest}
            />
        }
    </div>
}