import React from "react";
import { connect } from "react-redux";

const InstrUserDbPage = (props) => {
    return <></>;
}

function mapState(state) {
    return {};
}
const actionCreators = {
};

const connectedInstrUserDbPage = connect(mapState, actionCreators)(InstrUserDbPage);
export { connectedInstrUserDbPage as InstrUserDbPage };
