import React from "react";

export const TooltipImportants = ({ importants, ...props }) => {
    return <div className="d-flex flex-column text-left">
        <span>Important steps:</span>
        {importants.map((im, imdx) => {
            if (!im) return null;
            return <span key={imdx}>{im.name} ({imdx + 1}/{importants.length})</span>
        })}
    </div>
}