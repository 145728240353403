import { ChevronDownIcon, ChevronUpIcon } from "@primer/octicons-react";
import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import config from "_configs";


const SystemDropdownOptions = (user) => {
    return [
        <NavDropdown.Header key="login_system_header">System</NavDropdown.Header>,
        <NavDropdown.Item key="login_system_0" as={Link} to={config.clientUrls.SYSTEM_CABINET}>System Cabinet</NavDropdown.Item>,
    ];
}

const DefaultDropdownOptions = (user) => {
    return [
        // <NavDropdown.Header key="login_system_header">Default</NavDropdown.Header>,
        // <NavDropdown.Item key="login_default_0" as={Link} to={config.clientUrls.TRAIN_WIT}>RECOGNIZE (WIT)</NavDropdown.Item>,
    ];
}

const InstructorDropdownOptions = (user) => {
    return [
        <NavDropdown.Header key="login_instructor_header">Instructor</NavDropdown.Header>,
        // <NavDropdown.Item key="login_instructor_1" as={Link} to={config.clientUrls.CHAT_ROOM__INSTRUCTOR__REDIRECT}>MicroTalk</NavDropdown.Item>,
        <NavDropdown.Item key="login_instructor_1" as={Link} to={config.clientUrls.ADMIN_CABINET__ANALYTICS__INSTR_IN_PROGRESS}>MicroTalk</NavDropdown.Item>,
    ];
}

const AdminDropdownOptions = (user) => {
    return [
        <NavDropdown.Header key="login_admin_header">Admin</NavDropdown.Header>,
        <NavDropdown.Item key="login_admin_2" as={Link} to={config.clientUrls.ADMIN_CABINET__VOICE_COMMANDS_DISIGN}>Voice commands design</NavDropdown.Item>,
    ];
}


const UserDropdownOptions = (user) => {
    return [
        <NavDropdown.Header key="login_user_header">User</NavDropdown.Header>,
        <NavDropdown.Item key="login_user_0" as={Link} to={config.clientUrls.USER_CABINET}>User Cabinet</NavDropdown.Item>,
        // <NavDropdown.Item key="login_admin_1" as={Link} to={config.clientUrls.ADMIN_CABINET__INSTRUCTION_CONFIGS}>Manage instruction configs</NavDropdown.Item>,
    ];
}
const CL3UserDropdownOptions = (user) => {
    return [
        <NavDropdown.Header key="login_user_header">CL3 User</NavDropdown.Header>,
        <NavDropdown.Item key="login_user_0" as={Link} to={config.clientUrls.CL3__CLIENT_CABINET}>User Cabinet</NavDropdown.Item>,
        // <NavDropdown.Item key="login_admin_1" as={Link} to={config.clientUrls.ADMIN_CABINET__INSTRUCTION_CONFIGS}>Manage instruction configs</NavDropdown.Item>,
    ];
}

const ManagerDropdownOptions = (user) => {
    return [
        <NavDropdown.Header key="login_imanager_header">Manager</NavDropdown.Header>,
        <NavDropdown.Item key="login_manager_0"
            as={Link} to={config.clientUrls.MANAGER_CABINET}
        >Manger Cabinet</NavDropdown.Item>,
    ];
}

const UserDropdown = ({ user }) => {
    let DropdownOptions = [];
    if (user.roles.indexOf("manager") > -1) {
        DropdownOptions.push(ManagerDropdownOptions(user))
        DropdownOptions.push(<NavDropdown.Divider key='login_manager_divider' />)
    }
    if (user.roles.indexOf("admin") > -1) {
        DropdownOptions.push(AdminDropdownOptions(user))
        DropdownOptions.push(<NavDropdown.Divider key='login_admin_divider' />)
    }
    if (user.roles.indexOf("instructor") > -1) {
        DropdownOptions.push(InstructorDropdownOptions(user))
        DropdownOptions.push(<NavDropdown.Divider key='login_instructor_divider' />)
    }
    if (user.roles.indexOf("system") > -1) {
        DropdownOptions.push(SystemDropdownOptions(user))
        DropdownOptions.push(<NavDropdown.Divider key='login_system_divider' />)
    }



    let logoutItemHref = config.clientUrls.LOGIN;
    if (user.roles.includes("organization")) {
        logoutItemHref = config.clientUrls.CL3__ORGANIZATION_LOGIN;
        DropdownOptions.push(CL3UserDropdownOptions(user))
        DropdownOptions.push(<NavDropdown.Divider key='login_user_divider' />)
    } else if (config.DEBUG || user.roles.indexOf("user") > -1) {
        DropdownOptions.push(UserDropdownOptions(user))
        DropdownOptions.push(<NavDropdown.Divider key='login_user_divider' />)
    }
    DropdownOptions.push(DefaultDropdownOptions(user))
    return < NavDropdown title={user.email} id="collasible-nav-dropdown" >
        {DropdownOptions}
        < NavDropdown.Item as={Link} to={logoutItemHref} > Logout</NavDropdown.Item >
    </NavDropdown >
}

const UserCabinetNavBar = (props) => {
    const [showBar, setShowBar] = React.useState(false);
    const divStyling = {
        style: {
            textAlign: "center",
            color: "white",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            // make sure hide button is not too much space when the navbar is shown
            position: showBar ? "absolute" : "",
            top: showBar ? "37px" : ""
        },
        className: "bg-dark" //match the navbar styling
    };
    return <>
        {showBar && <NavBarContent {...props} />}
        <div {...divStyling} onClick={() => setShowBar(!showBar)}>
            {showBar ? <ChevronUpIcon size={20} /> : <ChevronDownIcon size={20} />}
        </div>
    </>
}

const NavBarContent = (props) => {
    return <>
        <Navbar collapseOnSelect bg="dark" variant="dark">
            <Navbar.Brand href={config.clientUrls.HOME}>MicroVoice</Navbar.Brand>
            <Nav className="mr-auto">
                {/* <Nav.Link href="#features">Features</Nav.Link>
                <Nav.Link href="#pricing">Pricing</Nav.Link> */}
            </Nav>
            <Nav>
                {props.loggedIn ?
                    <UserDropdown user={props.user} /> :
                    <>
                        <Nav.Link as={Link} to={config.clientUrls.LOGIN}>Login</Nav.Link>
                        <Nav.Link as={Link} to={config.clientUrls.CL3__ORGANIZATION_LOGIN}>ORG Login</Nav.Link>
                    </>
                }
            </Nav>
        </Navbar>
    </>
}

const NavBar = (props) => {
    if (window.location.pathname.startsWith('/user')) {
        return <UserCabinetNavBar {...props} />
    }
    return <NavBarContent {...props} />;
}

function mapState(state) {
    const { loggedIn, user } = state.authentication;
    return { loggedIn, user };
}

const actionCreators = {

};

const connectedNavBar = connect(
    mapState,
    actionCreators
)(NavBar);
export { connectedNavBar as NavBar };
