import React from "react";
import config from "_configs";
import { ItemList } from "_components/Base";

const styles = {
    container: {
        width: "50%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
}

export const AnalyticsPage = (props) => {
    const options = [];
    options.push({ name: "Instructions in progress", path: config.clientUrls.ADMIN_CABINET__ANALYTICS__INSTR_IN_PROGRESS })
    options.push({ name: "User database wth all assigned instructions", path: config.clientUrls.ADMIN_CABINET__ANALYTICS__INSTR_USER_DB })
    options.push({ name: "etc.", path: "/etc" })

    return <div style={styles.container}>
        <ItemList items={options} />
    </div>
}
