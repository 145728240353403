import { authHeader, handleResponse } from "_helpers";

export const instructionService = {
    listOrgInstructions,
    listUserInstructions,
    assignInstructionToUser,
    withholdInstructionFromUser,
    listAssignedUsers,
    listApplicableUsers,
    getInstruction,
    getBlocksPerInstruction,
    getBlocksPerInstruction_ConnectedBlock,
    getBlockResourcePerInstruction,
    getBlockResourcePerInstruction_ConnectedBlock,
    getBlockAudioResourcePerInstruction,
    getBlockAudioResourcePerInstruction_ConnectedBlock,
    sendFeedbackPerBlock,
    finishInstruction
};


function listOrgInstructions() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`/api/instructions`, requestOptions).then(
        handleResponse
    );
}


function listUserInstructions() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`/api/instructions/self`, requestOptions).then(
        handleResponse
    );
}


function assignInstructionToUser(instruction_id, userId) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ user_id: userId })
    };
    return fetch(`/api/instructions/${instruction_id}/assign`, requestOptions).then(
        handleResponse
    );
}


function withholdInstructionFromUser(instruction_id, userId) {
    const requestOptions = {
        method: "DELETE",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ user_id: userId })
    };
    return fetch(`/api/instructions/${instruction_id}/withhold`, requestOptions).then(
        handleResponse
    );
}


function listAssignedUsers(instruction_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`/api/instructions/${instruction_id}/users`, requestOptions).then(
        handleResponse
    );
}


function listApplicableUsers(instruction_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`/api/instructions/${instruction_id}/applicable_users`, requestOptions).then(
        handleResponse
    );
}

function getInstruction(instruction_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`/api/instructions/${instruction_id}`, requestOptions).then(
        handleResponse
    );
}

function getBlocksPerInstruction(instruction_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`/api/instructions/${instruction_id}/blocks`, requestOptions).then(
        handleResponse
    );
}

function getBlocksPerInstruction_ConnectedBlock(instruction_id, connected_block_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`/api/instructions/${instruction_id}/connected_block/${connected_block_id}/blocks`, requestOptions).then(
        handleResponse
    );
}

function getBlockResourcePerInstruction(instruction_id, block_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`/api/instructions/${instruction_id}/blocks/${block_id}/resource`,
        requestOptions).then(
            handleResponse
        );
}

function getBlockResourcePerInstruction_ConnectedBlock(instruction_id, connected_block_id, block_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`/api/instructions/${instruction_id}/connected_block/${connected_block_id}/blocks/${block_id}/resource`,
        requestOptions).then(
            handleResponse
        );
}

function getBlockAudioResourcePerInstruction(instruction_id, block_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`/api/instructions/${instruction_id}/blocks/${block_id}/audio_resource`,
        requestOptions).then(
            handleResponse
        );
}

function getBlockAudioResourcePerInstruction_ConnectedBlock(instruction_id, connected_block_id, block_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`/api/instructions/${instruction_id}/connected_block/${connected_block_id}/blocks/${block_id}/audio_resource`,
        requestOptions).then(
            handleResponse
        );
}

function sendFeedbackPerBlock(instruction_id, block_id, feedback) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ feedback })
    };
    return fetch(`/api/instructions/${instruction_id}/blocks/${block_id}/feedback`, requestOptions).then(
        handleResponse
    );
}

function finishInstruction(instruction_id) {
    const requestOptions = {
        method: "POST",
        headers: authHeader()
    };
    return fetch(`/api/instructions/${instruction_id}/finish`, requestOptions).then(
        handleResponse
    );
}
