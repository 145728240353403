import { genericService } from "_services";
import { alertActions, loadingAPIActions } from "_actions";

export const genericActions = {
    getVoiceCommandStack,
    createVoiceCommandStack,
    updateVoiceCommandStack,
    swapVoiceCommandStacks
};

function getVoiceCommandStack(locked = true) {
    return (dispatch) => {
        if (locked) dispatch(loadingAPIActions.startLoadingAPI());
        return genericService.getVoiceCommandStack().then(
            ({ data }) => {
                if (locked) dispatch(loadingAPIActions.stopLoadingAPI());
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                if (locked) dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function updateVoiceCommandStack(id, data, locked = true) {
    return (dispatch) => {
        if (locked) dispatch(loadingAPIActions.startLoadingAPI());
        return genericService.updateVoiceCommandStack(id, data).then(
            ({ data, message }) => {
                dispatch(alertActions.success(message));
                if (locked) dispatch(loadingAPIActions.stopLoadingAPI());
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                if (locked) dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function createVoiceCommandStack(data, locked = true) {
    return (dispatch) => {
        if (locked) dispatch(loadingAPIActions.startLoadingAPI());
        return genericService.createVoiceCommandStack(data).then(
            ({ data, message }) => {
                dispatch(alertActions.success(message));
                if (locked) dispatch(loadingAPIActions.stopLoadingAPI());
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                if (locked) dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}
function swapVoiceCommandStacks(id1, id2, locked = true) {
    return (dispatch) => {
        if (locked) dispatch(loadingAPIActions.startLoadingAPI());
        return genericService.swapVoiceCommandStacks(id1, id2).then(
            ({ data }) => {
                if (locked) dispatch(loadingAPIActions.stopLoadingAPI());
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                if (locked) dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}
