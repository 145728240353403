// Dictionary to standardize and control the commands
// VCL == VoiceCommandLibrary
const VCL = {
    // generics
    onStartTimer: "onStartTimer",
    onStopTimer: "onStopTimer",
    onMute: "onMute",
    onUnmute: "onUnmute",
    onFarewell: "onFarewell",
    onHelp: "onHelp",

    // open panels
    onOpenInstructions: "onOpenInstructions",
    onOpenLogs: "onOpenLogs",
    onOpenNotes: "onOpenNotes",

    // all panels related
    onShowAllInstructions: "onShowAllInstructions",
    onShowAllLogs: "onShowAllLogs",
    onShowAllNotes: "onShowAllNotes", // @TBD
    onHome: "onHome",
    onClose: "onClose", // @TBD

    // Instructions panel related
    onShowInstruction: "onShowInstruction",
    onNext: "onNext",
    onBack: "onBack",
    onRestart: "onRestart",
    onPlayAudio: "onPlayAudio",
    onStopAudio: "onStopAudio",

    // Logs panel related
    onShowLog: "onShowLog",
};

const VOICE_CONFIG_NAMES = {
    NONE: "none",
    INSTRUCTIONS_HOME: "instructions_home",
    INSTRUCTIONS_INSTRUCTION: "instructions_instruction",
    LOGS: "logs",
    NOTES: "notes",
    HELP_HOME: "help_home",
    HELP_INSTRUCTION_HOME: "help_instruction_home",
    HELP_INSTRUCTION_INSTRUCTION: "help_instruction_instruction",
    HELP_NOTES: "help_notes",
    HELP_LOGS: "help_logs",
}

/**
 * Control dictionary for the VCL read out.
 * priority is here only for printing (you want always keep things ordered for user)
 */
const VCL_DESCRIPTION = {
    // generics
    [VCL.onStopTimer]: {
        search: [
            { keys: ["timer"], options: { fuzzy: 0.9 } },
            { keys: ["stop"] }
        ],
        example: "Stop timer", priority: 100
    },
    [VCL.onStartTimer]: {
        search: [{ keys: ["timer"], options: { fuzzy: 0.9 } }],
        example: "Set timer for 5 minutes 43 seconds", priority: 101
    },
    [VCL.onMute]: {
        search: [{ keys: ['stop listening', 'stop listen', 'stop to listen'] }],
        example: "Stop listening | Stop listen | Stop to listen", priority: 102
    },
    [VCL.onUnmute]: {
        search: [{ keys: ['start listening', 'start listen', 'start to listen'] }],
        example: "Start listening | Start listen | Start to listen", priority: 103
    },
    [VCL.onFarewell]: {
        search: [{ keys: ['bye bye'] }],
        example: "Bye bye", priority: 104
    },
    [VCL.onHelp]: {
        search: [{ keys: ['help'], options: { fuzzy: 0.9 } }],
        example: 'Help', priority: 105
    },
    [VCL.onClose]: {
        search: [{ keys: ['close'], options: { fuzzy: 0.9 } }],
        example: 'Close', priority: 106
    },

    // open panels
    [VCL.onOpenInstructions]: {
        search: [{ keys: ['open instructions'], options: { fuzzy: 0.9 } }],
        example: 'Open Instructions', priority: 200
    },
    [VCL.onOpenLogs]: {
        search: [{ keys: ['open logs', 'open locks'], options: { fuzzy: 0.9 } }],
        example: 'Open Logs', priority: 201
    },
    [VCL.onOpenNotes]: {
        search: [{ keys: ['open notes'], options: { fuzzy: 0.9 } }],
        example: 'Open Notes', priority: 202
    },
    [VCL.onShowAllInstructions]: {
        search: [{ keys: ['show all instructions'], options: { fuzzy: 0.9 } }],
        example: 'Show all instructions', priority: 203
    },
    [VCL.onShowAllLogs]: {
        search: [{ keys: ['show all logs', 'show all locks', 'all logs', 'all locks', 'other logs', 'other locks', 'log menu', 'lock menu'], options: { fuzzy: 0.9 } }],
        example: 'Show all logs | all logs | other logs | log menu', priority: 204
    },
    [VCL.onShowAllNotes]: {
        search: [{ keys: ['show all notes', 'all notes', 'other notes', 'notes menu'], options: { fuzzy: 0.9 } }],
        example: 'Show all notes | all notes | other notes | notes menu', priority: 205
    },
    [VCL.onHome]: {
        search: [{ keys: ['main menu', 'main screen', 'home'] }],
        example: 'Main menu | main screen | home', priority: 206
    },

    // Instructions panel related
    [VCL.onShowInstruction]: {
        search: [{ keys: ['show instruction'], options: { fuzzy: 0.9 } }],
        after: { keys: ['show instruction'], options: { fuzzy: 0.9 } },
        example: 'Show instruction <instruction name>', priority: 300
    },
    [VCL.onNext]: {
        search: [{ keys: ['ok', 'okay', 'done', 'next', 'checked', 'check'] }],
        example: 'Ok | okay | done | next | checked | check', priority: 301
    },
    [VCL.onBack]: {
        search: [{ keys: ['back', 'beck', 'bag', 'last', 'previous'] }],
        example: 'Back | last | previous', priority: 302
    },
    [VCL.onRestart]: {
        search: [{ keys: ['restart'] }],
        example: 'Restart', priority: 303
    },
    [VCL.onPlayAudio]: {
        search: [{ keys: ['play', 'start playing', 'start to play'] }],
        example: 'Play | start playing | start to play', priority: 304
    },
    [VCL.onStopAudio]: {
        search: [{ keys: ['stop', 'stop playing', 'stop to play'] }],
        example: 'Stop | stop playing | stop to play', priority: 305
    },


    // Logs panel related
    [VCL.onShowLog]: {
        search: [{ keys: ['show log', 'show lock'], options: { fuzzy: 0.9 } }],
        after: { key: ['show log', 'show lock'], options: { fuzzy: 0.9 } },
        example: 'Show log <log name>', priority: 400
    },
}

export { VCL, VOICE_CONFIG_NAMES, VCL_DESCRIPTION }