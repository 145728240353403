import config from "_configs";
import { authHeader, handleResponse } from "_helpers";

export const resourceService = {
    listResources,
    createResource,
    updateResource,
    deleteResource
};

function listResources() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/resource/`, requestOptions).then(
        handleResponse
    );
}

function createResource(data) {
    // Create voice command stack
    // possible update data fields {onCommandName, search, after, example, priority}
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}/resource/add_new_resource`, requestOptions).then(
        handleResponse
    );
}

function updateResource(res_id, data) {
    // Create voice command stack
    // possible update data fields {onCommandName, search, after, example, priority}
    const requestOptions = {
        method: "PATCH",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };
    console.log(res_id)

    return fetch(`${config.apiUrl}/resource/${res_id}`, requestOptions).then(
        handleResponse
    );
}


function deleteResource(res_id) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/resource/${res_id}`, requestOptions).then(
        handleResponse
    );
}