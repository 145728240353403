import config from "_configs";
import { authOrgHeader, handleResponse } from "_helpers";

export const roomCL3Service = {
	listRooms,
	getUserInRoom,
};

function listRooms() {
	// list all available rooms
	// Returns {data: [{id, name, version, user_id, created_at}]}
	const requestOptions = {
		method: "GET",
		headers: authOrgHeader()
	};

	return fetch(`${config.apiUrl}/cl3/rooms`, requestOptions).then(
		handleResponse
	);
}


function getUserInRoom() {
	// Get current user, remotely signed in in the room (client) 
	// Returns {data: [{id, email}]}
	const requestOptions = {
		method: "GET",
		headers: authOrgHeader()
	};

	return fetch(`${config.apiUrl}/cl3/room/user`, requestOptions).then(
		handleResponse
	);
}
