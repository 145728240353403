import React, { useEffect, useRef } from "react";

import io from "socket.io-client"
import config from "_configs";

export const SocketTest = (props) => {
    const socket = useRef();
    useEffect(() => {
        const socketPath = config.SOCKET_PATH;
        // create a socket connection
        socket.current = io(socketPath, {
            transports: ["websocket"],
            path: '/socket',
            reconnectionAttempts: 5
        });
        socket.current.on("connect", () => {
            console.log("CONNECTED");
            socket.current.emit("ma-client-progress-admin-auth", {})
        });

        socket.current.on("ma-client-progress-notify-admin-spy", ({ name, data }) => {
            console.log("================")
            console.log(name)
            console.log(data)
            console.log("================")
        });

        return () => { socket.current.disconnect() }
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <></>
}
