import { userConstants } from "_constants";
import { userService } from "_services";
import { alertActions, loadingAPIActions } from "_actions";
import { history } from "_helpers";

import config from "_configs";

export const userActions = {
	login,
	logout,
	forgot,
	reset,
	verifyEmail,
	getProfile,
	getMAClientToken,

	createAdministrativeUser,
	createIamUser,
	createUserWithRoles,
	addRoles,
};

function login(email, password) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request({ email }));

		return userService.login(email, password).then(
			({ data }) => {
				localStorage.setItem("microassistant_user", JSON.stringify(data.user));
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success(data.user));
				history.push(config.clientUrls.HOME);
				return true;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.error(error.error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request(user) {
		return { type: userConstants.LOGIN_REQUEST, user };
	}
	function success(user) {
		return { type: userConstants.LOGIN_SUCCESS, user };
	}
	function failure(error) {
		return { type: userConstants.LOGIN_FAILURE, error };
	}
}

function logout() {
	return (dispatch) => {
		dispatch({ type: userConstants.LOGOUT });
		console.log("LOGOUT")
		userService.logout();
		dispatch({ type: userConstants.CLEAR_ALL });
		return true;
	};
}

function forgot(email) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());

		return userService.forgot(email).then(
			({ message }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(alertActions.success(message));
				history.push(config.clientUrls.LOGIN);
				return true;
			},
			(error) => {
				dispatch(alertActions.error(error.error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function reset(token, newPassword, confirmNew) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());

		return userService.reset(token, newPassword, confirmNew).then(
			({ message }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(alertActions.success(message));
				history.push(config.clientUrls.LOGIN);
				return true;
			},
			(error) => {
				dispatch(alertActions.error(error.error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function verifyEmail(token) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		history.push(config.clientUrls.LOGIN);
		return userService.verifyEmail(token).then(
			({ message }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(alertActions.success(message));
				return true;
			},
			(error) => {
				dispatch(alertActions.error(error.error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function getProfile() {
	const previousUser = localStorage.getItem("microassistant_user");
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request(previousUser ? JSON.parse(previousUser) : {})); // REQUIRED otherwise previousUser will be just string

		return userService.getProfile().then(
			({ data }) => {
				let oldUser = {};
				try {
					oldUser = JSON.parse(localStorage.getItem("microassistant_user"))
				} catch (err) { }
				localStorage.setItem("microassistant_user", JSON.stringify({ ...oldUser, ...data.user }));
				dispatch(loadingAPIActions.stopLoadingAPI());
				return dispatch(success(data.user));
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.error(error.error));
				dispatch(loadingAPIActions.stopLoadingAPI());
			}
		);
	};

	function request(user) {
		return { type: userConstants.USERS_GET_PROFILE_REQUEST, user };
	}
	function success(user) {
		return { type: userConstants.USERS_GET_PROFILE_SUCCESS, user };
	}
	function failure(error) {
		return { type: userConstants.USERS_GET_PROFILE_FAILURE, error };
	}
}


function getMAClientToken() {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return userService.getMAClientToken().then(
			({ data }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				return data.token;
			},
			(error) => {
				dispatch(alertActions.error(error.error));
				dispatch(loadingAPIActions.stopLoadingAPI());
			}
		);
	};
}


function createAdministrativeUser(email, type) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return userService.createAdministrativeUser(email, type).then(
			({ data, message }) => {
				dispatch(alertActions.success(message));
				dispatch(loadingAPIActions.stopLoadingAPI());
				return data.user;
			},
			(error) => {
				dispatch(alertActions.error(error.error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}


function createIamUser(email, password) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return userService.createIamUser(email, password).then(
			({ data, message }) => {
				dispatch(alertActions.success(message));
				dispatch(loadingAPIActions.stopLoadingAPI());
				return data.user;
			},
			(error) => {
				dispatch(alertActions.error(error.error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function createUserWithRoles(email, password, roles, isIAMUser) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return userService.createUserWithRoles(email, password, roles, isIAMUser).then(
			({ data, message }) => {
				dispatch(alertActions.success(message));
				dispatch(loadingAPIActions.stopLoadingAPI());
				return data.user;
			},
			(error) => {
				dispatch(alertActions.error(error.error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function addRoles(userId, roles) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return userService.addRoles(userId, roles).then(
			({ data, message }) => {
				dispatch(alertActions.success(message));
				dispatch(loadingAPIActions.stopLoadingAPI());
				return data.roles_added;
			},
			(error) => {
				dispatch(alertActions.error(error.error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}