import React from "react";

export const Button = ({
    children,
    className = "",
    type = "button", // To avoid form submittion on click (use submit otherwise)
    disabled,
    isLink,
    btn = true,
    variant = "primary",
    ...props
}) => {

    let classNames = [];
    if (isLink) {
        classNames.push("linkButton btn-none");
    } else {
        if (btn) {
            classNames.push("btn");
        }
        if (variant && variant.length > 0) {
            classNames.push(`btn-${variant}`);
        }
        // classNames.push("btn-sized")
    }
    classNames.push(className);
    props.className = classNames.join(" ");
    return <button type={type} disabled={disabled} {...props}>{children}</button>;
};