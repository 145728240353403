import React from "react";
import { Dropdown as DropdownBS } from "react-bootstrap";

export const Dropdown = ({ items, title, className, variant, ...rest }) => {
    if (!items || items.length < 1) {
        // window.alert("No dropdown")
        return <></>;
    }

    return (
        <DropdownBS {...rest}>
            <DropdownBS.Toggle variant={variant ? variant : "none"} id="Dropdown-basic" className={className}>
                {title}
            </DropdownBS.Toggle>

            <DropdownBS.Menu popperConfig={{
                strategy: "fixed"
            }} style={{ margin: 0 }}>
                {items.map((item, indx) => {
                    let _content = null,
                        _key = `DropdownBS-option-${indx}`;
                    if (item.header) {
                        _content = <DropdownBS.Header key={_key}>{item.header}</DropdownBS.Header>
                    } else if (item.divider) {
                        _content = <DropdownBS.Divider key={_key} />
                    } else {
                        _content = <DropdownBS.Item key={_key} disabled={item.disabled}>
                            <span onClick={item.onClick}>{item.name}</span>
                        </DropdownBS.Item>
                    }
                    return _content;
                })}
            </DropdownBS.Menu>
        </DropdownBS>
    );
};
