import { authHeader, handleResponse } from "_helpers";

export const blockService = {
    previewResource,
    previewAudioResource,
};

function previewResource(blockId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`/api/blocks/${blockId}/resource`, requestOptions).then(
        handleResponse
    );
}

function previewAudioResource(blockId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`/api/blocks/${blockId}/audio_resource`, requestOptions).then(
        handleResponse
    );
}
