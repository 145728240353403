import React from "react";
import { Item } from "./Item";

const styles = {
    itemlist: {},
}
export const ItemList = ({ items, extraTopMargin, ...props }) => {
    const classnames = ["d-flex", "flex-column", "justify-content-around", "mx-auto"]
    if (extraTopMargin) {
        classnames.push("pt-5")
    }
    return <div className={classnames.join(" ")} style={styles.itemlist}>
        {items.map(({ name, path }, i) => <Item key={`item-${i}`} name={name} path={path} />)}
    </div>
}