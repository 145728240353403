import config from "_configs";
import { authHeader, handleResponse } from "_helpers";

export const scheduleService = {
    listResourceSchedule,
    createSchedule,
    deleteSchedule,
    updateSchedule
};

function listResourceSchedule(resource_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/schedule/${resource_id}`, requestOptions).then(
        handleResponse
    );
}

function createSchedule(data) {
    // Create voice command stack
    // possible update data fields {onCommandName, search, after, example, priority}
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}/schedule/add_new_schedule`, requestOptions).then(
        handleResponse
    );
}


function deleteSchedule(id) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/schedule/${id}`, requestOptions).then(
        handleResponse
    );
}


function updateSchedule(sched_id, data) {
    // Create voice command stack
    // possible update data fields {onCommandName, search, after, example, priority}
    const requestOptions = {
        method: "PATCH",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}/schedule/${sched_id}`, requestOptions).then(
        handleResponse
    );
}