import { alertConstants, userConstants } from "_constants";
const initialState = {
	error: {},
	success: {},
};
export function alert(state = initialState, action) {
	switch (action.type) {
		case userConstants.CLEAR_ALL:
			return initialState;
		case alertConstants.SUCCESS:
			return Object.assign({}, state, {
				success: {
					type: "alert-success",
					message: action.message,
				},
			});
		case alertConstants.ERROR:
			return Object.assign({}, state, {
				error: {
					type: "alert-danger",
					message: action.message,
				},
			});
		case alertConstants.CLEAR_ERROR:
			return Object.assign({}, state, {
				error: {},
			});
		case alertConstants.CLEAR_SUCCESS:
			return Object.assign({}, state, {
				success: {},
			});
		default:
			return state;
	}
}
