import React, { useEffect, useState, useRef } from "react";
import { Button, Col, ProgressBar, Row } from "_components/Base";
import { joinObjects } from "_helpers";
import moment from "moment";
import { fromNowFormal } from "_api/time";

import { CommentDiscussionIcon, XCircleIcon } from "@primer/octicons-react";
import { OverlayedProgressBar } from "./OverlayedProgressBar";
import { StatusIcon } from "_components";
import { OverlayedIcon } from "./OverlayedIcon";
import { TooltipImportants } from "./TooltipImportants";
import { TooltipProgressBar } from "./TooltipProgressBar";

const styles = {
    flagContainer: {
        position: "sticky",
        width: "0%",
        transform: `translate(5px, 1px)`
    },
    commentedSection: {
        width: "200px"
    },
    commentContainer: {
        position: "sticky",
        width: "0%",
        transform: `translate(5px, 17px)`
    },
    progressBar: {
        height: '35px'
        // position: "relative"
    },
    overlayText: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    borderedRight: {
        borderRight: "1px solid black"
    },
    borderedLeft: {
        borderLeft: "1px solid black"
    },
    borderedX: {
        borderLeft: "1px solid black",
        borderRight: "1px solid black"
    },
    borderedY: {
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
    },
    transparent: {
        backgroundColor: "rgba(0,0,0,0)"
    }

}

export const InstructionProgressBar = ({ instruction, logs = [], email, className = "", handleDeleteComment, online, ...props }) => {
    const [timeSinceStart, setTimeSinceStart] = useState("");
    const [timeLastGoal, setTimeLastGoal] = useState("");
    const [commentSection, setCommentSection] = useState({ open: false });
    const [isOnline, setIsOnline] = useState(null);

    const sameBlockOldestTimestampRef = useRef();
    const log0TimestampRef = useRef();
    const timer = useRef()

    function setTimestampRefs(_logs) {
        const logsSteps = _logs.filter(o => o.type === 'step')
        const logn = logsSteps[logsSteps.length - 1];
        // finished
        if (!logn.to_block_id && timer.current) {
            clearInterval(timer.current);
            timer.current = null;
            return;
        } else if (logn.to_block_id === logn.from_block_id) {
            clearInterval(timer.current);
            timer.current = null;
            return;
        }
        const { parent_id, version } = instruction.leavesToBlocks[logn.to_block_id];
        let sameBlockOldestTimestamp = logsSteps[0].timestamp;
        for (let i = logsSteps.length - 1; i >= 0; i--) {
            const parentBlock = instruction.leavesToBlocks[logsSteps[i].to_block_id];
            if (parent_id === parentBlock.parent_id && version === parentBlock.version) {
                sameBlockOldestTimestamp = logsSteps[i].timestamp;
            } else break
        }
        log0TimestampRef.current = logsSteps[0].timestamp; // overkill - but to be consistent
        sameBlockOldestTimestampRef.current = sameBlockOldestTimestamp;
    }

    useEffect(() => {
        setTimestampRefs(logs);
        timer.current = setInterval(async () => {
            setTimeSinceStart(fromNowFormal(log0TimestampRef.current));
            setTimeLastGoal(fromNowFormal(sameBlockOldestTimestampRef.current));
        }, 1000);
        return () => {
            if (timer.current) clearInterval(timer.current);
        }
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const newIsOnline = !!online;
        setIsOnline(newIsOnline);
        if (!online && timer.current) {
            clearInterval(timer.current);
            timer.current = null;
        }
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [online])

    useEffect(() => {
        setTimestampRefs(logs);
        const logsSteps = logs.filter(o => o.type === 'step')
        const logn = logsSteps[logsSteps.length - 1];
        if (!timer.current && logn.from_block_id !== logn.to_block_id) {
            // create timer for sinceStart & last Goal (if does not exists and from block != to block (switch to another instruction))
            timer.current = setInterval(async () => {
                setTimeSinceStart(fromNowFormal(log0TimestampRef.current));
                setTimeLastGoal(fromNowFormal(sameBlockOldestTimestampRef.current));
            }, 1000);
        } else if (logn.from_block_id === logn.to_block_id) {
            setIsOnline(false);
        }
        const logsCommentsCount = logs
            .filter(o => o.type === "comment" && !o.deleted).length
        if (logsCommentsCount < 1 && commentSection.open) {
            setCommentSection({ ...commentSection, open: false })
        }

        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logs])

    // Calculates the list of from-to objects containing main timestamps when user enetered and left specific goal module 
    function processFromToTimestamps(logsSteps, parent_id, version) {
        const result = []
        for (let i = 0; i < logsSteps.length; i++) {
            const { from_block_id, to_block_id, timestamp } = logsSteps[i];
            const toRes = instruction.leavesToBlocks[to_block_id] || {};
            const fromRes = instruction.leavesToBlocks[from_block_id] || {};
            const isToSame = toRes.parent_id === parent_id && toRes.version === version;
            const isFromSame = fromRes.parent_id === parent_id && fromRes.version === version;
            if (!isToSame && !isFromSame) {
                continue
            } else if (isToSame && !isFromSame) {
                result.push({ from: timestamp });
            } else {
                result[result.length - 1].to = timestamp;
            }
            //this must be IN ADDITION to above
            // only when from and to blocks are not the same (pause everywhere)
            if (i === logsSteps.length - 1 && isToSame && isFromSame && from_block_id !== to_block_id)
                result.push({ from: timestamp })
        }
        return result;
    }

    const logsSteps = logs
        .filter(o => o.type === "step")
        .map(o => { return { ...o, parent: instruction.leavesToBlocks[o.block_id] } });
    const logsComments = logs
        .filter(o => o.type === "comment" && !o.deleted)
        // attach {parent: {parent_id, version, indx}} to the log
        .map(o => { return { ...o, parent: instruction.leavesToBlocks[o.block_id] } });
    const log = logsSteps[logsSteps.length - 1];
    const has_finished = !log.to_block_id;
    const { parent_id, version, indx } = instruction.leavesToBlocks[
        has_finished ? log.from_block_id : log.to_block_id
    ];

    const goalBlockIndex = instruction.goalBlocks.findIndex(o => o.id === parent_id && o.version === version);

    const miniBars = [];
    const totalSize = instruction.goalBlocks.reduce((acc, cur) => acc + cur.size, 0)
    let finishStatusPercent = 0;
    let percentCounter = 0; // for alerts
    instruction.goalBlocks.forEach((o, i) => {
        if (o.size < 1) return;
        if ((o.importants || []).some(im => im)) {
            miniBars.push(
                <div key={`minibar-alert-${i}`} style={styles.flagContainer}>
                    <OverlayedIcon tooltip={<TooltipImportants importants={o.importants} />} />
                </div>
            )
        }

        const relatedLogComments = logsComments.filter(lg => o.id === lg.parent.parent_id && o.version === lg.parent.version)
        if (relatedLogComments.length > 0) {
            miniBars.push(<div style={styles.commentContainer} key={`minibar-comment-${i}`}>
                <div onClick={() => {
                    setCommentSection({ ...commentSection, open: true, parent_id: o.id, version: o.version, name: o.name, goal: o.goal })
                }}
                >
                    <CommentDiscussionIcon className="red-font" size="small" />
                </div>
            </div>)
        }
        const processedTimestamps = processFromToTimestamps(logsSteps, o.id, o.version)
        const tooltip = <TooltipProgressBar size={o.size} name={o.name} logComments={relatedLogComments} timeranges={processedTimestamps} />
        const subBarWidth = Math.ceil(o.size / totalSize * 100);
        percentCounter = percentCounter + subBarWidth;
        if (i < goalBlockIndex || has_finished) {
            finishStatusPercent = finishStatusPercent + subBarWidth;
            miniBars.push(<OverlayedProgressBar tooltip={tooltip} key={`progress-goal-block-${i}`} striped variant="success" style={joinObjects(styles.borderedX, styles.borderedY)} now={subBarWidth} />)
        } else if (i > goalBlockIndex) {
            miniBars.push(<OverlayedProgressBar tooltip={tooltip} key={`progress-goal-block-${i}`} striped style={joinObjects(styles.transparent, styles.borderedX, styles.borderedY)} now={subBarWidth} />)
        } else {
            const startRatio = indx / o.size;
            const endRatio = (indx + 1) / o.size;
            let localWidth = Math.ceil(subBarWidth * startRatio);
            finishStatusPercent = finishStatusPercent + localWidth;
            miniBars.push(<OverlayedProgressBar tooltip={tooltip} key={`progress-goal-block-${i}-1`} striped style={joinObjects(styles.borderedLeft, styles.borderedY)} variant="success" now={localWidth} />)
            localWidth = Math.ceil(subBarWidth * (endRatio - startRatio));
            miniBars.push(<OverlayedProgressBar tooltip={tooltip} key={`progress-goal-block-${i}-2`} animated striped style={styles.borderedY} now={localWidth} />)
            localWidth = Math.ceil(subBarWidth * (1 - endRatio));
            miniBars.push(<OverlayedProgressBar tooltip={tooltip} key={`progress-goal-block-${i}-3`} striped style={joinObjects(styles.borderedRight, styles.borderedY, styles.transparent)} now={localWidth} />)

        }
    })
    return <>
        <Row className="py-3 px-2 striped-row">
            <Col md={2}>
                <div className="d-flex flex-column">
                    <span className="lead"><StatusIcon statusColor={isOnline ? "green" : "red"} />{email}</span>
                    <span className="small">Instruction: {instruction.name}</span>
                    <span className="small">{instruction.description}</span>
                </div>
            </Col>
            <Col md={2}>
                <div className="d-flex flex-column">
                    <span className="lead">{Math.min(finishStatusPercent, 100)}%</span>
                    <span className="small">Started: {moment(logsSteps[0].timestamp).format("h:mm a")}</span>
                    <span className="small">Goal time: {timeLastGoal}</span>
                    <span className="small">Total time: {timeSinceStart}</span>
                    <span className="small">Module: {instruction.goalBlocks[goalBlockIndex].name}</span>
                </div>
            </Col>
            <Col md={8} className="d-flex flex-column">
                <ProgressBar style={styles.progressBar} >
                    {miniBars}
                </ProgressBar>
                {logsComments.length < 1 && <p>There are no comments</p>}
                {logsComments.length === 1 && <p>There is 1 comment</p>}
                {logsComments.length > 1 && <p>There are {logsComments.length} comments</p>}
            </Col>
        </Row>
        {commentSection.open && <Row className="px-2">
            <Col md={2}></Col>
            <Col md={10} className="striped-rows">
                <div className="d-flex flex-row justify-content-between">
                    <h4 >COMMENTS for module "{commentSection.name}" with a goal "{commentSection.goal}"</h4>
                    <div onClick={() => { setCommentSection({ ...commentSection, open: false }) }}><XCircleIcon size="medium" /></div>
                </div>
                {logsComments
                    .filter(lg => lg.parent.parent_id === commentSection.parent_id && lg.parent.version === commentSection.version)
                    .map(({ comment_id, comment, block_id, timestamp }, i) =>
                        <blockquote className="blockquote striped-row" key={`comment-${i}`}>
                            <p className="mb-0">{comment}</p>
                            <footer className="blockquote-footer text-small d-flex flex-row ">
                                <div style={styles.commentedSection}>Commented at <cite>{moment(timestamp).format("LT")}</cite></div>
                                <Button isLink onClick={() => { handleDeleteComment(comment_id) }}>Delete</Button>
                            </footer>
                        </blockquote>
                    )}
            </Col>
        </Row>
        }
    </>
};