import React, { useEffect, useRef, useState } from "react";


import { connect } from "react-redux";


import "_css/train.css";
import { moduleActions } from "_actions";

var commandsDict = {
    next: "next", ok: "next", forward: "next",
    back: "back", last: "back",
    call: "call", help: "call",
    terminate: "terminate",
    return: "return",
    restart: "restart"
};
var commands = Object.keys(commandsDict);
var grammar = '#JSGF V1.0; grammar commands; public <command> = ' + commands.join(' | ') + ' ;'

// TODO change name
const Client = (props) => {
    const [commandStat, setCommandStat] = useState({ color: "", confidence: 0 });
    const recognition = useRef();
    const speechRecognitionList = useRef();

    const nextFunc = async () => { console.log("you said next") }
    const backFunc = async () => { console.log("you said back") }
    const callFunc = async () => { console.log("you said call") }
    const restartFunc = async () => { console.log("you said restart") }
    const returnFunc = async () => { console.log("you said restart") }
    const terminateFunc = async () => { console.log("you said terminate") }
    const defaultFunc = async () => { console.log("you said DEFAULT?!") }

    const processKeywords = (command) => {
        let func;
        if (command === 'next') func = nextFunc;
        else if (command === 'back') func = backFunc;
        else if (command === 'restart') func = restartFunc;
        else if (command === 'terminate') func = terminateFunc;
        else if (command === 'return') func = returnFunc;
        else if (command === 'call') func = callFunc;
        else func = defaultFunc;
        return func;
    }

    const initVoiceRecognition = () => {
        try {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;
            // const SpeechRecognitionEvent = window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;

            recognition.current = new SpeechRecognition();
            speechRecognitionList.current = new SpeechGrammarList();
            speechRecognitionList.current.addFromString(grammar, 1);
            recognition.current.grammars = speechRecognitionList.current;
            recognition.current.continuous = true;
            recognition.current.lang = 'en-US';
            recognition.current.interimResults = false;
            recognition.current.maxAlternatives = 5;

            recognition.current.onresult = async function (event) {
                const lastIndx = event.results.length - 1;
                if (event.results[lastIndx].isFinal) {
                    const alternatives = event.results[lastIndx];
                    let command = "", confidence = 0;
                    // analyze all the alternatives and go on
                    for (let i = 0; i < alternatives.length; i++) {
                        const _command = event.results[lastIndx][0].transcript.trim().toLowerCase();
                        const _confidence = event.results[lastIndx][0].confidence;
                        const possibleCommands = commands
                            .filter(c => _command.includes(c))
                            .sort((o1, o2) => o2.length - o1.length);
                        if (possibleCommands.length < 1) {
                            continue
                        }
                        // the longest command (first) is less possible to be found by chance
                        const _realizedCommand = possibleCommands[0];
                        if (_realizedCommand && _confidence >= confidence) {
                            command = _realizedCommand;
                            confidence = _confidence
                        }
                    }
                    if (confidence > 0) {
                        setCommandStat({ ...commandStat, command, confidence });
                        const func = processKeywords(command);
                        try {
                            await func();
                        } catch (err) {
                            console.log("Something is wrong with keyword processor!")
                        }
                    }
                }
            }

            recognition.current.onnomatch = function (event) {
                console.log("I didn't recognise that command.");
            }

            recognition.current.onerror = function (event) {
                console.log('Error occurred in recognition: ' + event.error);
            }

            recognition.current.onend = function (event) {
                // Restart the listening
                recognition.current.start();
            }

            recognition.current.onspeechend = async function () {
                console.log("onspeechend")
            }

            recognition.current.start()
        } catch (err) {
            console.log("The browser is not supported. Fall back to the default functionality.")
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await props.getConfigedBlockHierarchy();
                // res.hierarchy;
            } catch (err) {
                console.log(err);
            }

        }
        // initVoiceRecognition();
        fetchData();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <p>Command: {commandStat.command}</p>
            <p>Confidence: {commandStat.confidence}</p>
        </>
    )
}

function mapState(state) {
    return {};
}

const actionCreators = {
    getConfigedBlockHierarchy: moduleActions.getConfigedBlockHierarchy
};

const connectedClient = connect(mapState, actionCreators)(Client);
export { connectedClient as Client };
