import React from "react";
import { OverlayTrigger, ProgressBar, Tooltip } from "_components/Base";

export const OverlayedProgressBar = ({ tooltip, ...rest }) => {
    return <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-2">{tooltip}</Tooltip>}
    >
        {({ ref, ...triggerHandler }) => (
            <ProgressBar className="d-inline-flex align-items-center" ref={ref} {...rest} {...triggerHandler} />
        )}
    </OverlayTrigger >
}