import config from "_configs";
import { authHeader, handleResponse } from "_helpers";

export const organizationService = {
    createOrganization,
    listOrganizations,
    setIsActiveOrganization,
    listOrgUsers
};

function createOrganization(name, email) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ name, email }),
    };

    return fetch(`${config.apiUrl}/organizations/new`, requestOptions).then(
        handleResponse
    );
}

function setIsActiveOrganization(id, active) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ active }),
    };

    return fetch(`${config.apiUrl}/organizations/${id}/active`, requestOptions).then(
        handleResponse
    );
}

function listOrganizations() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/organizations`, requestOptions).then(
        handleResponse
    );
}


function listOrgUsers(org_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    const org_insert = org_id || "current";
    return fetch(`${config.apiUrl}/organizations/${org_insert}/users`, requestOptions).then(
        handleResponse
    );
}