import React, { useEffect } from "react";

import { connect } from "react-redux";
import { alertActions } from "_actions";

import { Button } from "_components/Base";

/**
 * timer (int) - setting for automatic dismiss (in seconds)
 * 
 * @returns 
 */
const Alert = ({ children, timer, dissmisable = true, className = "", variant = "",
    clearWarning, clearSuccess, clearError, clearPersistentError, clearAll, ...props }) => {
    let clearFunc;
    if (variant.includes("success")) {
        clearFunc = clearSuccess;
    } else {
        clearFunc = clearError;
    }
    useEffect(() => {
        if (timer && timer > 0) {
            const timeId = setTimeout(async () => {
                await clearFunc();
            }, timer * 1000)

            return () => {
                clearTimeout(timeId)
            }
        }
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onDismissClick = async (e) => {
        e.preventDefault();
        await clearFunc();
    }

    let classNames = ['alert'];

    if (variant) {
        classNames.push(variant);
    }

    if (className) {
        classNames.push(className)
    }

    classNames.push("d-flex", "justify-content-between");


    return <div className={classNames.join(" ")} {...props}>
        <span className="my-auto">{children}</span>
        {dissmisable &&
            <Button isLink className="my-auto mr-2" onClick={onDismissClick}>DISMISS</Button>
        }
    </div >
}


function mapState(state) {
    return {};
}

const actionCreators = {
    clearAll: alertActions.clearAll,
    clearSuccess: alertActions.clearSuccess,
    clearWarning: alertActions.clearWarning,
    clearError: alertActions.clearError,
    clearPersistentError: alertActions.clearPersistentError,
};

const connectedAlert = connect(
    mapState,
    actionCreators
)(Alert);
export { connectedAlert as Alert };
