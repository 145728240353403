import { history } from "_helpers";
import config from "_configs";

export function validateEmail(email) {
	const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
	return emailRegexp.test(email);
}

export async function handleResponse(response) {
	try {
		return response
			.json()
			.then((data) => {
				if (!response.ok) {
					if (response.status === 401) {
						history.push(config.clientUrls.LOGIN);
					} else if (response.status >= 500) {
						return Promise.reject({ reason: "NETWORK_PROBLEM" });
					}
					return Promise.reject(data);
				}
				return data;
			})
			.catch((err) => {
				console.log(err);
				if (!err.error) {
					history.push(config.clientUrls.NETWORK__PROBLEM);
					const e = { message: "Can't connect to the server" };
					throw e;
				} else {
					throw err;
				}
			});
	} catch (error) {
		console.log(error);
	}
}

export async function handleWITResponse(response) {
	try {
		return response
			.json()
			.then((data) => {
				if (!response.ok) {
					console.log(response.status)
					if (response.status === 401) {
						window.location.reload(true);
					} else if (response.status === 426) {
						history.push(config.clientUrls.SUBSCRIPTION);
					} else if (response.status === 402) {
						history.push(config.clientUrls.BILLING_SUMMARY); // @TODO change to the billing page when it exists
					} else if (response.status >= 500) {
						return Promise.reject({ reason: "NETWORK_PROBLEM" });
					}
					return Promise.reject(data);
				}
				return data;
			})
			.catch((err) => {
				console.log(err);
				if (!err.error) {
					history.push(config.clientUrls.MOKAFILE__NETWORK__PROBLEM);
					const e = { message: "Can't connect to the server" };
					throw e;
				} else {
					throw err;
				}
			});
	} catch (error) {
		console.log(error);
	}
}

export function handleResponseOld(response) {
	return response.text().then((text) => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				window.location.reload(true);
			}

			const error = (data && data.error) || response.statusText;
			return Promise.reject(error);
		}

		return data;
	});
}
