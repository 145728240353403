import config from "_configs";
import { authHeader, handleResponse } from "_helpers";

export const instructionCL3Service = {
    listUserInstructions,
    getInstruction,
    getBlocksPerInstruction,
    getBlockResourcePerInstruction,
    getBlockAudioResourcePerInstruction,
};

function getInstruction(instruction_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/cl3/instructions/${instruction_id}`, requestOptions).then(
        handleResponse
    );
}

function getBlocksPerInstruction(instruction_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/cl3/instructions/${instruction_id}/blocks`, requestOptions).then(
        handleResponse
    );
}

function getBlockResourcePerInstruction(instruction_id, block_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/cl3/instructions/${instruction_id}/blocks/${block_id}/resource`,
        requestOptions).then(
            handleResponse
        );
}

function getBlockAudioResourcePerInstruction(instruction_id, block_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/cl3/instructions/${instruction_id}/blocks/${block_id}/audio_resource`,
        requestOptions).then(
            handleResponse
        );
}

function listUserInstructions() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/cl3/instructions`, requestOptions).then(
        handleResponse
    );
}
