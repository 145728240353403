import React from "react";
import { history, joinObjects } from "_helpers";

import { Button } from "_components/Base";

const styles = {
    item: {
        width: "100%",
        fontSize: "x-large",
    }
}

export const Item = ({ name, path, style = {}, ...props }) => {
    return <Button
        onClick={() => { history.push(path) }}
        style={joinObjects(styles.item, style)}
        variant="outline-secondary" className="my-1"
        {...props}
    >
        {name}
    </Button >
}
