import React, { useEffect, useState } from "react";
import { ThreeBarsIcon, XIcon, CommentIcon } from "@primer/octicons-react";

const styles = {
    slideSidebar: (height, width = "200px") => {
        return {
            height: `${height - 32}px`,
            width: width,
            opacity: 1,
            transition: "width 0.7s, opacity 0.7s, transform 0.7s",
            overflowY: "auto"
        }
    },
    slideSidebarClose: {
        width: "0px",//!important
        opacity: "0",
        transform: "translate(-50px, 0)"
    }
}

export const SidebarIcon = ({ onClick, isOpen, ...rest }) => {
    return <span onClick={onClick} {...rest}>
        {isOpen ? <XIcon size='medium' /> : <ThreeBarsIcon size='medium' />}
    </span>
}

export const RightSidebarIcon = ({ onClick, isOpen, ...rest }) => {
    return <span onClick={onClick} {...rest}>
        {isOpen ? <XIcon size='medium' /> : <CommentIcon size='medium' />}
    </span>
}


export const Sidebar = ({ open, children, onIsOpenChange, width = "200px", height, icon: IconComponent = SidebarIcon }) => {
    const [isOpen, setIsOpen] = useState(true);
    useEffect(() => {
        if (isOpen !== open) {
            setIsOpen(!isOpen);
            onIsOpenChange(open);
        }
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    let style = { ...styles.slideSidebar(height, width) };
    if (!isOpen) {
        style = { ...style, ...styles.slideSidebarClose }
    }

    return <div className={`slide-sidebar-container ${!isOpen ? "slide-sidebar-container-close" : ""}`}>
        <div className="d-flex justify-content-end slide-sidebar-icon">
            <IconComponent
                isOpen={isOpen}
                onClick={() => {
                    setIsOpen(!isOpen);
                    if (onIsOpenChange) onIsOpenChange(!isOpen);
                }}
            />
        </div>

        <div style={style}>
            {children}
        </div>
    </div >
}