import { moduleService } from "_services";
import { alertActions, loadingAPIActions } from "_actions";


export const moduleActions = {
    createModule,
    deleteModule,
    listModules,
    addNewBlock,
    verifyAddNewBlock,
    verifyAddExistingBlock,
    verifyBlockUpdate,
    verifyBlockDelete,
    updateBlockResource,
    updateBlockAudioResource,
    addModuleBlocks, // deprecated
    addExistingBlock,
    duplicateExistingBlock,
    deleteBlock,
    updateBlock,
    swapBlocks,
    updateParentBlock,
    getBlockHierarchy,
    updateConnectedBlocks,
    getConnectedBlocks,
    getPossibleConnectedBlocks,
    deleteBlockFeedback,
};

function createModule(name, description, is_instruction) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.createModule(name, description, is_instruction).then(
            ({ message, data }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data={"instruction_id": instruction_id, "block_id": block_id}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function deleteModule(module_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.deleteModule(module_id).then(
            ({ message, data }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data={"module_id": module_id}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function listModules(is_instruction) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI(is_instruction));
        return moduleService.listModules().then(
            ({ message, data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data={"modules": modules}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function addNewBlock(module_id, parent_block_id, name, is_leaf, goal, is_important) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.addNewBlock(module_id, parent_block_id, name, is_leaf, goal, is_important).then(
            ({ message, data }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data={id, name, url, is_top, links, source_module_id}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function verifyAddNewBlock(module_id, parent_block_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.verifyAddNewBlock(module_id, parent_block_id).then(
            ({ message, data }) => {
                // dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data={block_id, conflicts: [{id(module), name(module), is_instruction}]}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function verifyAddExistingBlock(module_id, parent_block_id, added_block_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.verifyAddExistingBlock(module_id, parent_block_id, added_block_id).then(
            ({ message, data }) => {
                // dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data={block_id, conflicts: [{id(module), name(module), is_instruction}]}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function verifyBlockUpdate(module_id, blockId, fields = []) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.verifyBlockUpdate(module_id, blockId, fields).then(
            ({ data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data={block_id, conflicts}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function verifyBlockDelete(module_id, blockId) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.verifyBlockDelete(module_id, blockId).then(
            ({ data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data={block_id, conflicts}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function updateBlockResource(module_id, blockId, selectedFile) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.updateBlockResource(module_id, blockId, selectedFile).then(
            ({ message, data }) => {
                // {id, aspect_ratio}
                // dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function updateBlockAudioResource(module_id, blockId, selectedFile) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.updateBlockAudioResource(module_id, blockId, selectedFile).then(
            ({ message }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                return true;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function addModuleBlocks(module_id, parent_block_id, added_module_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.addModuleBlocks(module_id, parent_block_id, added_module_id).then(
            ({ message, data }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {"hierarchy": hierarchy, "cloned_from_module_ids": cloned_from_module_ids}
                // hierarchy is a hierarchy starting from parent_block_id to replace existing children
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function addExistingBlock(module_id, parent_block_id, added_module_id, added_block_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.addExistingBlock(module_id, parent_block_id, added_module_id, added_block_id).then(
            ({ message, data }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {"hierarchy": hierarchy, "cloned_from_module_ids": cloned_from_module_ids}
                // hierarchy is a hierarchy starting from parent_block_id to replace existing children
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function duplicateExistingBlock(module_id, parent_block_id, added_module_id, added_block_id, name) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.duplicateExistingBlock(module_id, parent_block_id, added_module_id, added_block_id, name).then(
            ({ message, data }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {"hierarchy": hierarchy, "cloned_from_module_ids": cloned_from_module_ids}
                // hierarchy is a hierarchy starting from parent_block_id to replace existing children
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function deleteBlock(module_id, blockId) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.deleteBlock(module_id, blockId).then(
            ({ message, data }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data={"block_id": block_id}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

/**
 * data is expected to be an object: {url, name, links}
 **/
function updateBlock(module_id, blockId, data) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.updateBlock(module_id, blockId, data).then(
            ({ message, data }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data={"block_id": block_id}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function swapBlocks(module_id, parent_block_id, block_id1, block_id2) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.swapBlocks(module_id, parent_block_id, block_id1, block_id2).then(
            ({ message, data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data={block_id1: order2, block_id2: order1}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function updateParentBlock(module_id, blockId, parent_block_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.updateParentBlock(module_id, blockId, parent_block_id).then(
            ({ message, data }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {block_id, source_parent_id, target_parent_id}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function getBlockHierarchy(module_id, blockId) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.getBlockHierarchy(module_id, blockId).then(
            ({ message, data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {hierarchy, cloned_from_module_ids, block_feedbacks}
                // hierarchy is a hierarchy starting from parent_block_id to replace existing children
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function deleteBlockFeedback(module_id, blockId, feedbackId) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.deleteBlockFeedback(module_id, blockId, feedbackId).then(
            ({ message, data }) => {
                // dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {hierarchy, cloned_from_module_ids, block_feedbacks}
                // hierarchy is a hierarchy starting from parent_block_id to replace existing children
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function updateConnectedBlocks(module_id, connected_block_ids) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.updateConnectedBlocks(module_id, connected_block_ids).then(
            ({ message, data }) => {
                dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {module_id}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function getConnectedBlocks(module_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.getConnectedBlocks(module_id).then(
            ({ message, data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {"blocks": [{id, name}]
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function getPossibleConnectedBlocks(module_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return moduleService.getPossibleConnectedBlocks(module_id).then(
            ({ message, data }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data = {"hiearachy": []}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}