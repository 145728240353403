export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("microassistant_user"));

  if (user && user.token) {
    return { authorization: "Bearer " + user.token };
  } else {
    return {};
  }
}

export function authOrgHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("microassistant_user"));

  if (user && user.room_id && user.token) {
    return { authorization: "Bearer " + user.token };
  } else {
    return {};
  }
}
