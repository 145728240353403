import { authHeader, handleResponse, isNone } from "_helpers";

export const moduleService = {
    createModule,
    deleteModule,
    listModules,
    addNewBlock,
    verifyAddNewBlock,
    verifyAddExistingBlock,
    verifyBlockUpdate,
    verifyBlockDelete,
    updateBlockResource,
    updateBlockAudioResource,
    addModuleBlocks, //deprecated
    addExistingBlock,
    duplicateExistingBlock,
    deleteBlock,
    updateBlock,
    swapBlocks,
    updateParentBlock,
    getBlockHierarchy,
    updateConnectedBlocks,
    getConnectedBlocks,
    getPossibleConnectedBlocks,
    deleteBlockFeedback,
};

function createModule(name, description, is_instruction) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ name, description, is_instruction })
    };
    return fetch(`/api/modules/new`, requestOptions).then(
        handleResponse
    );
}

function deleteModule(module_id) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader()
    };
    return fetch(`/api/modules/${module_id}`, requestOptions).then(
        handleResponse
    );
}

function listModules(is_instruction) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    let suffix = '';
    if (!isNone(is_instruction)) {
        suffix = `/instructions-only/${is_instruction}`
    }
    return fetch(`/api/modules${suffix}`, requestOptions).then(
        handleResponse
    );
}

function addNewBlock(module_id, parent_block_id, name, is_leaf, goal, is_important) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ parent_block_id, name, is_leaf, goal, is_important })
    };
    return fetch(`/api/modules/${module_id}/blocks/add_new_block`, requestOptions).then(
        handleResponse
    );
}

function verifyAddNewBlock(module_id, parent_block_id) {
    const requestOptions = {
        method: "POST",
        headers: authHeader()
    };
    return fetch(`/api/modules/${module_id}/blocks/${parent_block_id}/verify_add_new_block`, requestOptions).then(
        handleResponse
    );
}

function verifyAddExistingBlock(module_id, parent_block_id, added_block_id) {
    const requestOptions = {
        method: "POST",
        headers: authHeader()
    };
    return fetch(`/api/modules/${module_id}/blocks/${parent_block_id}/verify_add_existing_block/${added_block_id}`, requestOptions).then(
        handleResponse
    );
}

function updateBlockResource(module_id, blockId, selectedFile) {
    const formData = new FormData();
    formData.append("file", selectedFile);
    const requestOptions = {
        method: "PATCH",
        headers: authHeader(),
        body: formData
    };
    return fetch(`/api/modules/${module_id}/blocks/${blockId}/resource`, requestOptions).then(
        handleResponse
    );
}

function verifyBlockUpdate(module_id, blockId, fields = []) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ fields })
    };
    return fetch(`/api/modules/${module_id}/blocks/${blockId}/verify_update`, requestOptions).then(
        handleResponse
    );
}

function verifyBlockDelete(module_id, blockId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
    };
    return fetch(`/api/modules/${module_id}/blocks/${blockId}/verify_delete`, requestOptions).then(
        handleResponse
    );
}

function updateBlockAudioResource(module_id, blockId, selectedFile) {
    const formData = new FormData();
    formData.append("file", selectedFile);
    const requestOptions = {
        method: "PATCH",
        headers: authHeader(),
        body: formData
    };
    return fetch(`/api/modules/${module_id}/blocks/${blockId}/audio_resource`, requestOptions).then(
        handleResponse
    );
}

function addModuleBlocks(module_id, parent_block_id, added_module_id) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ parent_block_id, module_id: added_module_id })
    };
    return fetch(`/api/modules/${module_id}/blocks/add_module_blocks`, requestOptions).then(
        handleResponse
    );
}

function addExistingBlock(module_id, parent_block_id, added_module_id, added_block_id) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ parent_block_id, module_id: added_module_id, added_block_id })
    };
    return fetch(`/api/modules/${module_id}/blocks/add_existing_block`, requestOptions).then(
        handleResponse
    );
}

function duplicateExistingBlock(module_id, parent_block_id, added_module_id, added_block_id, name) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ parent_block_id, module_id: added_module_id, added_block_id, name })
    };
    return fetch(`/api/modules/${module_id}/blocks/duplicate_existing_block`, requestOptions).then(
        handleResponse
    );
}

function deleteBlock(module_id, blockId) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
    };
    return fetch(`/api/modules/${module_id}/blocks/${blockId}`, requestOptions).then(
        handleResponse
    );
}

function updateBlock(module_id, blockId, data) {
    const requestOptions = {
        method: "PATCH",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };
    return fetch(`/api/modules/${module_id}/blocks/${blockId}`, requestOptions).then(
        handleResponse
    );
}

function swapBlocks(module_id, parent_block_id, block_id1, block_id2) {
    const requestOptions = {
        method: "PATCH",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ parent_block_id, block_id1, block_id2 })
    };
    return fetch(`/api/modules/${module_id}/blocks/swap`, requestOptions).then(
        handleResponse
    );
}

function updateParentBlock(module_id, blockId, parent_block_id) {
    const requestOptions = {
        method: "PATCH",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ parent_block_id })
    };
    return fetch(`/api/modules/${module_id}/blocks/${blockId}/update_parent`, requestOptions).then(
        handleResponse
    );
}

function getBlockHierarchy(module_id, blockId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    const suffix = !blockId ? "" : `/${blockId}`;
    return fetch(`/api/modules/${module_id}/hierarchy${suffix}`, requestOptions).then(
        handleResponse
    );
}

function updateConnectedBlocks(module_id, connected_block_ids) {
    const requestOptions = {
        method: "PATCH",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ connected_block_ids })
    };
    return fetch(`/api/modules/${module_id}/connected_blocks`, requestOptions).then(
        handleResponse
    );
}

function getConnectedBlocks(module_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`/api/modules/${module_id}/connected_blocks`, requestOptions).then(
        handleResponse
    );
}

function getPossibleConnectedBlocks(module_id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`/api/modules/${module_id}/possible_connected_blocks`, requestOptions).then(
        handleResponse
    );
}

function deleteBlockFeedback(module_id, blockId, feedbackId) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader()
    };
    return fetch(`/api/modules/${module_id}/blocks/${blockId}/feedback/${feedbackId}`, requestOptions).then(
        handleResponse
    );
}