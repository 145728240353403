import { audioService } from "_services";
import { alertActions, loadingAPIActions } from "_actions";


export const audioActions = {
    speech2text
};

function speech2text(data, serviceType = "wit", rate = 16000) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return audioService.speech2text(data, serviceType, rate).then(
            (response) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                console.log(response)
                return response;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}
