import React, { useEffect, useRef, useState } from "react";
import { autoCorrelate } from "_api/audio";
import Deque from "denque";
import { createRingBuffer } from "_api/dataStructure";


import { connect } from "react-redux";


import "_css/train.css";
import { audioActions } from "_actions";

const BUFLEN = 2048;
const WEB_AUDIO_KOEF = BUFLEN / 128;
const PREFFIX_SOUND_SIZE = 1 * WEB_AUDIO_KOEF;
const ALLOWED_SKIPS = 3 * WEB_AUDIO_KOEF;
const SAMPLE_RATE = 16000;
const MAX_DURATION = 10;
const MAX_QUEUE_SIZE = MAX_DURATION * SAMPLE_RATE;
const HUMAN_VOICE_RANGE = [100, 500];


// const MEDIA_CONTROLS = {
//     "audio": {
//         mandatory: {
//             // "channelCount": 1,
//             // "sampleRate": SAMPLE_RATE,
//             // "sampleSize": BUFLEN,
//             "googEchoCancellation": "true",
//             "googAutoGainControl": "false",
//             "googNoiseSuppression": "true",
//             "googHighpassFilter": "false",
//         }
//         // "mandatory": {
//         // },
//         // "optional": []
//     }
// }
const MEDIA_CONTROLS = {
    audio: true,
    video: false,
}

const Train = (props) => {
    const audioContext = useRef();
    const analyser = useRef();
    const buf = useRef();
    const buf2 = useRef();

    const voiceQueue = useRef();
    const voiceQueue2 = useRef();
    const inputBufferQueue = useRef();

    const requestRef = useRef();
    const fps = useRef();
    const [reportFPS, setReportFPS] = useState(-1);
    const controls = useRef();
    const preffixSound = useRef();

    const [messageText, setMessageText] = useState("");
    const [microphoneState, setMicrophoneState] = useState("Init");

    useEffect(() => {
        async function getContext() {
            try {
                setMicrophoneState("Get context");
                const stream = await navigator.mediaDevices.getUserMedia(MEDIA_CONTROLS);
                setMicrophoneState("Get AudioContext");
                const myAudioContext = window.AudioContext;//window.AudioContext || window.webkitAudioContext;
                try {
                    audioContext.current = new myAudioContext({ sampleRate: SAMPLE_RATE });
                } catch (eerr) {
                    console.log("fallback")
                    audioContext.current = new myAudioContext();
                }


                setMicrophoneState("Get AudioContext");

                await audioContext.current.audioWorklet.addModule('mic-input-extractor.js'); //-mute
                inputBufferQueue.current = new Deque();
                const micInputExtractorNode = new AudioWorkletNode(audioContext.current, 'mic-input-extractor')//-mute

                // Create an AudioNode from the stream.
                const mediaStreamSource = audioContext.current.createMediaStreamSource(stream);

                analyser.current = audioContext.current.createAnalyser();
                analyser.current.fftSize = BUFLEN;
                analyser.current.smoothingTimeConstant = 0;
                console.log(`analyser.smoothingTimeConstant = ${analyser.current.smoothingTimeConstant}`)

                mediaStreamSource.connect(analyser.current);

                // buf.current = new Float32Array(BUFLEN);
                buf.current = new Float32Array(analyser.current.frequencyBinCount);
                console.log(analyser.current.frequencyBinCount)
                // buf.current = new Uint8Array(analyser.current.frequencyBinCount);
                // buf2.current = new Uint8Array(analyser.current.frequencyBinCount);
                // if (analyser.current.getFloatTimeDomainData) {
                //     buf.current = new Float32Array(analyser.current.frequencyBinCount);
                // } else {
                //     buf.current = new Uint8Array(analyser.current.frequencyBinCount);
                // }

                // requestRef.current = requestAnimationFrame(updatePitch);
                console.log(audioContext.current.sampleRate);
                controls.current = { isRecording: false, skips: ALLOWED_SKIPS, toRecognize: false }
                preffixSound.current = createRingBuffer(PREFFIX_SOUND_SIZE);
                // voiceQueue.current = new Deque();
                voiceQueue2.current = [];

                analyser.current.connect(micInputExtractorNode);
                micInputExtractorNode.connect(audioContext.current.destination)

                micInputExtractorNode.port.onmessage = (event) => {
                    inputBufferQueue.current.splice(
                        inputBufferQueue.current.length, 0,
                        ...event.data
                    );
                };
                if (!analyser.current.getFloatTimeDomainData) {
                    global.AnalyserNode.prototype.getFloatTimeDomainData = function (array) {
                        var uint8 = new Uint8Array(analyser.current.frequencyBinCount);
                        this.getByteTimeDomainData(uint8);
                        // setMicrophoneState(`INSIDE ${uint8[10]}`)
                        // const lowest = 0.01;
                        // console.log(`unit8 = ${Math.max(...uint8)}`);
                        // array = uint8.slice(0, array.length).map(a => (a - 128) / 128)
                        // console.log(array)
                        // Array.prototype.splice.apply(array, [0, array.length].concat(uint8.map(a => (a - 128) / 128)));
                        // array.splice(0, uint8.length, uint8.map(a => (a - 128) / 128))
                        const len = Math.max(uint8.length, analyser.current.fftSize);
                        for (var i = 0; i < len; i++) {
                            // array[i] = (uint8[i] - 128) * 0.0078125;
                            // array[i] = (uint8[i]) * 0.0078125;
                            array[i] = (uint8[i] - 128) * 0.0078125;
                        }
                    };
                }
                setMicrophoneState("ON");
                requestRef.current = requestAnimationFrame(updatePitch);
            } catch (err) {
                // console.log(err)
                // setMicrophoneState(JSON.stringify(err));
                console.trace(err)
            }
            fps.current = performance.now();
        }
        getContext();

        return //() => cancelAnimationFrame(requestRef.current);

        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function updatePitch(time) {
        analyser.current.getFloatTimeDomainData(buf.current);
        // analyser.current.getByteTimeDomainData(buf.current);
        // console.log(buf.current.slice(1, 20).some(a => a = 128))
        // if (analyser.current.getFloatTimeDomainData) {
        //     analyser.current.getFloatTimeDomainData(buf.current);
        // } else {
        //     analyser.current.getByteTimeDomainData(buf2.current);
        //     // const len = analyser.current.fftSize;
        //     // for (let i = 0; i < len; i++) {
        //     //     buf.current[i] = (buf2.current[i] - 130) / 128
        //     // }
        // }
        // console.log(buf.current[0])
        // var ac = autoCorrelate(buf.current[0] === 0 ? buf2.current : buf.current, audioContext.current.sampleRate);
        var ac = autoCorrelate(buf.current, audioContext.current.sampleRate);
        // console.log(ac)
        // setMicrophoneState(`LISTEN ${buf.current[100]}`);
        // console.log(Math.max(...fft_buf.current));
        if (ac === -1 || ac < HUMAN_VOICE_RANGE[0] || ac > HUMAN_VOICE_RANGE[1]) {
            setMicrophoneState(`LISTEN ${ac}`);
            if (!controls.current.isRecording) {
                preffixSound.current.push(inputBufferQueue.current.toArray());
            } else if (controls.current.skips > 0) {
                if (inputBufferQueue.current.length > 0)
                    // voiceQueue.current.splice(voiceQueue.current.length, 0, ...inputBufferQueue.current.toArray());
                    voiceQueue2.current.push(inputBufferQueue.current.toArray())
                controls.current.skips -= 1;
            } else {
                console.log("SKIPPING@@!@")
                controls.current.isRecording = false;
                controls.current.toRecognize = true;
            }
        } else {
            setMicrophoneState(`RECORD ${ac}`);
            if (!controls.current.isRecording) {
                // preffixSound.current
                //     .tolist()
                //     .forEach(pref => { voiceQueue.current.splice(voiceQueue.current.length, 0, ...pref) });
                const elmList = preffixSound.current.toElementList();
                console.log(`elmList.length ${elmList.length} ${preffixSound.current.length}`);
                // voiceQueue.current.splice(voiceQueue.current.length, 0, ...elmList)
                voiceQueue2.current.push(...preffixSound.current.tolist())
                preffixSound.current.clear();
                controls.current.isRecording = true;
            } else {
                controls.current.skips = ALLOWED_SKIPS;
            }
            // add values to the voiceQueue
            try {
                // console.log(inputBufferQueue.current.length);
                if (inputBufferQueue.current.length > 0) {
                    // voiceQueue.current.splice(voiceQueue.current.length, 0, ...inputBufferQueue.current.toArray())
                    voiceQueue2.current.push(inputBufferQueue.current.toArray())
                }
            } catch (errr) {
                console.log(errr);
                // console.log(`voiceQueue.current len = ${voiceQueue.current.length}`)
                console.log(`voiceQueue.current len = ${voiceQueue2.current.length}`)
            }
            // if (voiceQueue.current.length > MAX_QUEUE_SIZE - 1) {
            if (voiceQueue2.current.length > MAX_QUEUE_SIZE - 1) {
                console.log("CUT OFF == CUT OFF == CUT OFF == CUT OFF", MAX_QUEUE_SIZE - 1)
                controls.current.isRecording = false;
                controls.current.toRecognize = true;
            }
        }
        inputBufferQueue.current = new Deque();
        if (controls.current.toRecognize) {
            try {
                const startTime = new Date().getTime();
                const { serviceType } = props.match.params;
                // const { message } = await props.speech2text(voiceQueue.current.toArray(), serviceType);
                const { message } = await props.speech2text(voiceQueue2.current, serviceType, audioContext.current.sampleRate);
                setMessageText(message);
                const endTime = new Date().getTime();
                console.log(`Elapsed time ${endTime - startTime}`);
            } catch (err) {
                console.log(err);
            }

            controls.current.toRecognize = false;
            // voiceQueue.current.clear();
            // voiceQueue.current = new Deque();
            voiceQueue2.current.length = 0;
        }
        const perf = performance.now();
        setReportFPS(1000 / (perf - fps.current))
        fps.current = perf;
        requestRef.current = requestAnimationFrame(updatePitch)
    }


    return <>
        <p>{reportFPS}</p>
        <p>App state {microphoneState}</p>
        <p>{messageText}</p>

    </>
}


function mapState(state) {
    return {};
}

const actionCreators = {
    speech2text: audioActions.speech2text,
};

const connectedTrain = connect(mapState, actionCreators)(Train);
export { connectedTrain as Train };
