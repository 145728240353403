import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { roomCL3Actions, alertActions, userCL3Actions } from "_actions";
import { Container } from "_components/Base";

import { RoomsPanel } from "./RoomsPanel";
import { UserLogin } from "./UserLogin";


const SessionManager = (props) => {
    const [rooms, setRooms] = useState([]);
    const [userData, setUserData] = useState({});
    const updateRooms = async () => {
        try {
            const _rooms = await props.listRooms(false);
            setRooms(_rooms)
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        updateRooms();
        const interval = setInterval(() => {
            updateRooms()
        }, 60000);

        // Clear timeout if the component is unmounted
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onLogin = (data) => {
        setUserData(data);
    }
    const moveToRoom = async (room_id) => {
        try {
            await props.changeUserRoom(userData.room_id, room_id);
            await updateRooms();
            setUserData({});
        } catch (err) {
            console.log(err);
        }
    }
    const remoteLogoutOfRoom = async (user_id, room_id) => {
        try {
            await props.remoteLogoutOfRoom(user_id, room_id);
            await updateRooms();
        } catch (err) {
            console.log(err);
        }
    }
    return <Container>
        <h3>CL3 Session Manager Application</h3>
        <RoomsPanel
            rooms={rooms} user_id={userData.user_id}
            remoteLogoutOfRoom={remoteLogoutOfRoom}
            moveToRoom={moveToRoom}
        />
        <hr />
        {!userData.user_id &&
            <UserLogin room_id={props.user.room_id} onLogin={onLogin} />
        }

    </Container>
}


function mapState(state) {
    const { user } = state.authentication;
    return { user };
}

const actionCreators = {
    listRooms: roomCL3Actions.listRooms,
    changeUserRoom: userCL3Actions.changeUserRoom,
    remoteLogoutOfRoom: userCL3Actions.remoteLogoutOfRoom,
    errorAlert: alertActions.error,
    clearAlert: alertActions.clearError,
    successAlert: alertActions.success,
};

const connectedSessionManager = connect(mapState, actionCreators)(SessionManager);
export { connectedSessionManager as SessionManager };
