import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Button, Row, Col } from "_components/Base";
import { Modal } from "_components/Base";


import { genericActions } from "_actions";

const UpdateVoiceCommandModal = ({ open, voiceCommandStack, handleClose, handleAction, ...props }) => {
    const [localVoiceCommandStack, setLocalVoiceCommandStack] = useState({});

    useEffect(() => {
        if (!voiceCommandStack) return;
        let newVoiceCommandStack = {
            ...voiceCommandStack,
            search: voiceCommandStack.search.map(o => {
                return { ...o, keys: o.keys.join(" | "), fuzzy: (o.options || {}).fuzzy || 0 }
            }),
            after: {
                ...(voiceCommandStack.after || {}),
                keys: ((voiceCommandStack.after || {}).keys || []).join(" | "),
                fuzzy: ((voiceCommandStack.after || {}).options || {}).fuzzy || 0
            }
        };

        setLocalVoiceCommandStack(newVoiceCommandStack);
    }, [voiceCommandStack])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = { ...localVoiceCommandStack };
        result.search = localVoiceCommandStack.search
            .filter(o => o.keys && o.keys.trim().length > 0)
            .map(o => {
                const kwargs = { keys: o.keys.split("|").map(v => v.trim()).filter(v => v && v.length > 0) };
                const fuz = parseFloat(o.fuzzy)
                if (!isNaN(fuz) || fuz > 0) {
                    kwargs.options = { fuzzy: fuz };
                }
                return kwargs;
            });

        if (localVoiceCommandStack.after.keys && localVoiceCommandStack.after.keys.length > 0) {
            const fuz = parseFloat(localVoiceCommandStack.after.fuzzy);
            const kwargs = { keys: localVoiceCommandStack.after.keys.split('|').map(v => v.trim()).filter(v => v && v.length > 0) }
            if (!isNaN(fuz) || fuz > 0) {
                kwargs.options = { fuzzy: localVoiceCommandStack.after.fuzzy };
            }
            result.after = kwargs;
        } else {
            result.after = {};
        }
        await props.updateVoiceCommandStack(result.id, result);
        handleAction(result);
        handleClose();
    }

    const modalBody = <>
        <Form onSubmit={handleSubmit}>
            <h5>Search.Keys</h5>
            <p className="small">
                Use | for logical OR. If no "|" were found - system searches exact match in sentence.
                To add logical AND - add AND condition. To remove - leave it empty.
            </p>
            <Button onClick={(e) => {
                e.preventDefault();
                setLocalVoiceCommandStack({
                    ...localVoiceCommandStack,
                    search: [...localVoiceCommandStack.search, { keys: "", fuzzy: 0 }]
                })
            }}>Add AND condition</Button>
            {localVoiceCommandStack.search &&
                localVoiceCommandStack.search.map((o, i) => {
                    return <div key={`div-${i}`} ><Row>
                        <Col md={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>Search.Keys</Form.Label>
                                <Form.Control name="keys" type="text"
                                    value={localVoiceCommandStack.search[i].keys}
                                    onChange={(e) => {
                                        setLocalVoiceCommandStack({
                                            ...localVoiceCommandStack,
                                            search: localVoiceCommandStack
                                                .search.map((v, j) => j === i ? { ...v, keys: e.target.value } : v)
                                        })
                                    }} />
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>Fuzzy</Form.Label>
                                <Form.Control name="fuzzy" type="number"
                                    value={localVoiceCommandStack.search[i].fuzzy}
                                    onChange={(e) => {
                                        setLocalVoiceCommandStack({
                                            ...localVoiceCommandStack,
                                            search: localVoiceCommandStack
                                                .search.map((v, j) => j === i ? { ...v, fuzzy: parseFloat(e.target.value) } : v)
                                        })
                                    }} />
                            </Form.Group>
                        </Col>
                    </Row>
                        <hr />
                    </div>
                })}

            <hr />
            {localVoiceCommandStack.after &&
                <Row>
                    <Col md={10}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>After.Keys (Logical OR | is possible)</Form.Label>
                            <Form.Control name="key" type="text"
                                value={localVoiceCommandStack.after.keys || ""}
                                onChange={(e) => {
                                    setLocalVoiceCommandStack({
                                        ...localVoiceCommandStack,
                                        after: {
                                            ...localVoiceCommandStack.after,
                                            keys: e.target.value
                                        }
                                    })
                                }} />
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>After.Fuzzy</Form.Label>
                            <Form.Control name="fuzzy" type="number"
                                value={localVoiceCommandStack.after.fuzzy}
                                onChange={(e) => {
                                    setLocalVoiceCommandStack({
                                        ...localVoiceCommandStack,
                                        after: {
                                            ...localVoiceCommandStack.after,
                                            fuzzy: parseFloat(e.target.value)
                                        }
                                    })
                                }} />
                        </Form.Group>
                    </Col>
                </Row>
            }

            <hr />
            {localVoiceCommandStack.example &&
                <Form.Group controlId="formBasicName">
                    <Form.Label>Example string</Form.Label>
                    <Form.Control name="example" type="text"
                        value={localVoiceCommandStack.example}
                        onChange={(e) => {
                            setLocalVoiceCommandStack({
                                ...localVoiceCommandStack,
                                example: e.target.value
                            })
                        }} />
                </Form.Group>
            }
        </Form>
    </>;
    return <Modal isopen={open}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleOk={handleSubmit}
        modalTitle={`Modify ${localVoiceCommandStack.onCommandName}`}
        modalBody={modalBody}
    />
}

function mapState(state) {
    return {};
}

const actionCreators = {
    getVoiceCommandStack: genericActions.getVoiceCommandStack,
    updateVoiceCommandStack: genericActions.updateVoiceCommandStack,
    swapVoiceCommandStacks: genericActions.swapVoiceCommandStacks,
};

const connectedUpdateVoiceCommandModal = connect(mapState, actionCreators)(UpdateVoiceCommandModal);
export { connectedUpdateVoiceCommandModal as UpdateVoiceCommandModal };