import React, { useState } from "react";
import {
    PlusIcon, DashIcon, ContainerIcon,
    VerifiedIcon, KebabHorizontalIcon,
    UnmuteIcon, CommentIcon, CommentDiscussionIcon,
    ShareAndroidIcon
} from "@primer/octicons-react";
import { Button, Dropdown } from "_components/Base";

import { Menu, Item, Separator, useContextMenu } from 'react-contexify';

const styles = {
    textOnIcon: {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        fontSize: "12px"
    },
    iconContainer: {
        position: "relative",
        marginLeft: "auto",
        marginRight: "6px"
    },
}

export const TreeNode = ({ item, open, children, feedbackState = {}, onExpandClick, onItemClick, onLeafItemClick, onNonLeafItemClick, activeElements, className = "", menuItems = [], ...rest }) => {
    const dummyFunc = () => { }
    const contextMenuId = `context-menu-${item.id}-${item.level}`;
    const { show } = useContextMenu({
        id: contextMenuId,
    });
    const [active, setActive] = useState(false);

    let preffix_icon = <></>
    if (item.is_leaf && item.audio_url) {
        // preffix_icon = <VerifiedIcon className="mx-1" />
        preffix_icon = <UnmuteIcon className="mx-1" />
    } else if (item.is_leaf) {
        preffix_icon = <VerifiedIcon className="mx-1" />
    } else if (!onExpandClick) {
        preffix_icon = <ContainerIcon className="mx-1" />
    } else if (!item.isInstructionItem && (!item.children || item.children.length === 0) && !item.url) {
        preffix_icon = <ContainerIcon className="mx-1" />
    } else if (item.isInstructionItem || (item.children && item.children.length > 0 && !item.is_leaf)) {
        preffix_icon = <Button btn={false} className="no-borders p-0 px-1" variant="icon" onClick={onExpandClick}>
            {open ? <DashIcon /> : <PlusIcon />}
        </Button>
    }
    const dropdownTitle = <KebabHorizontalIcon className="kebab-vertical" size={16} />

    const handleContextMenu = (e) => {
        e.preventDefault();
        show(e, { props: { key: 'value' } })
    }

    const enableMenu = menuItems && menuItems.length > 0;
    const fieldClassName = [];
    const containerClassNames = [];
    if (className && className.length > 0) containerClassNames.push(className);
    if (!item.isInstructionItem && activeElements && activeElements.has(item.id)) {
        if (item.is_leaf || item.isLeaf) {
            containerClassNames.push("tree-node-highlighted-leaf");
        } else {
            containerClassNames.push("tree-node-highlighted-block");
        }
        containerClassNames.push("active")
    }
    if (active) containerClassNames.push("active");

    let handleItemCLick = null;
    if (!item.isInstructionItem && onItemClick) handleItemCLick = onItemClick;
    else if (item.is_leaf && onLeafItemClick) handleItemCLick = onLeafItemClick;
    else if (!item.is_leaf && onNonLeafItemClick) handleItemCLick = onNonLeafItemClick;

    if (handleItemCLick) fieldClassName.push("clickable");
    else handleItemCLick = dummyFunc;
    return <>
        <div className={`full-width tree-node d-flex ${containerClassNames.join(" ")}`}
            onContextMenu={enableMenu ? handleContextMenu : dummyFunc}
        >
            {preffix_icon}
            <div
                className={`d-flex full-width ${fieldClassName.join(" ")}`}
                onClick={() => { handleItemCLick(item) }}
            >
                <span style={!item.is_leaf ? {fontWeight: 500} : {} } className="align-middle">
                    {item.is_instruction &&
                        <ShareAndroidIcon className="mb-1 mr-1" size="small" />
                    }
                    {item.name}
                </span>
            </div>
            <span className="d-flex ml-auto">
                {feedbackState.item > 0 &&
                    <div style={styles.iconContainer}>
                        <CommentIcon style={{ color: "red" }} size={24} />
                        <span style={styles.textOnIcon}>{feedbackState.item}</span>
                    </div>
                }
                {feedbackState.children > 0 &&
                    <div style={styles.iconContainer}>
                        <CommentDiscussionIcon style={{ color: "yellow" }} size={24} />
                    </div>
                }
                {enableMenu &&
                    <Dropdown items={menuItems} title={dropdownTitle} className="noDropdownCaret" style={{ marginLeft: "auto" }} variant="none" />
                }
            </span>
        </div >
        {
            enableMenu &&
            <Menu id={contextMenuId} onShown={(e) => { setActive(true) }} onHidden={(e) => { setActive(false) }}>
                {menuItems.map(({ name, onClick, header, divider, disabled }, indx) => {
                    let _content = null,
                        key = `${contextMenuId}-item-${indx}`;

                    if (header) {
                        _content = <Item key={key} disabled>{header}</Item>
                    } else if (divider) {
                        _content = <Separator key={key} />
                    } else {
                        _content = <Item key={key} onClick={onClick} disabled={disabled}>{name}</Item>
                    }
                    return _content;

                })}
            </Menu>
        }
    </>
}