import React from 'react';
import { connect } from "react-redux";

const PageInstructorCabinet = (props) => {
    return <h3>Instructor Cabinet</h3>
}


function mapState(state) {
    return {};
}
const actionCreators = {};

const connectedPageInstructorCabinet = connect(mapState, actionCreators)(PageInstructorCabinet);
export { connectedPageInstructorCabinet as PageInstructorCabinet };
