import { blockService } from "_services";
import { alertActions, loadingAPIActions } from "_actions";


export const blockActions = {
    previewResource,
    previewAudioResource,
};

function previewResource(blockId) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return blockService.previewResource(blockId).then(
            ({ message, data }) => {
                // dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data={"url": url, "block_id": block_id}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function previewAudioResource(blockId) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return blockService.previewAudioResource(blockId).then(
            ({ message, data }) => {
                // dispatch(alertActions.success(message));
                dispatch(loadingAPIActions.stopLoadingAPI());
                // data={"url": url, "block_id": block_id}
                return data;
            },
            (error) => {
                dispatch(alertActions.error(error.error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}
