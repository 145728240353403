import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { PageUserCabinet } from "./PageUserCabinet";
import { roomCL3Actions, genericActions } from "_actions";

import "_css/train.css";
import "_css/cl3.css";
const STATUSES = {
    ON: "on", OFF: "off", PAUSE: "pause"
}

// TODO change name
const Client = (props) => {
    const [commandStat, setCommandStat] = useState({ command: "", confidence: 0 });
    const [currentUser, setCurrentUser] = useState({});
    const [doMute, setDoMute] = useState(false);
    const [renewVoice, setRenewVoice] = useState(true);
    const [status, setStatus] = useState(STATUSES.OFF);
    const [voiceCommandStack, setVoiceCommandStack] = useState([]);
    const recognition = useRef();

    const initVoiceRecognition = () => {
        try {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            recognition.current = new SpeechRecognition();
            recognition.current.continuous = true;
            recognition.current.lang = 'en-US';
            recognition.current.interimResults = false;
            recognition.current.maxAlternatives = 5;

            recognition.current.onresult = async function (event) {
                const lastIndx = event.results.length - 1;
                if (event.results[lastIndx].isFinal) {
                    const alternatives = event.results[lastIndx];
                    let command = "", confidence = 0;
                    // analyze all the alternatives and go on
                    for (let i = 0; i < alternatives.length; i++) {
                        const _command = event.results[lastIndx][0].transcript.trim().toLowerCase();
                        const _confidence = event.results[lastIndx][0].confidence;
                        if (_confidence > confidence) {
                            command = _command;
                            confidence = _confidence;
                        }
                    }
                    if (confidence > 0) {
                        setCommandStat({ ...commandStat, command, confidence });
                    }
                }
            }

            recognition.current.onnomatch = function (event) {
                console.log("I didn't recognise that command.");
            }

            recognition.current.onerror = function (event) {
                console.log('Error occurred in recognition: ' + event.error);
                if (event.error === "not-allowed") {
                    setRenewVoice(false);
                }
            }

            recognition.current.onend = function (event) {
                // Restart the listening
                setStatus(STATUSES.PAUSE)
                if (renewVoice)
                    recognition.current.start();
                setStatus(STATUSES.ON);
            }

            recognition.current.onspeechend = async function () {
                setStatus(STATUSES.PAUSE)
            }

            recognition.current.start()
            setStatus(STATUSES.ON)
        } catch (err) {
            console.log("The browser is not supported. Fall back to the default functionality.")
        }
    }

    const updateUser = async () => {
        try {
            const data = await props.getUserInRoom();
            setCurrentUser(data);
        } catch (err) {
            console.log(err);
            // in case of error - user is not specified
            setCurrentUser({});
        }
    }

    // timeout for user verification / update
    // (could be more optimized by creating strict protocols)
    useEffect(() => {
        updateUser();
        const timer = setInterval(async () => {
            await updateUser();
        }, 60000);

        // Clear timeout if the component is unmounted
        return () => clearInterval(timer);
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await props.getVoiceCommandStack();
                setVoiceCommandStack(data)
            } catch (err) {
                console.log(err);
            }
        }
        initVoiceRecognition();
        fetchData();
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    let statusColor;
    if (doMute && status !== STATUSES.OFF) statusColor = "pink";
    else if (status === STATUSES.ON) statusColor = "green";
    else if (status === STATUSES.PAUSE) statusColor = "yellow";
    else statusColor = "red";
    let content = null;
    if (currentUser.id) {
        content = <PageUserCabinet
            key={`user-${currentUser.id}`}
            voiceCommand={commandStat}
            voiceCommandStack={voiceCommandStack}
            changeMute={(flag) => setDoMute(flag)}
        />
    } else {
        content = (
            <>
                <h3>CL3 laboratory</h3>
                <p>Wait a bit, we are retrieving the data</p>
            </>
        )
    }
    return <>
        <span style={{ position: "absolute", top: "0px", left: "1px", opacity: 0.7, color: statusColor }}>&#9679;</span>
        {content}
        <span style={{ position: "absolute", bottom: "0px", left: "0px" }}>Debug cmd{doMute ? " (Muted)" : ""}: {commandStat.command}</span>
    </>

}

function mapState(state) {
    return {};
}

const actionCreators = {
    getUserInRoom: roomCL3Actions.getUserInRoom,
    getVoiceCommandStack: genericActions.getVoiceCommandStack,
};

const connectedClient = connect(mapState, actionCreators)(Client);
export { connectedClient as Client };
